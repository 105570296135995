import React, { useEffect, useState } from "react";
import { Box, Tooltip, Typography } from "@material-ui/core";
import Switch from "@mui/material/Switch";
import { makeStyles } from "@material-ui/core/styles";
// import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import CommonHeadPart from "../../common-components/CommonHeadPart";
import FilterTabs from "../../common-components/FilterTabs";
import ProgramIcon from "../../svg_icons/program.svg";
import { InnerPageRoutes, ROUTES } from "../../constants/routes";
import { ProgramsFilterTabs } from "../../constants/tabs";
import DialogTitle from "@mui/material/DialogTitle";
import { Link, useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import * as Actions from "../../reducer-store/actions/programsAction";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { PAGE } from "../../reducer-store/constants";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "../Common.css";
import MUIDataTable from "mui-datatables";
import { TextField } from '@material-ui/core';
import IconButton from "@mui/material/IconButton";
import { SearchOutlined } from '@material-ui/icons';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { FaRegEdit } from "react-icons/fa";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Button, Col, Row } from "react-bootstrap";
import { RiErrorWarningFill } from "react-icons/ri";
import { IoIosWarning } from "react-icons/io";
import { PROGRAM_SUCCESSFULLY_DISABLED, PROGRAM_SUCCESSFULLY_ENABLED } from "../../reducer-store/ToastMessage";
import ToastMessage from "../../common-components/ToastMessage";
import CircularProgress from '@mui/material/CircularProgress';
import { LIST_OF_COURSE_ERROR, LIST_OF_MODUAL_ERROR, LIST_OF_TOPIC_ERROR } from "../../reducer-store/ActionTypes";
import { Visibility } from "@mui/icons-material";
import { yellow } from "@mui/material/colors";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const useStyles = makeStyles(() => ({
    root: {
        "& > *": {
            borderBottom: "unset"
        }
    },
    tableHeadCell: {
        color: "#FFFFFF",
    },
    divInsideMain: {
        alignItems: 'center',
    },
    subtitle: {
        marginTop: 15,
    },
    linkstyle: {
        textDecoration: "none",
        color: "black"
    },
    searchBar: {
        borderColor: "black",
        "& hover": {
            borderColor: "black"
        },
        "& focused": {
            borderColor: "black"
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "black"
        }
    },
    dialogContent: {
        textAlign: "center",
        justifyContent: 'center',
        alignItems: "center"
    },
    dialogAction: {
        justifyContent: "center !important",
    },
    icon: {
        width: '30%',
        height: 'auto',
        color: "#000",
    },
    yesButton: {
        borderRadius: "40px !important",
        backgroundColor: "#00A651 !important",
        border: "1px solid #00A651 !important",
        color: "#ffffff !important",
        padding: "0 35px !important",
        height: "40px !important",
        fontFamily: 'Arial !important',
        fontSize: '15px',
        textTransform: 'capitalize'
    },
    noButton: {
        borderRadius: "40px !important",
        backgroundColor: "#ffffff !important",
        border: "1px solid #000000 !important",
        color: "#000000 !important",
        padding: "0 35px !important",
        height: "40px !important",
        textTransform: 'capitalize',
        fontSize: '15px',
    }
}));

const BreadCrumbProgram = [
    {
        labelText: "Home",
        linkUrl: ROUTES.home,
    },
    {
        labelText: "Program",
        linkUrl: "",
    },
];

const theme = createTheme({
    components: {
        MuiTableHead: {
            styleOverrides: {
                root: {
                    "& .css-1ygcj2i-MuiTableCell-root": {
                        fontweight: "bold",
                        backgroundColor: '#FFFFFF'
                    },
                    "& .tss-1h9t3sl-MUIDataTableHeadCell-sortAction": {
                        display: "-webkit-box"
                    },
                    "& .tss-1fbujeu-MUIDataTableHeadCell-toolButton": {
                        backgroundColor: '#FFFFFF'
                    },
                    '.tss-1akey0g-MUIDataTableHeadCell-data': {
                        fontSize: '15px'
                    },
                    "& .tss-1832qqn-MUIDataTableHeadRow-root": {
                        borderBottomColor: '#000',
                        borderBottomWidth: '1.2px',
                    },
                    "& .tss-10syd3x-MUIDataTableHeadCell-root": {
                        fontSize: '15px',
                    },
                    "& .tss-gm6zfk-MUIDataTableHeadCell-fixedHeader": {
                        borderBottomColor: '#000',
                        borderBottomWidth: 'thin',
                    },
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "Arial"
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontFamily: "Arial",
                },
                head: {
                    backgroundColor: "#FFFFFF"
                },
                footer: {
                    backgroundColor: "#FFFFFF"
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                colorInherit: {
                    backgroundColor: '#E53D12',
                    borderRadius: '4px',
                    color: 'white',
                    marginRight: '25px',
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    "&:nth-child(odd)": {
                        backgroundColor: "#DFDFDF"
                    },
                    "&:nth-child(even)": {
                        backgroundColor: "#F9F9F9"
                    },
                }
            }
        },
        MuiTableBody: {
            styleOverrides: {
                root: {
                    "& .css-1ex1afd-MuiTableCell-root": {
                        width: "13%",
                    }
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    "& .MuiTablePagination-displayedRows": {
                        marginTop: "9%"
                    },
                }
            }
        },
    }
});

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;
    const [name, setname] = useState("");

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ color: "#ffffff", backgroundColor: "#404040" }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={() => onClose()}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const Programs: React.FC = () => {
    const history = useHistory();
    const classes = useStyles();
    const AuthToken = useSelector((state: any) => state.auth);
    const { token } = AuthToken;
    const programList = useSelector((state: any) => state.program);
    const { programs } = programList;
    const [page, setPage] = useState(PAGE);
    const [filter, setFilters] = useState("all");
    const dispatch = useDispatch();
    const { ProgramList } = bindActionCreators(Actions, dispatch);
    const { getProgramDetails } = Actions;
    const { updateProgram } = Actions;
    const [search, setSearch] = useState("");
    const [order, setOrder] = useState("desc");
    const [status, setStatus] = useState('');
    // const [programListArr, setProgramListArr] = useState({
    //     columns: column,
    //     rows: []
    // });
    const [open, setOpen] = React.useState(false);
    const [openCourseFee, setOpenCourseFee] = React.useState(false);
    const [openProgramView, setProgramView] = React.useState(false);
    const [data, setData] = useState<any>([]);
    const [rowsPerPage, setRowsPerPage] = useState(1000);
    const [searchApiData, setSearchApiData] = useState([]);
    const [filterVal, setFilterVal] = useState('');
    const [disableProgramId, setDisableProgramId] = useState('');
    const [courseFeeName, setCourseFeeName] = useState('');
    const [programName, setProgramName] = useState('');
    const [programHours, setProgramHours] = useState<any>('');
    const [searchData, setSearchData] = useState(false);

    const [viewProgramInfos, setViewProgramInfos] = useState<any>([]);

    const handlefilter = (e: any) => {
        if (e.target.value == "") {
            setData(searchApiData);
        } else {
            const filterData = searchApiData.filter((items: any) =>
                items.name.props.children[0].toString().includes(e.target.value.toString())
                || items.name.props.children[2].toString().includes(e.target.value.toString())
                || items.id.props.children[0].toString().includes(e.target.value.toString())
                || items.hour.toString().includes(e.target.value.toString())
                || items.description.toLowerCase().includes(e.target.value.toLowerCase())
                || items.course.toString().includes(e.target.value.toString())
                || items.course_fee.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
                || items.credential.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
            );
            if (filterData.length > 0) {
                setData(filterData);
                setSearchData(false);
            } else {
                setData([])
                setSearchData(true);
            }
        }
        setFilterVal(e.target.value);
    };

    useEffect(() => {
        (async () => {
            dispatch({ type: LIST_OF_MODUAL_ERROR });
            dispatch({ type: LIST_OF_COURSE_ERROR });
            dispatch({ type: LIST_OF_TOPIC_ERROR });

            const newProgramList: any = await ProgramList(filter, page, rowsPerPage, token, order, search);
            // newProgramList.data.data.map((data: any) => {
            //     if (data.total_fees) {
            //         handleDisable(data.id, status);
            //     }
            // })
            renderDataTable(newProgramList);
        })();
    }, [page, filter, order, search, open]);

    useEffect(() => {
        sessionStorage.removeItem("program_id");
        sessionStorage.removeItem("program_name");
        sessionStorage.removeItem("program_code");
        sessionStorage.removeItem("vehicle_type");
        sessionStorage.removeItem("description");
    }, []);

    const renderDataTable = (newProgramList: any) => {
        const rowsDataTable = newProgramList && newProgramList?.data.map((row: any) => {
            return {
                id: <>{row.id}{!row.total_fees ?
                    <Tooltip title="Please add course fee">
                        <IoIosWarning style={{ marginLeft: '10px' }} color="#FFAF00" size={22} />
                    </Tooltip>
                    : null}</>,
                name:
                    <Link to={{ pathname: `${InnerPageRoutes.programView}/${row.id}`, state: `${row.name}` }} className={classes.linkstyle}>
                        {row.name}{<br />}{row.program_code !== null ? row.program_code : "---"}
                    </Link>,
                hour: row.total_duration_minutes !== null ? row?.total_duration_minutes / 60 : "---",
                description: row?.description ? row?.description : "---",
                course: row?.courses ? row?.courses : "---",
                course_fee: row?.total_fees ? `CAD ${Number(row?.total_fees).toFixed(2)}` : "---",
                credential: row?.melt_compatible === true ? "Certificate" : "Non Certificate",
                status: <> <div>
                    <Tooltip title={row.status ? "" : "Please add course fee to enable"}>
                        <Switch
                            color={row.status ? "success" : "warning"}
                            checked={row.status ? true : false}
                            onChange={() => handleClickOpen(row, row.status)}
                        />
                    </Tooltip>
                </div> </>,
                action: <>
                    <Visibility sx={{ color: yellow[700], marginRight: '15px', cursor: 'pointer' }} titleAccess="View Program Details" onClick={() => handleProgramView(row)} />
                    {row?.school_id ?
                        <Link to={`${InnerPageRoutes.editProgram}/${row.id}`}>
                            <FaRegEdit size={22} color="#00A651" />
                        </Link> : null}
                </>
            };
        });
        setData(rowsDataTable);
        setSearchApiData(rowsDataTable);
    };

    const columns = [
        {
            label: 'Sr No',
            name: "id",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
                    <Link to={{ pathname: `${InnerPageRoutes.programView}/${tableMeta?.rowData[0]?.props?.children[0]}` }} className={classes.linkstyle}>
                        {tableMeta.rowIndex + 1}
                    </Link>
                )
            }
        },
        {
            label: 'Name & Code',
            name: "name",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
                    <Link to={{ pathname: `${InnerPageRoutes.programView}/${tableMeta?.rowData[0]?.props?.children[0]}` }} className={classes.linkstyle}>
                        {value}
                    </Link>
                )
            }
        },
        {
            label: 'Hours',
            name: "hour",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
                    <Link to={{ pathname: `${InnerPageRoutes.programView}/${tableMeta?.rowData[0]?.props?.children[0]}` }} className={classes.linkstyle}>
                        {value}
                    </Link>
                )
            }
        },
        {
            label: 'Description',
            name: "description",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
                    <Link to={{ pathname: `${InnerPageRoutes.programView}/${tableMeta?.rowData[0]?.props?.children[0]}` }} className={classes.linkstyle}>
                        {value}
                    </Link>
                )
            }
        },
        {
            label: 'Course',
            name: "course",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
                    <Link to={{ pathname: `${InnerPageRoutes.programView}/${tableMeta?.rowData[0]?.props?.children[0]}` }} className={classes.linkstyle}>
                        {value}
                    </Link>
                )
            }
        },
        {
            label: 'Course Fee',
            name: "course_fee",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
                    <Link to={{ pathname: `${InnerPageRoutes.programView}/${tableMeta?.rowData[0]?.props?.children[0]}` }} className={classes.linkstyle}>
                        {value}
                    </Link>
                )
            }
        },
        {
            label: 'Credential',
            name: "credential",
            options: {
                customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
                    <Link to={{ pathname: `${InnerPageRoutes.programView}/${tableMeta?.rowData[0]?.props?.children[0]}` }} className={classes.linkstyle}>
                        {value}
                    </Link>
                )
            }
        },
        {
            label: 'Enabled / Disable',
            name: "status",
            options: {
                sort: false,
            }
        },
        {
            label: 'Action',
            name: "action",
            options: {
                sort: false
            }
        },
    ];

    const options = {
        search: false,
        print: false,
        viewColumns: false,
        filter: false,
        download: false,
        responsive: "vertical",
        expandableRows: false,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        selectableRows: "none",
        rowsPerPageOptions: [],
        textLabels: {
            body: {
                noMatch: searchData || data?.length == 0 && programs?.data?.length == 0 ?
                    'Sorry, there is no matching data to display' :
                    <Box style={{ alignItems: 'center', margin: "0 auto", justifyContent: "center" }}>
                        <CircularProgress sx={{ color: "#707070" }} />
                    </Box >
                ,
            },
        },
        customToolbar: () => {
            return (<>
                <TextField
                    variant="standard"
                    placeholder="Search"
                    onInput={(e: any) => handlefilter(e)}
                    value={filterVal}
                    className={classes.searchBar}
                    InputProps={{
                        style: { color: "black" },
                        endAdornment: (
                            <IconButton>
                                <SearchOutlined />
                            </IconButton>
                        ),
                    }}
                />

            </>)
        },
    };

    // const handleShow = (row: any) => {
    //     setShowProgram(!showProgram)
    //     setProgramData({ row })
    // }

    const handleProgramView = async (row: any) => {
        if (row) {
            setProgramName(row?.name);
            setProgramHours(Number(row?.total_duration_minutes / 60))
            const programData: any = await getProgramDetails(row?.id, token);
            if (programData?.data?.ProgramModules?.length > 0) {
                setViewProgramInfos(programData?.data?.ProgramModules);
                setProgramView(true);
            }
        }
    }

    const handleClickOpen = async (row: any, state: any) => {
        if (!row.total_fees) {
            setDisableProgramId(row.id);
            setCourseFeeName(row.name);
            setTimeout(() => {
                setOpenCourseFee(true);
            }, 1000);
        } else {
            setStatus(state ? '0' : '1');
            setDisableProgramId(row.id);
            setTimeout(() => {
                setOpen(true);
            }, 1000);
        }
    };

    const handleClose = () => {
        setCourseFeeName("");
        setDisableProgramId("");
        setOpen(false);
        setOpenCourseFee(false);
        setProgramView(false);
    };

    const handleDisable = async (progId: string, state: any) => {
        let programObj = {
            status: state,
        };
        const resData: any = updateProgram(progId, programObj, token)
        if (!resData.error) {
            { state === "0" ? toast.success(PROGRAM_SUCCESSFULLY_DISABLED) : toast.success(PROGRAM_SUCCESSFULLY_ENABLED) };
            setTimeout(() => {
                setOpen(false);
            }, 2000);
        }
    }

    const handleFilter = (newValue: string) => {
        setFilters(newValue);
        setPage(1);
    };

    /*     const creactSortHandler = async () => {
            setOrder(order === "asc" ? "desc" : "asc");
        }; */
    const handleSearch = (value: string) => {
        setSearch(value);
    };

    const CollapsedRow = ({ row, childrenRow }: any) => {
        const [openFunction, setOpenFunction] = React.useState(false);
        return (
            <React.Fragment>
                <Table size="small" aria-label="purchases">
                    <TableHead>
                        <TableRow style={{ backgroundColor: "#707070" }}>
                            <TableCell></TableCell>
                            <TableCell style={{ color: "#FFFFFF" }}>Course Name</TableCell>
                            <TableCell style={{ color: "#FFFFFF" }}>Total Hours</TableCell>
                            <TableCell style={{ color: "#FFFFFF" }}>Total inclass training hours</TableCell>
                            <TableCell style={{ color: "#FFFFFF" }}>Total inyard training hours</TableCell>
                            <TableCell style={{ color: "#FFFFFF" }}>Total incab training hours</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={childrenRow?.id}>
                            <TableCell style={{ width: "62px" }}>
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpenFunction(!openFunction)}
                                >
                                    {openFunction ? (
                                        <KeyboardArrowUpIcon />
                                    ) : (
                                        <KeyboardArrowDownIcon />
                                    )}
                                </IconButton>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {childrenRow?.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {(+childrenRow.total_duration_minutes) / 60}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {(+childrenRow.total_inclass_training_minutes) / 60}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {(+childrenRow.total_inyard_training_minutes) / 60}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {(+childrenRow.total_incab_training_minutes) / 60}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse
                            in={openFunction}
                            timeout="auto"
                            unmountOnExit
                        >
                            {childrenRow?.ModuleCourseTopics?.length > 0 ?
                                <Box padding={1}>
                                    <Table size="small" aria-label="purchases">
                                        <TableHead>
                                            <TableRow style={{ backgroundColor: "#A2A2A2" }}>
                                                <TableCell></TableCell>
                                                <TableCell style={{ color: "#FFFFFF" }}>Topic Name</TableCell>
                                                <TableCell style={{ color: "#FFFFFF" }}>Total Hours</TableCell>
                                                <TableCell style={{ color: "#FFFFFF" }}>Total inclass training hours</TableCell>
                                                <TableCell style={{ color: "#FFFFFF" }}>Total inyard training hours</TableCell>
                                                <TableCell style={{ color: "#FFFFFF" }} >Total incab training hours</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {childrenRow?.ModuleCourseTopics?.map((childrenRow: any) => (
                                                <TableRow key={childrenRow?.id}>
                                                    <TableCell></TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {childrenRow?.name}
                                                    </TableCell>
                                                    <TableCell>{(+childrenRow.total_duration_minutes) / 60}</TableCell>
                                                    <TableCell>{(+childrenRow.total_inclass_training_minutes) / 60}</TableCell>
                                                    <TableCell>{(+childrenRow.total_inyard_training_minutes) / 60}</TableCell>
                                                    <TableCell>{(+childrenRow.total_incab_training_minutes) / 60}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Box> : null}
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    };

    function RowData(props: any) {
        const [openModule, setOpenModule] = useState(false);

        const { row }: any = props;
        return (<>
            <React.Fragment>
                <TableRow className={classes.root} key={row.id}>
                    <TableCell style={{ width: "62px" }} key={row.id} >
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenModule(!openModule)}
                        >
                            {openModule ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{(+row.total_duration_minutes) / 60}</TableCell>
                    <TableCell>{(+row.total_inclass_training_minutes) / 60}</TableCell>
                    <TableCell>{(+row.total_inyard_training_minutes) / 60}</TableCell>
                    <TableCell>{(+row.total_incab_training_minutes) / 60}</TableCell>
                </TableRow>
                <TableRow key={row.id}>
                    <TableCell key={row.id} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse key={row.id} in={openModule} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Table size="small" aria-label="purchases">
                                    <TableBody>
                                        {row?.Courses?.map((childrenRow: any) => (
                                            <CollapsedRow row={row} childrenRow={childrenRow} />
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        </>)
    }

    return (
        <>
            <ToastMessage />
            <CommonHeadPart
                componentText="Programs"
                Icon={ProgramIcon}
                buttonText="Add New Program"
                BreadCrumbs={BreadCrumbProgram}
                urlLink={InnerPageRoutes?.addNewProgram}
            />
            <div className={classes.divInsideMain}>
                {/* {!programs || (programs?.data?.length > 0 && data?.length == 0) ? (
                    <Box style={{ position: 'absolute', margin: '0 auto', top: '50%', left: '50%', justifyContent: 'center' }}>
                        <CircularProgress sx={{ color: '#707070' }} />
                    </Box>
                ) : programs?.data.length > 0 ?
                    ( */}
                <div className="main-datatable">
                    <ThemeProvider theme={theme} >
                        <MUIDataTable
                            title={<FilterTabs
                                SelectedFilter={filter}
                                Filters={ProgramsFilterTabs}
                                HandleChange={handleFilter}
                                isFilter={false}
                                HandleSearch={handleSearch}
                                isSearch={false}
                            />}
                            data={data}
                            columns={columns}
                            options={options}
                        />
                    </ThemeProvider >
                </div>
                {/* ) : (
                        <ThemeProvider theme={theme} >
                            <MUIDataTable
                                title={<FilterTabs
                                    SelectedFilter={filter}
                                    Filters={ProgramsFilterTabs}
                                    HandleChange={handleFilter}
                                    isFilter={false}
                                    HandleSearch={handleSearch}
                                    isSearch={false}
                                />}
                                data={data}
                                columns={columns}
                                options={options}
                            />
                        </ThemeProvider>
                    )} */}
            </div>

            {/* // <>
            //     <Typography variant="subtitle1" onClick={() => setShowProgram(!showProgram)} className={classes.subtitle} >
            //         <ArrowBackIosNewIcon fontSize="small" /> Back
            //     </Typography>
            //     <ProgramView  programdata={programData}  />
            // </> */}

            <BootstrapDialog
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    Program Enable/Disable
                </BootstrapDialogTitle>

                <DialogContent dividers className={classes.dialogContent} >
                    <RiErrorWarningFill color="#FDB924" size={30} style={{ marginRight: 10 }} />
                    {/* <img className={classes.icon} sizes={"10"} src={Icon} /> */}
                    <Typography variant="h6" component="div">
                        Are you sure you want to  {status === "0" ? "disable" : "enable"} this program?
                    </Typography>

                </DialogContent>

                <DialogActions className={classes.dialogAction} >
                    {/* <CancelButtonComponent ButtonLabel="No" /> */}
                    <Button
                        type="button"
                        variant="contained"
                        className={classes.noButton}
                        onClick={handleClose}
                    >
                        No
                    </Button>
                    {/* <SaveButtonComponent
                        ButtonType="submit"
                        ButtonLabel="Yes"
                    /> */}
                    <Button
                        type="button"
                        variant="contained"
                        className={classes.yesButton}
                        onClick={() => handleDisable(disableProgramId, status)}
                    >
                        Yes
                    </Button>
                </DialogActions>
            </BootstrapDialog>

            <BootstrapDialog
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
                aria-labelledby="customized-dialog-title"
                open={openCourseFee}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    Add Course Fee
                </BootstrapDialogTitle>

                <DialogContent dividers className={classes.dialogContent} >
                    {/* <RiErrorWarningFill color="#FDB924" size={35} /> */}
                    {/* <img className={classes.icon} sizes={"10"} src={Icon} /> */}
                    <Typography variant="h6" component="div">
                        Please update the course fee to enable the program <br /> "{courseFeeName}"
                    </Typography>
                </DialogContent>

                <DialogActions className={classes.dialogAction} >
                    {/* <CancelButtonComponent ButtonLabel="No" /> */}
                    <Button
                        type="button"
                        variant="contained"
                        className={classes.noButton}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    {/* <SaveButtonComponent
                        ButtonType="submit"
                        ButtonLabel="Yes"
                    /> */}
                    <Button
                        type="button"
                        variant="contained"
                        className={classes.yesButton}
                        onClick={() => history.push({ pathname: `${InnerPageRoutes.programView}/${disableProgramId}`, state: `${courseFeeName}`, key: "setFee" })}
                    >
                        Proceed
                    </Button>
                </DialogActions>
            </BootstrapDialog>

            <BootstrapDialog
                onClose={handleClose}
                fullWidth
                maxWidth="lg"
                aria-labelledby="customized-dialog-title"
                open={openProgramView}
            >
                {/* <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    View Program Details
                </BootstrapDialogTitle> */}

                <DialogContent dividers className={classes.dialogContent} >

                    <Row className="mb-3">
                        <Col lg="6" sm="6" xs="6">
                            <Typography align="left">
                                <b>Program Name:</b> {programName}
                            </Typography>
                        </Col>

                        <Col lg="6" sm="6" xs="6">
                            <Typography align="left">
                                <b>Total Program Hours:</b> {programHours}
                            </Typography>
                        </Col>
                    </Row>

                    <TableContainer component={Paper}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow style={{ background: "#404040" }}>
                                    <TableCell></TableCell>
                                    <TableCell style={{ color: "#FFFFFF" }}>Module Name</TableCell>
                                    <TableCell style={{ color: "#FFFFFF" }}>Total hours</TableCell>
                                    <TableCell style={{ color: "#FFFFFF" }}>Total inclass training hours</TableCell>
                                    <TableCell style={{ color: "#FFFFFF" }}>Total inyard training hours</TableCell>
                                    <TableCell style={{ color: "#FFFFFF" }}>Total incab training hours</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {viewProgramInfos?.map((row: any, index: number) => (
                                    <RowData key={row.id} row={row} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </BootstrapDialog >
        </>
    );
};

export default Programs;
