import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// import { Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DrawerItem } from "../ts";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      "&:hover": {
        color: "#00a651",
        backgroundColor: "rgba(0, 0, 0, 0.00) !important",

      },
      "&$selected": {
        paddingLeft: "8px",
        color: "#00a651",
        backgroundColor: "rgba(0, 0, 0, 0.00) !important",

      },
    },
    selected: {},


    listIcon: {
      minWidth: "auto",
      padding: "0px 10px",

    },
    icon: {
      width: "20px",
      height: "auto",
    },
    link: {
      textDecoration: "none",
      color: "#3D3D3D",
    },
  })
);

type Props = DrawerItem & {
  selected?: boolean;
  onClick?: () => void;
};

const MenuItem: React.FC<Props> = ({
  route,
  literal,
  Icon,
  selected,
  onClick,
}) => {
  const classes = useStyles();
  const AuthToken = useSelector((state: any) => state.auth);
  const { user } = AuthToken;

  const link = (
    <ListItem
      button
      selected={selected}
      classes={{ selected: classes.selected, button: classes.button }}
      className="sideber-options"
      onClick={onClick}
      title={literal === "My Plan" && user?.School?.License?.name === "Standard" ? "Upgrade Plan" : literal}
    >
      <div className="border-sloid1">
      </div>
      <ListItemIcon className={classes.listIcon}>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={literal} />
    </ListItem >
  );
  return route ? (
    <Link className={classes.link} to={route}>
      {link}
    </Link>
  ) : (
    link
  );
};

export default MenuItem;
