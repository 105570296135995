import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, Form } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Grid } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import OtpInput from "react-otp-input";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ROUTES } from "../../constants/routes";
import MainComponent from "./MainComponent";
import { isRequiredField, renderRequiredError } from "../../common-components/FormComponent/FormValidator";
import * as authAction from "../../reducer-store/actions/authActions";
import { toast } from "react-toastify";
import ToastMessage from "../../common-components/ToastMessage";

import Logo from "../../common-components/Logo/Logo";
import { styled } from '@mui/material/styles';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const useStyles = makeStyles((theme) => ({
  sectionTop: {
    width: '100%',
    maxWidth: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: "#ffffff",
  },
  loginTitle: {
    fontSize: "40px",
    textAlign: "center",
    marginBottom: "40px",
    fontWeight: 700,
    color: "#000000",
    '@media (max-width: 1200px)': {
      fontSize: "32px",
    },
    '@media (max-width: 991px)': {
      fontSize: "30px",
    },
    '@media (max-width: 575px)': {
      fontSize: "26px",
    }
  },
  forgotpass: {
    fontSize: "20px",
    textAlign: "right",
    marginBottom: "20px",
    fontWeight: 600,
    color: "#000000",
  },
  forgotpass1: {
    textDecoration: "none",
    color: "black",
    "&:hover": {
      color: "#000000",
      textDecoration: "none",
    },
  },
  cardMain: {
    '@media (max-width: 991px)': {
      marginTop: "30px",
    },
  },
  timerResend: {
    textAlign: 'center',
    font: 'normal normal normal 16px/21px Arial',
  },
  resend: {
    cursor: 'pointer',
    marginBottom: '4px',
    color: '#00A651',
    textAlign: 'center',
    backgroundColor: '#FFFFFF',
    border: 'none',
    font: 'normal normal normal 16px / 21px Arial',
    "&:hover": {
      color: '#00A651',
      backgroundColor: '#FFFFFF',
      textDecoration: "none",
    },
  },
  resend1: {
    textAlign: 'center',
    font: 'normal normal normal 16px / 21px Arial',
    letterSpacing: ' 0px',
    color: '#7E7E7E',
  },
  textColor: {
    font: 'normal normal normal 16px / 18px Arial',
    letterSpacing: '0px',
    color: '#00A651',
    textAlign: 'center'
  },
  logoP: {
    position: "fixed",
    '@media (max-width: 768px),(min-width: 480px)': {
      padding: "15px 30px",
    },
    '@media (max-width: 480px)': {
      padding: "15px 10px",
    }
  },
  footer: {
    position: "absolute",
    bottom: 0,
    backgroundColor: ' #000000',
    width: '100%',
    minHeight: 'auto',
    '@media (max-width: 768px)': {
      position: 'absolute',
      minHeight: 'auto',
      bottom: 0,
    },
  },
  FooterText: {
    textAlign: 'center',
    color: '#ffffff',
    margin: '15px 0px',
    '@media (max-width: 768px)': {
      margin: '15px 0px',
    },
  },
}));

const Onetimepass_f2 = (props: any) => {
  const classes = useStyles();
  const [otp, setOtp] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isValidValues, setIsValidValues] = useState(true);
  const [isCheckValidEmailValue, setisCheckValidEmailValue] = useState(false);
  const [isCheckValidNoValue, setisCheckValidNoValue] = useState(false);
  const [isCheckValidPhoneValue, setisCheckValidPhoneValue] = useState(false);
  const [isCheckValidRID, setisCheckValidRID] = useState(false);
  const [isCheckValidAddress, setisCheckValidAddress] = useState(false);
  const [isCheckValidIPAddress, setisCheckValidIPAddress] = useState(false);
  const [isCheckValidPassword, setisCheckValidPassword] = useState(false);
  const [show, setShow] = useState(false);
  const [timer, setTimer] = useState(59);
  const timeOutCallback = useCallback(() => setTimer(currTimer => currTimer - 1), []);
  const isCheckString = false;
  const history = useHistory();
  const { verifyForgotPasswordOTPCode, sentForgotPasswordOTPCode } = authAction;
  const [loaded, setLoader] = useState(false);

  useEffect(() => {
    setShow(false);
    if (timer == 0) {
      setShow(true);
    } else {
      timer > 0 && setTimeout(timeOutCallback, 1000);
    }
    // return () => { clearInterval(timer); setShow(true) }
  }, [timer, timeOutCallback]);

  const resetTimer = function () {
    if (!timer) {
      setTimer(59);
    }
  };

  const SubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    if (isRequiredField(otp)) {
      setIsSubmit(false);
      setLoader(true);
      const mail = localStorage.getItem("forgot-email");
      let userObj = {
        email: mail,
        otp: otp
      }
      let resData: any = await verifyForgotPasswordOTPCode(userObj);
      if (!resData.error) {
        toast.success("OTP verified successfully");
        setTimeout(() => {
          setLoader(false);
          history.push(ROUTES.newPassword);
        }, 2000);
      }
      return;
    }
    setIsValidValues(false);
  };

  const ResendOtp = async (e: React.MouseEvent) => {
    resetTimer();
    const email: any = localStorage.getItem("forgot-email");
    let resData: any = await sentForgotPasswordOTPCode(email);
    if (!resData.error) {
      toast.success("Resent OTP.");
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
  }

  return (
    <MainComponent>
      <StyledRoot>
        <ToastMessage />
        <Box className={classes.logoP}>
          <Link to={ROUTES.login}><Logo size={30} /></Link>
        </Box>
        <section className={classes.sectionTop}>
          <ToastMessage />
          <Container className="mt-4">
            <Row className="stap-form">
              <Typography
                className={classes.loginTitle}
                variant="h5"
                component="div"
              >
                Enter One-Time Code
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                component="div"
                className={classes.textColor}
              >
                <CheckIcon />
                Check your Reset password OTP on your registered email
              </Typography>
              <Typography
                className={classes.loginTitle}
                variant="h5"
                component="div"
              ></Typography>
            </Row>
            <Row className="stap-form">
              <Col lg="6" sm="12" className=" offset-lg-3">
                <Box>
                  <Card className="Main_Card" variant="outlined">
                    {loaded && <LinearProgress />}
                    <Form onSubmit={SubmitHandler}>
                      <CardContent>
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          item
                          xs
                        >
                          <OtpInput
                            value={otp}
                            onChange={(otp: any) => {
                              setOtp(otp);
                            }}
                            numInputs={6}
                            inputStyle={{
                              borderBottomColor: '#000',
                              fontSize: "24px",
                              width: "40px",
                              height: "40px",
                              margin: "auto 10px",
                              borderTop: "0px",
                              borderLeft: "0px",
                              borderRight: "0px",
                              outline: "none",
                            }}
                            containerStyle={{
                              margin: "20px auto",
                              padding: "auto",
                            }}
                            isInputNum
                          />
                          {!isValidValues &&
                            renderRequiredError(otp, isSubmit, "Enter valid OTP", isCheckValidEmailValue, isCheckValidNoValue, isCheckValidPhoneValue, isCheckValidRID, isCheckValidAddress, isCheckValidIPAddress, isCheckValidPassword, isCheckString)}
                        </Grid>
                      </CardContent>
                      <CardContent>
                        <div className="logbutton">
                          <div className="text-center">
                            <Link
                              to="/forgot-password"
                              className="btn btn-style-white"
                            >
                              Back
                            </Link>
                            <button type="submit" className="btn btn-style">
                              Verify OTP
                            </button>
                          </div>
                        </div>
                      </CardContent>
                      <CardContent>
                        <Grid item xs direction="row" className={classes.timerResend}>
                          {show == true ?
                            (
                              <div style={{ alignSelf: 'center' }}>
                                <Typography
                                  variant="subtitle1"
                                  display="inline"
                                  style={{ marginRight: '10px' }}
                                >
                                  00:00
                                </Typography>

                                <span onClick={ResendOtp}>
                                  <Typography
                                    variant="caption"
                                    display="inline"
                                    className={classes.resend}
                                  >
                                    Resend Code
                                  </Typography>
                                </span>
                              </div>
                            ) : (
                              <div aria-disabled>
                                <Typography
                                  variant="subtitle1"
                                  display="inline"
                                  style={{ marginRight: '10px' }}
                                >
                                  00:{timer}
                                </Typography>

                                <Typography
                                  variant="caption"
                                  display="inline"
                                  className={classes.resend1}
                                >
                                  Resend Code
                                </Typography>
                              </div>)
                          }
                        </Grid>
                      </CardContent>
                    </Form>
                  </Card>
                </Box>
              </Col>
            </Row>
          </Container>
          <footer className={classes.footer}>
            <Container>
              <Row>
                <Col lg="12" sm="12">
                  <p className={classes.FooterText}>© 2023 Powered by Driving School Cloud.  All rights reserved.</p>
                </Col>
              </Row>
            </Container>
          </footer>
        </section>
      </StyledRoot>
    </MainComponent>
  );
};
export default Onetimepass_f2;
