import React from 'react';
import LoginHeader from './Login_header';
import LoginFooter from './Login_footer';
import '../Common.css';

type MainComponentProps = {
  children: React.ReactNode
}

const MainComponent: React.FC<MainComponentProps> = (props) => {
  return (
    <>
      {/* <LoginHeader /> */}
      <div className='d-flex flex-column justify-content-center'>
        {
          props.children
        }
      </div>
      {/* <LoginFooter /> */}
    </>
  );
}
export default MainComponent;
