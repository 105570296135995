import React, { useState, useEffect } from "react";
import "../../Common.css";
import "bootstrap/dist/css/bootstrap.min.css";
// import { makeStyles } from "@material-ui/core/styles";
import { Box, Table, TableBody, TableHead, TableRow } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import PaginationComponent from "../../../common-components/PaginationComponent";
// import Paypal from '../../../svg_icons/paypal.png';
import Master from '../../../svg_icons/mastro.png';
import Visa from '../../../svg_icons/visa.png';
import { bindActionCreators } from "redux";
import * as Actions from '../../../reducer-store/actions/paymentsAction'
import { useDispatch, useSelector } from "react-redux";
import { LIST_OF_PAYMENTS_ERROR } from "../../../reducer-store/ActionTypes";
import { CircularProgress } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@mui/material/TableCell";
import { LIMIT, PAGE } from "../../../reducer-store/constants";
// import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { DownloadForOffline } from "@mui/icons-material";
import { green } from "@mui/material/colors";
import { toast } from "react-toastify";
import GppGoodRoundedIcon from "@mui/icons-material/GppGoodRounded";
import { Col } from "react-bootstrap";
import ToastMessage from "../../../common-components/ToastMessage";


const useStyles = makeStyles(() => ({
    boxBorder1: {
        textAlign: "center",
        margin: "20px 0px",
    },
    TransferInitiated: {
        color: "#00a651",
    },
    TransferFaild: {
        color: "red",
    },
    line: {
        backgroundColor: "#00a651",
        width: "100%",
        height: "5px",
        marginTop: "2.5%",
    },
    Right: {
        textAlign: "end",
    },
    Left: {
        textAlign: "start",
    },
}));

const theme = createTheme({
    components: {
        MuiTableHead: {
            styleOverrides: {
                root: {
                    "& .css-1ygcj2i-MuiTableCell-root": {
                        fontweight: "bold",
                        backgroundColor: '#FFFFFF'
                    },
                    "& .tss-1h9t3sl-MUIDataTableHeadCell-sortAction": {
                        display: "-webkit-box"
                    },
                    "& .tss-1fbujeu-MUIDataTableHeadCell-toolButton": {
                        backgroundColor: '#FFFFFF'
                    },
                    '.tss-1akey0g-MUIDataTableHeadCell-data': {
                        fontSize: '15px'
                    },
                    "& .tss-1832qqn-MUIDataTableHeadRow-root": {
                        borderBottomColor: '#000',
                        borderBottomWidth: '1.2px',
                    },
                    "& .tss-10syd3x-MUIDataTableHeadCell-root": {
                        fontSize: '15px',
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "Arial"
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontFamily: "Arial",
                },
                head: {
                    backgroundColor: "#FFFFFF"
                },
                footer: {
                    backgroundColor: "#FFFFFF"
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                colorInherit: {
                    backgroundColor: '#E53D12',
                    borderRadius: '4px',
                    color: 'white',
                    marginRight: '25px',
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    "&:nth-child(odd)": {
                        backgroundColor: "#F4F4F4"
                    },
                }
            }
        },
        MuiTableBody: {
            styleOverrides: {
                root: {
                    "& .css-1ex1afd-MuiTableCell-root": {
                        width: "13%",
                    }
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    "& .MuiTablePagination-displayedRows": {
                        marginTop: "9%"
                    },
                }
            }
        },
    }
});

const Faild: React.FC = () => {
    const classes = useStyles();
    const [page, setPage] = useState(PAGE);
    const [search, setSearch] = useState('');
    const [order, setOrder] = useState("asc");
    const AuthToken = useSelector((state: any) => state.auth);
    const { token } = AuthToken;
    const [data, setData] = useState([]);
    const [searchData, setSearchData] = useState(false);
    const dispatch = useDispatch();
    const { studentPaymentsList, generatePaymentUrl } = Actions;
    const listOfPayment = useSelector((state: any) => state.payment);
    const { payments } = listOfPayment;
    const status = 2

    const handleSearch = (value: string) => {
        setSearch(value);
    };

    useEffect(() => {
        (async () => {
            dispatch({ type: LIST_OF_PAYMENTS_ERROR })
            const newFailedPaymentList = await studentPaymentsList(status, token);
            renderDataTable(newFailedPaymentList);
        })();
    }, [page]);

    const paymentStatus = (value: number) => {
        switch (value) {
            case 0:
                return "Failed";
            case 1:
                return "Received";
            case 2:
                return "Failed";
            case 3:
                return "Cancelled";
            case 4:
                return "Refund Requested";
            case 5:
                return "Refunded";
        }
    };

    // const paymentUrl = async (paymentId: any) => {
    //     let obj = {
    //         "payment_id": paymentId,
    //     }
    //     const resData = await generatePaymentUrl(obj, token);

    //     if (!resData?.error) {
    //         toast.success("Invoice generated successfully");
    //         setTimeout(() => {
    //             navigateToExternalUrl(resData?.invoice_url, true);
    //         }, 2000)
    //     }
    // }

    // const navigateToExternalUrl = async (urlName: string, shouldOpenNewTab: boolean = true) => {
    //     shouldOpenNewTab ? window.open(urlName, "_blank") : window.location.href = urlName;
    //     return;
    // }

    const renderDataTable = (paymentsList: any) => {
        var localArr: any = []
        const rowsDataTable = paymentsList && paymentsList.data.map((row: any, index: number) => {
            localArr.push(row?.symbol.concat(" ", Number(row?.total_amount).toFixed(2)))
            return {
                id: index + 1,
                date: row?.paid_on ? moment(row?.paid_on).format("YYYY-MM-DD") : "--",
                amount: localArr[index],
                // amount: <>{row.symbol ? row?.symbol : "--"} {" "} {Number(row?.total_amount).toFixed(2)}</>,
                status: paymentStatus(row?.status) || "--",
                // transaction_id: <>{row.PaymentInfos.length ? row.PaymentInfos[0]?.transaction : "--"}</>,
                transaction_id: row.PaymentInfos.length > 0 && row.PaymentInfos[0]?.transaction !== null ? row.PaymentInfos[0]?.transaction : "--",
                fee_type: row?.details ? row?.details : row?.feeType ? row?.feeType : "--",
                // fee_type: <>{row?.details ? row?.details : row?.feeType ? row?.feeType : "--"}</>,
                instrument_logo: row?.PaymentInfos[0]?.network != null ? cardshow(row?.PaymentInfos[0]?.network) : "--",
                transaction_acc: row?.PaymentInfos[0]?.last4 != null ? `**** **** **** ${row?.PaymentInfos[0]?.last4}` : "--",
                // invoice: <>{row.status == "1" ?
                //     <span onClick={() => paymentUrl(row?.payment_id)}>
                //         <DownloadForOffline sx={{ fill: green[600], cursor: 'pointer' }} />
                //     </span> : null}</>,
                transfer_initiate: row?.created_at,
                transfer_complete: row?.paid_on,
                paymentgateway_id: row?.payment_id,
                payStatus: row?.status,
                gateway_name: row?.gateway_name,
            };
        });
        setData(rowsDataTable)
    };

    const columns = [
        {
            label: 'Sr No',
            name: "id",
        },
        {
            label: 'Date',
            name: "date",
        },
        {
            label: 'Amount',
            name: "amount",
        },
        {
            label: 'Status',
            name: "status",
        },
        {
            label: 'Transaction ID',
            name: "transaction_id",
        },
        {
            label: 'Payment Details',
            name: "fee_type",
            options: {
                sort: true,
            }
        },
        {
            label: 'Card Type',
            name: "instrument_logo",
            options: {
                sort: false,
            }
        },
        {
            label: 'Transaction Acc',
            name: "transaction_acc",
        },
        // {
        //     label: 'Invoice',
        //     name: "invoice",
        // },
        {
            label: "transfer_initiate",
            name: "transfer_initiate",
            options: {
                display: false,
            }
        },
        {
            label: "transfer_complete",
            name: "transfer_complete",
            options: {
                display: false,
            }
        },
        {
            label: 'Payment Gateway Id',
            name: "paymentgateway_id",
            options: {
                display: false,
            }
        },
        {
            label: 'payStatus',
            name: "payStatus",
            options: {
                display: false,
            }
        },
        {
            label: '',
            name: "gateway_name",
            options: {
                display: false,
            }
        },
    ];

    const options = {
        search: false,
        print: false,
        viewColumns: false,
        filter: false,
        download: false,
        responsive: "vertical",
        expandableRows: true,
        expandableRowsHeader: false,
        expandableRowsOnClick: false,
        selectableRows: "none",
        rowsPerPageOptions: [],
        fixedHeader: false,
        textLabels: {
            body: {
                noMatch: searchData || data?.length == 0 ?
                    'Sorry, there is no matching data to display' :
                    <Box style={{ alignItems: 'center', margin: "0 auto", justifyContent: "center" }}>
                        <CircularProgress sx={{ color: "#707070" }} />
                    </Box >
                ,
            },
        },
        renderExpandableRow: (rowData: any) => {
            console.log("rowData", rowData)
            return (
                <TableRow >
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                        <Box className={classes.boxBorder1}>
                            <div className="row">
                                <Col lg="4" sm="4" xs="12" className={classes.Right}>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        component="div"
                                        className={classes.TransferInitiated}
                                    >
                                        <GppGoodRoundedIcon />
                                        Transfer Initiated
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                        component="div"
                                    >
                                        Timestamp : {rowData[7] ? moment(rowData[7]).format("MMM D, YYYY | h:mm:ss A") : "--"}
                                    </Typography>
                                </Col>
                                <Col lg="4" sm="4" xs="12">
                                    <div className={classes.line}></div>
                                </Col>

                                <Col lg="4" sm="4" xs="12" className={classes.Left}>
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                        component="div"
                                        className={rowData[10] === 2 ? classes.TransferFaild : classes.TransferInitiated}
                                    >
                                        <GppGoodRoundedIcon />
                                        Transfer {!rowData[11] ? "Cash" : paymentStatus(rowData[10])}
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                        component="div"
                                    >
                                        Timestamp :  {rowData[8] ? moment(rowData[8]).format("MMM D, YYYY | h:mm:ss A") : "--"}
                                    </Typography>
                                    <Typography
                                        variant="subtitle1"
                                        gutterBottom
                                        component="div"
                                    >
                                        {!rowData[11] ? "Cash Transaction" : "Payment Gateway"} ID : {rowData[9] || "--"}
                                    </Typography>
                                </Col>
                            </div>
                        </Box>
                    </TableCell>
                </TableRow >
            );
        },
    };

    const cardshow = (value: any) => {
        if (value?.length === 0) {
            return "---";
        }
        switch (value?.brand) {
            case "visa":
                return <img src={Visa} alt="visa" />;
            case "master":
                return <img src={Master} alt="visa" />;
            default:
                return <img src={Visa} alt="visa" />;
        }
    };

    return (
        <>
            <ToastMessage />
            <ThemeProvider theme={theme}>
                <MUIDataTable
                    title={""}
                    data={data}
                    columns={columns}
                    options={options}
                />
            </ThemeProvider>
        </>);
};
export default Faild;