import React from "react";
import {
  BreadCrumbComponent,
  ButtonComponent,
  ComponentHeading,
} from "./index";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { CommonHeadPartProps } from "../ts";
// import { FilterButtonComponent } from "./ButtonComponent";

const useStyles = makeStyles(() => ({
  divInsideMain: {
    boxShadow: "0 0 5px #8e8e8e",
    borderRadius: "3px",
  },
  paddingZero: {
    padding: "0 0 20px 0",
  },
  marginRight: {
    marginRight: "10px",
  },
}));

const CommonHeadPart: React.FC<CommonHeadPartProps> = ({
  componentText,
  Icon,
  buttonText = "",
  BreadCrumbs,
  urlLink = "/",
}): JSX.Element => {
  const classes = useStyles();
  return (
    <Container className={classes.paddingZero} fluid>
      <Row>
        <Col sm={6}>
          <Row>
            <Col sm={12}>
              <ComponentHeading text={componentText}>
                <img className={classes.marginRight} src={Icon} />
              </ComponentHeading>
            </Col>
            <Col sm={12}>
              <BreadCrumbComponent BreadCrumbs={BreadCrumbs} />
            </Col>
          </Row>
        </Col>
        {buttonText !== "" && (
          <Col sm={6}>
            <ButtonComponent buttonText={buttonText} link={urlLink} />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default CommonHeadPart;
