import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ROUTES } from "../../../constants/routes"
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { CancelButtonComponent } from "../../../common-components/ButtonComponent";
import CommonHeadPart from '../../../common-components/CommonHeadPart';
import * as Actions from "../../../reducer-store/actions/StaffManagementAction";
import { useDispatch, useSelector } from "react-redux";
import ToastMessage from "../../../common-components/ToastMessage";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import moment from "moment";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import StaffManagementIcon from "../../../svg_icons/StaffManagement.svg";
import { fileSignedURL } from "../../../reducer-store/actions/authActions";
import AttachmentIcon from '@mui/icons-material/Attachment';
import { CircularProgress } from "@mui/material";
import { InputField } from "../../../common-components/FormComponent/FormFieldsComponent";

const useStyles = makeStyles(() => ({
  CardTitle: {
    color: "black",
  },
  linkStyle: {
    textDecoration: "none",
    color: "inherit",
  },
  inputlabel: {
    fontSize: "16px",
    fontWeight: 600,
    display: "flex"
  },
  uploadfile: {
    cursor: 'pointer'
  },
}));

const BreadCrumbNewStaffInstructor = [
  {
    labelText: "Home",
    linkUrl: ROUTES.dashboard,
  },
  {
    labelText: "Staff Management",
    linkUrl: ROUTES.staffManagement,
  },
  {
    labelText: "View Staff",
    linkUrl: "",
  },
];

const ViewStaffInstructor: React.FC = () => {
  const classes = useStyles();
  const params: any = useParams();
  const { id } = params;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [selectType, setSelectType] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dateofbirth, setDateOfBirth] = useState("");
  const [houseaptnumber, setHouseAptNumber] = useState("");
  const [streetname, setStreetName] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [postCode, setPostcode] = useState("");
  const [country, setCountry] = useState("");
  const [emergencyContact, setEmergencyContact] = useState("");
  const [jobStartDate, setJobStartDate] = useState("");
  const [jobEndDate, setJobEndDate] = useState("");
  const [employementType, setEmployementType] = useState("");
  const [taxNumber, setTaxNumber] = useState("");
  const [jobType, setJobType] = useState("1");
  const AuthToken = useSelector((state: any) => state.auth);
  const { token, user } = AuthToken;
  const dispatch = useDispatch();
  const { SingleStaffManagementData } = bindActionCreators(Actions, dispatch);
  const [saveUpdate, setSaveUpdate] = useState(true);
  const [checked, setChecked] = useState(true);
  const [status, setStatus] = useState('0');
  const [photoIdShow, setPhotoIdShow] = useState<any>("");
  const [profilePhotoShow, setProfilePhotoShow] = useState<any>("");
  const [profilePhoto, setProfilePhoto] = useState("");
  const [medicalCertificateShow, setMedicalCertificateShow] = useState<any>("");
  const [policeCertificateShow, setPoliceCertificateShow] = useState<any>("");
  const [drivingLicenseShow, setDrivingLicenseShow] = useState<any>("");
  const [teachingLicenseShow, setTeachingLicenseShow] = useState<any>("");
  const [doc1Show, setDoc1Show] = useState<any>("");
  const [doc2Show, setDoc2Show] = useState<any>("");
  const [doc3Show, setDoc3Show] = useState<any>("");
  const [doc4Show, setDoc4Show] = useState<any>("");
  const [doc1_type, setDoc1_type] = useState<any>("");
  const [doc2_type, setDoc2_type] = useState<any>("");
  const [doc3_type, setDoc3_type] = useState<any>("");
  const [doc4_type, setDoc4_type] = useState<any>("");
  const [drivingRecordShow, setDrivingRecordShow] = useState<any>("");
  const [experienceShow, setExperienceShow] = useState<any>("");
  const [experience, setExperience] = useState("");
  const [refLetter1Show, setRefLetter1Show] = useState<any>("");
  const [refLetter2Show, setRefLetter2Show] = useState<any>("");

  const navigateToExternal = async (urlName: string, shouldOpenNewTab: boolean = true) => {
    const newData: { url: string, msg: string, error: boolean } = await fileSignedURL(urlName && urlName, token);
    const { url, msg, error } = newData;
    setProfilePhoto(url)
    /*  if (error) {
       toast.error(msg);
       return;
     } */
    // shouldOpenNewTab ? window.open(url, "_blank") : window.location.href = url;
    return;
  }

  useEffect(() => {
    (async () => {
      if (params && params.id) {
        const response: any = await SingleStaffManagementData(id, token);
        const { data } = response.data;
        setName(data && data.name);
        setEmail(data && data.email);
        setPhoneNumber(data && data.phone);
        setDateOfBirth(data && moment(data.dob).format("MM/DD/YYYY"));
        setHouseAptNumber(data && data.house_address);
        setStreetName(data && data.street_address);
        setCity(data && data.city);
        setSelectType(data && data.type);
        setProvince(data && data.province);
        setPostcode(data && data.postcode);
        setCountry(data && data.country);
        setEmergencyContact(data && data.emergency_phone);
        setJobStartDate(data && moment(data.joining_date).format("MM/DD/YYYY"));
        setJobEndDate(data && moment(data.last_updated).format("MM/DD/YYYY"));
        setEmployementType(data && data.employment_type);
        setTaxNumber(data && data.tax_number);
        setJobType(data && data.job_type);
        { data && data.status === 1 ? setChecked(true) : setChecked(false) }
        { data && data.status === 1 ? setStatus('1') : setStatus('0') }
        setPhotoIdShow(data && data.photoId_url);
        setProfilePhotoShow(data && data.profile_photo_url);
        navigateToExternal(data && data?.profile_photo_url)
        setMedicalCertificateShow(data && data.medical_proof_url);
        setPoliceCertificateShow(data && data.police_proof_url);
        setDrivingLicenseShow(data && data.drivinglicense_proof_url);
        setTeachingLicenseShow(data && data.teachinglicense_proof_url);
        setDoc1Show(data && data.doc1_url);
        setDoc2Show(data && data.doc2_url);
        setDoc3Show(data && data.doc3_url);
        setDoc4Show(data && data.doc4_url);
        setDoc1_type(data && data?.doc1_type);
        setDoc2_type(data && data?.doc2_type);
        setDoc3_type(data && data?.doc3_type);
        setDoc4_type(data && data?.doc4_type);
        setDrivingRecordShow(data && data?.driving_record_url);
        setExperienceShow(data && data?.experience_letter_url);
        setExperience(data && data?.no_of_years)
        setRefLetter1Show(data && data?.reference_letter1_url);
        setRefLetter2Show(data && data?.reference_letter2_url);
        setSaveUpdate(false)
      }
    })();
  }, [params]);

  if (saveUpdate) {
    return <><Box style={{ position: 'absolute', margin: '0 auto', top: '50%', left: '50%', justifyContent: 'center' }}>
      <CircularProgress sx={{ color: '#707070' }} />
    </Box></>;
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (event.target.checked === true) {
      setStatus('1');
    } else {
      setStatus('0');
    }
  };

  const navigateToExternalUrl = async (urlName: string, shouldOpenNewTab: boolean = true) => {
    const newData: { url: string, msg: string, error: boolean } = await fileSignedURL(urlName && urlName, token);
    const { url, msg, error } = newData;
    /*  if (error) {
       toast.error(msg);
       return;
     } */
    shouldOpenNewTab ? window.open(url, "_blank") : window.location.href = url;
    return;
  }

  return (<>
    <ToastMessage />
    <Row>
      <Col lg="12" sm="12">
        <CommonHeadPart
          componentText="Staff Management"
          Icon={StaffManagementIcon}
          buttonText=""
          urlLink=""
          BreadCrumbs={BreadCrumbNewStaffInstructor}
        />
      </Col>
    </Row>
    <Card>
      <div className={classes.CardTitle}>
        <CardContent>
          <Typography variant="h5" align="justify">
            View Staff Details
          </Typography>
        </CardContent>
        <hr className="hrline" />
      </div>
      <CardContent>
        <form >
          <Row>
            <Col lg="4" sm="12">
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="text"
                label="Name"
                className="form-textfield-styles"
                value={name}
                disabled={true}
              />
            </Col>
            <Col lg="4" sm="12">
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="text"
                label="Email-Id"
                className="form-textfield-styles"
                value={email}
                disabled={true}
              />
            </Col>
            <Col lg="4" sm="12">
              <FormControlLabel
                value="top"
                control={<Switch checked={checked} onChange={handleChange} color={checked ? "success" : "primary"} />}
                label="Enable/Disable"
                labelPlacement="top"
                className="form-textfield-styles"
                disabled={true}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="4" sm="12">
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="text"
                label="Select Type"
                className="form-textfield-styles"
                value={selectType === "instructor" ? "Instructor" : "General Staff"}
                disabled={true}
              />
            </Col>
            <Col lg="4" sm="12">
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="text"
                label="Phone Number"
                className="form-textfield-styles"
                value={phoneNumber}
                disabled={true}
              />
            </Col>
            <Col lg="4" sm="12">
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="text"
                label="Date of Birth"
                className="form-textfield-styles"
                value={dateofbirth}
                disabled={true}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="4" sm="12">
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="text"
                label="House/Apt Number"
                className="form-textfield-styles"
                value={houseaptnumber}
                disabled={true}
              />
            </Col>
            <Col lg="4" sm="12">
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="text"
                label="Street Name"
                className="form-textfield-styles"
                value={streetname}
                disabled={true}
              />
            </Col>
            <Col lg="4" sm="12">
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="text"
                label="City"
                className="form-textfield-styles"
                value={city}
                disabled={true}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="4" sm="12">
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="text"
                label="Province"
                className="form-textfield-styles"
                value={province}
                disabled={true}
              />
            </Col>
            <Col lg="4" sm="12">
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="text"
                label="Postcode"
                className="form-textfield-styles"
                value={postCode}
                disabled={true}
              />
            </Col>
            <Col lg="4" sm="12">
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="text"
                label="Country"
                className="form-textfield-styles"
                value={country}
                disabled={true}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="4" sm="12">
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="text"
                label="Emergency Contact"
                className="form-textfield-styles"
                value={emergencyContact}
                disabled={true}
              />
            </Col>
            <Col lg="4" sm="12">
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="text"
                label="Job Start Date"
                className="form-textfield-styles"
                value={jobStartDate}
                disabled={true}
              />
            </Col >
            <Col lg="4" sm="12">
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="text"
                label="Job End Date"
                className="form-textfield-styles"
                value={jobEndDate}
                disabled={true}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="4" sm="12">
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="text"
                label="Employment Type Label"
                className="form-textfield-styles"
                value={employementType}
                disabled={true}
              />
            </Col>
            <Col lg="4" sm="12">
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="text"
                label="Tax Number"
                className="form-textfield-styles"
                value={taxNumber}
                disabled={true}
              />
            </Col>
            <Col lg="4" sm="12">
              <TextField
                InputLabelProps={{ shrink: true }}
                fullWidth
                type="text"
                label="Job Type"
                className="form-textfield-styles"
                value={jobType === "2" ? "Part Time" : "Full Time"}
                disabled={true}
              />
            </Col>
          </Row>

          <Row >
            <Col lg="4" sm="12">
              <Typography className={classes.inputlabel}>Photo Id </Typography>
              <span onClick={() => navigateToExternalUrl(photoIdShow, true)}>
                {
                  photoIdShow !== null ?
                    <Typography
                      title="Click to check uploaded attachement"
                      className={classes.uploadfile}>
                      ({photoIdShow?.split(`${window.location.host.split('.')[0]}/`).pop().split('?')[0]} &nbsp;
                      <AttachmentIcon />)
                    </Typography> : <Typography>No Attachement Uploaded</Typography>
                }
              </span>

            </Col>
            <Col lg="4" sm="12">
              <Typography variant="body1" className={classes.inputlabel}>Profile Photo </Typography>
              <span onClick={() => navigateToExternalUrl(profilePhotoShow, true)}>
                {
                  profilePhotoShow !== null ?
                    <Typography
                      className={classes.uploadfile}
                      title="Click to check uploaded attachement">
                      ({profilePhotoShow?.split(`${window.location.host.split('.')[0]}/`).pop().split('?')[0]} &nbsp;
                      <AttachmentIcon />)
                    </Typography>
                    : <Typography>No Attachement Uploaded</Typography>
                }
              </span>
            </Col>

            {
              selectType === "instructor" ? (
                <>
                  {medicalCertificateShow ?
                    <Col lg="4" sm="12">
                      <Typography variant="body1" className={classes.inputlabel}>Medical Certificate </Typography>
                      <span onClick={() => navigateToExternalUrl(medicalCertificateShow, true)}>
                        {
                          medicalCertificateShow !== null ?
                            <Typography
                              title="Click to check uploaded attachement"
                              className={classes.uploadfile} >
                              ({medicalCertificateShow?.split(`${window.location.host.split('.')[0]}/`).pop().split('?')[0]} &nbsp;
                              <AttachmentIcon />)
                            </Typography> : <Typography>No Attachement Uploaded</Typography>
                        }
                      </span>
                    </Col>
                    : <></>}

                  {policeCertificateShow ?
                    <Col lg="4" sm="12">
                      <Typography variant="body1" className={classes.inputlabel}>Police Clearance Certificate  </Typography>
                      <span onClick={() => navigateToExternalUrl(policeCertificateShow, true)}>
                        {
                          policeCertificateShow !== null ?
                            <Typography
                              title="Click to check uploaded attachement"
                              className={classes.uploadfile}>
                              ({policeCertificateShow?.split(`${window.location.host.split('.')[0]}/`).pop().split('?')[0]} &nbsp;
                              <AttachmentIcon />)
                            </Typography> : <Typography>No Attachement Uploaded</Typography>
                        }
                      </span>
                    </Col>
                    : <></>}

                  {drivingLicenseShow ?
                    <Col lg="4" sm="12">
                      <Typography variant="body1" className={classes.inputlabel}>Commercial License</Typography>
                      <span onClick={() => navigateToExternalUrl(drivingLicenseShow, true)}>
                        {
                          drivingLicenseShow !== null ?
                            <Typography
                              title="Click to check uploaded attachement"
                              className={classes.uploadfile}>
                              ({drivingLicenseShow?.split(`${window.location.host.split('.')[0]}/`).pop().split('?')[0]} &nbsp;
                              <AttachmentIcon />)
                            </Typography> : <Typography>No Attachement Uploaded</Typography>
                        }
                      </span>
                    </Col>
                    : <></>}

                  {refLetter1Show ?
                    <Col lg="4" sm="12">
                      <Typography variant="body1" className={classes.inputlabel}>Driving Record</Typography>
                      <span onClick={() => navigateToExternalUrl(refLetter1Show, true)}>
                        {
                          refLetter1Show !== null ?
                            <Typography
                              title="Click to check uploaded attachement"
                              className={classes.uploadfile}>
                              ({refLetter1Show?.split(`${window.location.host.split('.')[0]}/`).pop().split('?')[0]} &nbsp;
                              <AttachmentIcon />)
                            </Typography> : <Typography>No Attachement Uploaded</Typography>
                        }
                      </span>
                    </Col>
                    : <></>}

                  {refLetter2Show ?
                    <Col lg="4" sm="12">
                      <Typography variant="body1" className={classes.inputlabel}>Experience Certificate</Typography>
                      <span onClick={() => navigateToExternalUrl(refLetter2Show, true)}>
                        {
                          refLetter2Show !== null ?
                            <Typography
                              title="Click to check uploaded attachement"
                              className={classes.uploadfile}>
                              ({refLetter2Show?.split(`${window.location.host.split('.')[0]}/`).pop().split('?')[0]} &nbsp;
                              <AttachmentIcon />)
                            </Typography> : <Typography>No Attachement Uploaded</Typography>
                        }
                      </span>
                    </Col>
                    : <></>}

                  {experienceShow ?
                    <>
                      <Col lg="2" sm="12">
                        <Typography variant="body1" className={classes.inputlabel}>Experience Letter</Typography>
                        <span onClick={() => navigateToExternalUrl(experienceShow, true)}>
                          {
                            experienceShow !== null ?
                              <Typography
                                title="Click to check uploaded attachement"
                                className={classes.uploadfile}>
                                ({experienceShow?.split(`${window.location.host.split('.')[0]}/`).pop().split('?')[0]} &nbsp;
                                <AttachmentIcon />)
                              </Typography> : <Typography>No Attachement Uploaded</Typography>
                          }
                        </span>
                      </Col>
                      {experience ?
                        <Col lg="4" sm="12">
                          <Typography variant="body1" className={classes.inputlabel}>No Of Years</Typography>
                          <Typography
                            title="Click to check uploaded attachement"
                            className={classes.uploadfile}>
                            {experience}
                          </Typography>
                        </Col>
                        : <></>}
                    </>
                    : <></>}
                </>) : (<></>)
            }

            {doc1Show ?
              <>
                <Col lg="2" sm="12">
                  <Typography variant="body1" className={classes.inputlabel}>Other Document 1  </Typography>
                  <span onClick={() => navigateToExternalUrl(doc1Show, true)}>
                    {
                      doc1Show !== null ?
                        <Typography
                          title="Click to check uploaded attachement"
                          className={classes.uploadfile}>
                          ({doc1Show?.split(`${window.location.host.split('.')[0]}/`).pop().split('?')[0]} &nbsp;
                          <AttachmentIcon />)
                        </Typography> : <Typography>No Attachement Uploaded</Typography>
                    }
                  </span>
                </Col>
                <Col lg="2" sm="12">
                  <Typography variant="body1" className={classes.inputlabel}>Document Type 1</Typography>
                  <Typography
                    className={classes.uploadfile}>
                    {doc1_type}
                  </Typography>
                </Col>
              </>
              : <></>}

            {doc2Show ?
              <>
                <Col lg="2" sm="12">
                  <Typography variant="body1" className={classes.inputlabel}>Other Document 2 </Typography>
                  <span onClick={() => navigateToExternalUrl(doc2Show, true)}>
                    {
                      doc2Show !== null ?
                        <Typography
                          title="Click to check uploaded attachement"
                          className={classes.uploadfile}>
                          ({doc2Show?.split(`${window.location.host.split('.')[0]}/`).pop().split('?')[0]} &nbsp;
                          <AttachmentIcon />)
                        </Typography> : <Typography>No Attachement Uploaded</Typography>
                    }
                  </span>
                </Col>
                <Col lg="2" sm="12">
                  <Typography variant="body1" className={classes.inputlabel}>Document Type 2</Typography>
                  <Typography
                    className={classes.uploadfile}>
                    {doc2_type}
                  </Typography>
                </Col>
              </>
              : <></>}

            {doc3Show ?
              <>
                <Col lg="2" sm="12">
                  <Typography variant="body1" className={classes.inputlabel}>Other Document 3 </Typography>
                  <span onClick={() => navigateToExternalUrl(doc3Show, true)}>
                    {
                      doc3Show !== null ?
                        <Typography
                          title="Click to check uploaded attachement"
                          className={classes.uploadfile}>
                          ({doc3Show?.split(`${window.location.host.split('.')[0]}/`).pop().split('?')[0]} &nbsp;
                          <AttachmentIcon />)
                        </Typography> : <Typography>No Attachement Uploaded</Typography>
                    }
                  </span>
                </Col>
                <Col lg="2" sm="12">
                  <Typography variant="body1" className={classes.inputlabel}>Document Type 3</Typography>
                  <Typography
                    className={classes.uploadfile}>
                    {doc3_type}
                  </Typography>
                </Col>
              </>
              : <></>}

            {doc4Show ?
              <>
                <Col lg="2" sm="12">
                  <Typography variant="body1" className={classes.inputlabel}>Other Document 4  </Typography>
                  <span onClick={() => navigateToExternalUrl(doc4Show, true)}>
                    {
                      doc4Show !== null ?
                        <Typography
                          title="Click to check uploaded attachement"
                          className={classes.uploadfile}>
                          ({doc4Show?.split(`${window.location.host.split('.')[0]}/`).pop().split('?')[0]} &nbsp;
                          <AttachmentIcon />)
                        </Typography> : <Typography>No Attachement Uploaded</Typography>
                    }
                  </span>
                </Col>
                <Col lg="2" sm="12">
                  <Typography variant="body1" className={classes.inputlabel}>Document Type 4</Typography>
                  <Typography
                    className={classes.uploadfile}>
                    {doc4_type}
                  </Typography>
                </Col>
              </>
              : <></>}

          </Row>
          <Row className="mt-4">
            <Col lg="12" sm="12">
              <div style={{ textAlign: "end" }}>
                <Link to={ROUTES.staffManagement} className={classes.linkStyle}>
                  <CancelButtonComponent ButtonLabel="Back" />
                </Link>
              </div>
            </Col>
          </Row>
        </form>
      </CardContent>
    </Card>
  </>
  )
};

export default ViewStaffInstructor;