import axios from "axios";
import { toast } from "react-toastify";
import {
  DELETE_OF_WHITE_IP_ERROR,
  DELETE_WHITE_IP,
  LIST_OF_SCHOOL_SETTINGS,
  LIST_OF_SCHOOL_SETTINGS_ERROR,
  LIST_OF_TAX,
  LIST_OF_TAX_ERROR,
  LIST_OF_WHITE_IP,
  LIST_OF_WHITE_IP_ERROR
} from "../ActionTypes";
import {
  LIST_OF_SCHOOL_SETTINGS_API,
  ADD_SCHOOL_SETTINGS_API,
  LIST_OF_WHITE_IP_API,
  ADD_WHITE_LIST_API,
  PAGE,
  LIMIT,
  ADD_TAX_API,
  LIST_OF_TAX_API,
  UPDATE_WHITE_LIST_API,
  UPDATE_SCHOOLSETTING_API,
  DELETE_WHITE_LIST_API

} from "../constants";

const token = sessionStorage.getItem("token");
const header = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST,GET,OPTIONS,PUT,DELETE,PATCH",
    "Access-Control-Allow-Headers": "Content-Type, X-Auth-Token, Origin, Authorization"
  },
};


export const addSchoolSettings = async (userObj: any, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .post(ADD_SCHOOL_SETTINGS_API, userObj, header)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};


export const schoolSettingList = (
  token: string,
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(
        LIST_OF_SCHOOL_SETTINGS_API, header
      )
      .then((res) => {
        dispatch({
          type: LIST_OF_SCHOOL_SETTINGS,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: LIST_OF_SCHOOL_SETTINGS_ERROR,
        });
        toast.error(error.response.data.errorMsg);
      });
  };
};

export const updateSchoolSetting = async (
  settingId: string,
  userObj: any,
  token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  const body = JSON.stringify(userObj);
  return await axios
    .put(`${UPDATE_SCHOOLSETTING_API}/${settingId}`, body, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error.response.data.errorMsg);
      return error.response.data;
    });
};

type AddTaxDetails = {
  name: string;
  tax: string;
};

export const addTaxDetails = async (userObj: AddTaxDetails, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  const body = JSON.stringify(userObj);
  return await axios
    .post(ADD_TAX_API, body, header)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const taxList = (
  limit = LIMIT,
  page = PAGE,
  token: string,
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(
        `${LIST_OF_TAX_API}?limit=${limit}&page=${page}`, header
      )
      .then((res) => {
        dispatch({
          type: LIST_OF_TAX,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: LIST_OF_TAX_ERROR,
        });
        toast.error(error.response.data.errorMsg);
        return error.response;
      });
  };
};

export const whiteListIPLists = (token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(
        LIST_OF_WHITE_IP_API, header
      )
      .then((res) => {
        dispatch({
          type: LIST_OF_WHITE_IP,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: LIST_OF_WHITE_IP_ERROR,
        });
        toast.error(error.response.data.errorMsg);
        return error.response;
      });
  };
};


export const addWhiteListIP = async (userObj: any, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .post(ADD_WHITE_LIST_API, userObj, header)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const updateWhiteListIP = async (id: string | number, userObj: any, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .put(`${UPDATE_WHITE_LIST_API}/${id}`, userObj, header)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export const deleteWhiteList = async (
  id: any,
  token: string
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .delete(
        `${DELETE_WHITE_LIST_API}/${id}`,
        header
      )
      .then((res) => {
        dispatch({
          type: DELETE_WHITE_IP,
          payload: res,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: DELETE_OF_WHITE_IP_ERROR,
        });
        toast.error(error.response.data.errorMsg);
        return error.response;
      });
  };
};