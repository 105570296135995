export const ROUTES = {
  login: "/",
  forgotPassword: "/forgot-password",
  newPassword: "/new-password",
  generateOTP: "/generate-otp",
  addOTP: "/add-otp",
  v2AddOTP: "/add-otp-2",
  changePassword: "/change-password",
  changePasswordOTP: "/change-password-otp",
  changeNewPassword: "/change-new-password",
  home: "/dashboard",
  dashboard: "/dashboard",
  communication: "/communication",
  programs: "/program",
  myPlan: "/my-plan",
  assetManagement: "/asset-management",
  staffManagement: "/staff-management",
  studentContractManagement: "/student-contract-management",
  payments: "/payments",
  studentPayments: "/student-payments",
  settings: "/settings",
  profile: "/profile",
};

export const InnerPageRoutes = {
  replyToMessage: `${ROUTES.communication}/reply-to-message`,
  addNewProgram: `${ROUTES.programs}/add-new-program`,
  editProgram: `${ROUTES.programs}/edit-program`,
  composeMessage: `${ROUTES.communication}/compose-message`,
  sendDraftMessage: `${ROUTES.communication}/send-draft-message`,
  newStudentContract: `${ROUTES.studentContractManagement}/new-student-contract`,
  editStudentContract: `${ROUTES.studentContractManagement}/edit-student-contract`,
  viewStudentContract: `${ROUTES.studentContractManagement}/view-student-contract`,
  newStaffInstructor: `${ROUTES.staffManagement}/new-staff-instructor`,
  viewStaffInstructor: `${ROUTES.staffManagement}/view-staff-instructor`,
  editStaffInstructor: `${ROUTES.staffManagement}/edit-staff-instructor`,
  messageReply: `${ROUTES.communication}/message-reply`,
  programView: `${ROUTES.programs}/program-view`,
  thanksUpgrade: `${ROUTES.myPlan}/thanks-upgrade`
};
