import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Paper, Typography } from "@material-ui/core";
import VisaCardIcon from "../../svg_icons/visa.png";
import MasterCardIcon from "../../svg_icons/mastro.png";
import PayPalIcon from "../../svg_icons/paypal.png";
import LockIcon from '@mui/icons-material/Lock';
import { makeStyles } from "@material-ui/core/styles";
import CommonHeadPart from "../../common-components/CommonHeadPart";
import PlanIcon from "../../svg_icons/plan.svg";
import { ROUTES } from "../../constants/routes";
import DialogTitle from "@mui/material/DialogTitle";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import * as Actions from "../../reducer-store/actions/planAction";
import * as AuthActions from "../../reducer-store/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
// import ProgramView from "../Programs/ProgramDetailsView/ViewProgram";
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloseIcon from "@mui/icons-material/Close";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "../Common.css";
import IconButton from "@mui/material/IconButton";
import { GiUpCard } from "react-icons/gi";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Button, Col, Row } from "react-bootstrap";
import ToastMessage from "../../common-components/ToastMessage";
import FormFieldComponent from "../../common-components/FormFieldComponent";
import moment from "moment";
import PaymentForm from "./PaymentForm";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import CheckIcon from "@material-ui/icons/Check";
import Group from "../../svg_icons/truckwhite.svg";
import Group1 from "../../svg_icons/Group 1112.svg";
import Group2 from "../../svg_icons/Group 1115.svg";
import Group3 from "../../svg_icons/Group 1116.svg";
import Group4 from "../../svg_icons/Group 1117.svg";
import Group5 from "../../svg_icons/Path 314.svg";
import Az from "../../svg_icons/az.png";
import Dz from "../../svg_icons/dz.png";
import Bz from "../../svg_icons/bz.png";
import Cz from "../../svg_icons/cz.png";
import Bike from "../../svg_icons/bike.png";
import Car from "../../svg_icons/car.png";

const useStyles = makeStyles((theme) => ({
    divInsideMain: {
        boxShadow: "0 0 5px #8e8e8e",
        borderRadius: "3px",
        marginTop: "30px",
    },
    valueStyle: {
        fontFamily: "Arial",
        letterSpacing: '0.06px',
        color: '#3A3A3A',
        fontSize: '18px'
    },
    tabPanelStyle: {
        padding: "0 !important",
    },
    boxBorderCurrent: {
        boxShadow: "0px 0px 5px #8e8e8e",
        backgroundColor: "#F9F9F9",
        borderRadius: "5px",
    },
    boxBorderHistory: {
        boxShadow: "0px 0px 5px #8e8e8e",
        backgroundColor: "#DFDFDF",
        borderRadius: "5px",
    },
    divStyling: {
        padding: "15px 20px",
    },
    switchRight: {
        textAlign: "right",
    },
    rowStyling: {
        marginLeft: 20,
        marginTop: 10,
        marginBottom: 10,
    },
    colStyling: {
        marginTop: 10,
        marginBottom: 10,
    },
    saveEmailButton: {
        borderRadius: "40px !important",
        backgroundColor: "#00A651 !important",
        border: "1px solid #00A651 !important",
        color: "#ffffff !important",
        padding: "0 35px !important",
        height: "40px !important",
        boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        fontWeight: 500,
        fontSize: "16px !important",
        textTransform: 'capitalize',
    },
    saveEmailButtonDisabled: {
        borderRadius: "40px !important",
        backgroundColor: "#E5E5E5 !important",
        border: "1px solid #8E8E8E !important",
        color: "#959595 !important",
        padding: "0 35px !important",
        boxShadow: 'none',
        height: "40px !important",
        fontWeight: 500,
        fontSize: "16px !important",
        textTransform: 'capitalize',
        cursor: 'default !important',
        '&:hover': {
            boxShadow: 'none'
        },
        '&:focus': {
            boxShadow: 'none'
        },
        '&:active': {
            boxShadow: 'none'
        }
    },
    saveEmailButton1: {
        borderRadius: "40px !important",
        backgroundColor: "#feba26 !important",
        border: "1px solid #feba26 !important",
        color: "#000 !important",
        padding: "0 35px !important",
        height: "auto",
        boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        fontWeight: 500,
        fontSize: "16px !important",
        textTransform: 'capitalize',
    },
    saveEmailButtonDisabled1: {
        borderRadius: "40px !important",
        backgroundColor: "#feba26 !important",
        border: "1px solid #feba26 !important",
        color: "#000 !important",
        padding: "0 35px !important",
        boxShadow: 'none',
        height: "auto",
        fontWeight: 500,
        fontSize: "16px !important",
        textTransform: 'capitalize',
        cursor: 'default !important',
        '&:hover': {
            boxShadow: 'none'
        },
        '&:focus': {
            boxShadow: 'none'
        },
        '&:active': {
            boxShadow: 'none'
        }
    },
    iconHover: {
        display: "flex",
        flexDirection: "row",
        // marginBottom: "10px",
        alignItems: "center !important",
        '&:hover': {
            cursor: "pointer"
        },
    },
    button1234: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "40px",
        height: "40px",
    },
    popRow: {
        paddingTop: 10,
    },
    popRow1: {
        paddingTop: 20,
    },
    lockicon: {
        '& svg': {
            fontSize: "18px"
        },
    },
    saveButton: {
        borderRadius: "40px !important",
        backgroundColor: "#00A651 !important",
        border: "1px solid #00A651 !important",
        color: "#ffffff !important",
        padding: "0 35px !important",
        height: "40px !important",
        float: "right"
    },
    dialogContent: {
        textAlign: "center",
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: "center"
    },
    dialogAction: {
        justifyContent: "center !important",
    },
    icon: {
        width: '30%',
        height: 'auto',
        color: "#000",
    },
    yesButton: {
        borderRadius: "40px !important",
        backgroundColor: "#00A651 !important",
        border: "1px solid #00A651 !important",
        color: "#ffffff !important",
        padding: "0 35px !important",
        height: "40px !important",
        fontFamily: 'Arial !important',
        fontSize: '15px',
        textTransform: 'capitalize'
    },
    noButton: {
        borderRadius: "40px !important",
        backgroundColor: "#ffffff !important",
        border: "1px solid #000000 !important",
        color: "#000000 !important",
        padding: "0 35px !important",
        height: "40px !important",
        textTransform: 'capitalize',
        fontSize: '15px',
    },
    mainTitle: {
        fontSize: "44px",
        fontWeight: 700,
        color: "#000000",
    },
    subTitle: {
        fontSize: "18px",
    },
    featur: {
        textAlign: "center",
    },
    plan: {
        display: "flex",
        flexDirection: "column",
        minHeight: "640px",
    },
    addons_plan: {
        display: "flex",
        flexDirection: "column",
        minHeight: "640px",
        backgroundColor: "#00a651",
        position: "relative",
        overflow: "visible",
        "@media (max-width: 900px)": {
            marginTop: '20px'
        },
    },
    card: {
        backgroundColor: "#fedc92",
    },
    addons_card: {},
    plan_title: {
        fontSize: "24px",
        fontWeight: 700,
        color: "#000000",
    },
    addons_title: {
        paddingTop: "0px",
        fontSize: "24px",
        fontWeight: 700,
        color: "#ffffff",
    },
    img_title: {
        marginLeft: "10px",
        color: "#464646",
    },
    img_title1: {
        marginLeft: "10px",
        color: "#ffffff",
    },
    platform: {
        fontWeight: 700,
        color: "#000000",
        marginBottom: "10px",
    },
    Customplan_title: {
        fontSize: "18px",
        fontWeight: 700,
        color: "#00a651",
        textAlign: "center",
        marginTop: "20px",
    },
    // CardContent: {
    //   minHeight: "490px",
    // },
    bestplan: {
        content: "",
        display: "inline-block",
        width: "47px",
        height: "20px",
        backgroundColor: "#de9b0b",
        position: "absolute",
        top: "-10px",
        right: "10px",
        Transform: "rotate(1deg)",
        borderRadius: "8px",
        zIndex: -2,
    },
    bestplan1: {
        content: "",
        display: "inline-block",
        width: "80px",
        height: "80px",
        backgroundColor: "#feba26",
        position: "absolute",
        top: "-10px",
        right: "20px",
        borderRadius: "0px 0px 10px 10px",
    },
    offer: {
        fontSize: "14px",
        fontWeight: 600,
        color: "#ffffff",
        padding: "5px",
        textAlign: "center",
    },
    renew: {
        marginTop: '10px'
    }
}));

const BreadCrumbProgram = [
    {
        labelText: "Home",
        linkUrl: ROUTES.home,
    },
    {
        labelText: "My Plan",
        linkUrl: "",
    },
];

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;
    const [name, setname] = useState("");

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ color: "#ffffff", backgroundColor: "#404040" }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={() => onClose()}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const MyPlan: React.FC = () => {
    const classes = useStyles();
    const AuthToken = useSelector((state: any) => state.auth);
    const { user, token, planData } = AuthToken;
    const dispatch = useDispatch();
    const { ProvinceTaxList, planList } = bindActionCreators(AuthActions, dispatch);
    const { planHistoryList, SinglePlanData } = bindActionCreators(Actions, dispatch);
    const [openFirstPayment, setOpenFirstPayment] = useState(false);
    const [openPayment, setOpenPayment] = useState(false);
    const [openRenewPayment, setOpenRenewPayment] = useState(false);
    const [planHistoryData, setPlanHistoryData] = useState<any>([]);
    const [currentPlanName, setCurrentPlanName] = useState('');
    const [currentPlanPrice, setCurrentPlanPrice] = useState('');
    const [currentTotal, setCurrentTotal] = useState('');
    // const [totalAmountPrev, setTotalAmounPrev] = useState('');
    const [currentPlanStart, setCurrentPlanStart] = useState('');
    const [upgradePlanAmount, setUpgradePlanAmount] = useState('');
    const [renewPlanAmount, setRenewPlanAmount] = useState('');
    const [tax1, setTax1] = useState<any>('');
    const [tax2, setTax2] = useState<any>('');
    const [totalTax, setTotalTax] = useState<any>('');
    // const [tax1Prev, setTax1Prev] = useState<any>('');
    // const [tax2Prev, setTax2Prev] = useState<any>('');
    // const [totalTaxPrev, setTotalTaxPrev] = useState<any>('');
    const [renewPlan, setRenewPlan] = useState(false);

    useEffect(() => {
        (async () => {
            const res: any = await planList();
        })();
    }, []);

    useEffect(() => {
        (async () => {
            const response: any = await planHistoryList(token);
            const { data } = response.data;

            setPlanHistoryData(data)
            //CURRENT PLAN DETAILS
            setCurrentPlanName(user?.School?.License?.Plan?.name);
            setCurrentPlanPrice(user?.School?.License?.Plan?.price);
            setCurrentPlanStart(data && data[0]?.plan_end_date || user?.School?.created_at);
            setCurrentTotal(user?.School?.License?.Plan?.total_amount);

            setTotalTax(user?.School?.License?.Plan?.tax_amount)
            setTax1(user?.School?.License?.Plan?.tax1);
            setTax2(user?.School?.License?.Plan?.tax2);
        })();
    }, []);

    useEffect(() => {
        if (user.School.License?.expiry_timestamp >= moment().subtract(1, 'months').toISOString()) {
            setRenewPlan(true);
        }
    }, []);

    useEffect(() => {
        (async () => {
            setRenewPlanAmount(user?.School?.License.Plan?.total_amount)
            let platinum_id = "2";
            const resData: any = await SinglePlanData(platinum_id, token);
            const { data } = resData;
            let months = moment.duration(moment().diff(moment(user?.School?.License?.created_at))).asMonths();
            months = Math.ceil(months);
            if (new Date().getDate() === 1) {
                months -= 1
            }

            const res: any = await ProvinceTaxList(token, user?.School?.state);

            let remaining_credit = 0;
            if (user?.School.License?.Plan?.price)
                remaining_credit = user?.School?.License?.Plan?.price * (12 - months) / 12
            const amount = data?.data?.price;

            // const taxSlab1Amount = (amount * tax1) / 100;
            // const taxSlab2Amount = (amount * tax2) / 100;
            let newAmount: any = amount - remaining_credit;

            const taxAmount = (newAmount * res?.data[0]?.total_tax) / 100;
            let totalAmount = newAmount + taxAmount;

            setUpgradePlanAmount(totalAmount);
        })();
    }, []);

    const handleClickPayment = async () => {
        setOpenPayment(false);
        setOpenFirstPayment(true);
    };

    const handleClickOpen = () => {
        setOpenPayment(true);
    };

    const handleClickOpenRenew = () => {
        setOpenRenewPayment(true);
    };

    const handleClose = () => {
        setOpenPayment(false);
        setOpenRenewPayment(false);
    }

    const stripePromise = loadStripe(
        "pk_test_51JputsEggVol7ThpYTRlsiA06NmirAEmovr3GeCn6YTzjJmVJTmeLwFb9UU7zq0qZbUFSOfEaWyE6422yXmD05qd00kGweIaRE"
    );

    function handleCloseFirstPayment(): void {
        setOpenFirstPayment(false);
    }

    return (
        <>
            <ToastMessage />
            <Row className={classes.button1234}>
                <Col lg="6" sm="12">
                    <CommonHeadPart
                        componentText="My Plan"
                        Icon={PlanIcon}
                        buttonText=""
                        BreadCrumbs={BreadCrumbProgram}
                        urlLink="/"
                    />
                </Col>
            </Row>

            <div >
                <Paper className={classes.boxBorderCurrent}>
                    <Row className={classes.rowStyling} >
                        <Col lg='12' sm="12" className={classes.colStyling} >
                            <span className={classes.valueStyle}>Current Plan Details</span>
                        </Col>
                    </Row>
                    <Row className={classes.rowStyling} >
                        <Col lg={user?.School.License?.Plan?.id == "1" ? '3' : "4"} sm="12" className={classes.colStyling} >
                            <FormFieldComponent FormFieldLable="Plan Name" FormFieldValue={currentPlanName || "---"} />
                        </Col>
                        {/* <Col lg='3' sm="12" className={classes.colStyling} >
                            <FormFieldComponent FormFieldLable="Plan Price" FormFieldValue={`CAD ${Number(currentTotal).toFixed(2) || "---"}`} />
                        </Col> */}
                        {user?.School.License?.Plan?.id == "1" ?
                            <Col lg='3' sm="12" className={classes.colStyling} >
                                <FormFieldComponent FormFieldLable="Total Amount" FormFieldValue={`CAD ${Number(currentTotal).toFixed(2) || "---"}`} />
                            </Col> : null}{ }
                        <Col lg={user?.School.License?.Plan?.id == "1" ? '3' : '4'} sm="12" className={classes.colStyling} >
                            <FormFieldComponent FormFieldLable="Valid From" FormFieldValue={moment(currentPlanStart).format("YYYY-MM-DD") || "---"} />
                        </Col>
                        <Col lg={user?.School.License?.Plan?.id == "1" ? '3' : "4"} sm="12" className={classes.colStyling} >
                            <FormFieldComponent FormFieldLable="Expiry" FormFieldValue={moment(user.School.License?.expiry_timestamp).format("YYYY-MM-DD")} />
                        </Col>
                    </Row>
                </Paper>
            </div>

            <div>
                {planHistoryData?.length > 0 ?
                    planHistoryData?.map((data: any, index: any) => (
                        <Paper className={classes.boxBorderHistory} key={index}>
                            <Row className={classes.rowStyling} >
                                <Col lg='12' sm="12" className={classes.colStyling} >
                                    <span className={classes.valueStyle}>Plan History ({data?.history_type} Plan)</span>
                                </Col>
                            </Row>
                            <Row className={classes.rowStyling} >
                                <Col lg={user?.School.License?.Plan?.id == "1" ? '3' : "4"} sm="12" className={classes.colStyling} >
                                    <FormFieldComponent FormFieldLable="Plan Name" FormFieldValue={`${data?.PreviousPlan?.name}`} />
                                </Col>
                                {user?.School.License?.Plan?.id == "1" ?
                                    <Col lg='3' sm="12" className={classes.colStyling} >
                                        <FormFieldComponent FormFieldLable={`Total ${data?.history_type} Plan Amount`} FormFieldValue={`CAD ${Number(data?.total_amount).toFixed(2)}`} />
                                    </Col> : null}
                                {/* <Col lg='4' sm="12" className={classes.colStyling} >
                                    <FormFieldComponent FormFieldLable={`Total ${data?.history_type} Amount`} FormFieldValue={`CAD ${data?.total_amount ? Number(data?.total_amount).toFixed(2) : "---"}`} />
                                </Col> */}
                                <Col lg={user?.School.License?.Plan?.id == "1" ? '3' : "4"} sm="12" className={classes.colStyling} >
                                    <FormFieldComponent FormFieldLable="Valid From" FormFieldValue={moment(data?.plan_start_date).format("YYYY-MM-DD")} />
                                </Col>
                                <Col lg={user?.School.License?.Plan?.id == "1" ? '3' : "4"} sm="12" className={classes.colStyling} >
                                    <FormFieldComponent FormFieldLable="Expiry" FormFieldValue={moment(data?.plan_end_date).format("YYYY-MM-DD")} />
                                </Col>
                            </Row>
                        </Paper>
                    ))
                    : null}
            </div>

            <section className="section" id="pricing">
                {/* <div className="icon-effects-w3-3">
                        <img src="assets/images/bg3.png" />
                    </div> */}
                <div className="ypur_plan"></div>
                <div className="ypur_plan_2">
                    <div className="container">
                        <Row className="justify-content-md-center">
                            {user?.School.License?.Plan?.id == "1" ?
                                <Col xl="4" lg="6" md="12" sm="12" xs="12">
                                    <Card className={classes.plan}>
                                        <CardMedia className={classes.card} component="div">
                                            <CardContent>
                                                <Typography
                                                    className={classes.plan_title}
                                                    variant="h6"
                                                    component="div"
                                                >
                                                    Your Current Plan{/* {planData && planData[0]?.name} */}
                                                    <br />{" "}
                                                    {`${planData && planData[0]?.symbol} ${planData && planData[0]?.price}`}<span style={{ fontSize: '18px' }}>/year</span>
                                                </Typography>
                                            </CardContent>
                                        </CardMedia>
                                        <CardContent className="">
                                            <Typography component="div">
                                                One license for all course types.
                                            </Typography>
                                            <div className="stline"></div>
                                            <Row>
                                                <Col lg="6" sm="6" xs="6">
                                                    <img src={Az} />{" "}
                                                    <span className={classes.img_title}> AZ </span>
                                                </Col>
                                                <Col lg="6" sm="6" xs="6">
                                                    <img src={Dz} />{" "}
                                                    <span className={classes.img_title}> DZ </span>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col lg="6" sm="6" xs="6">
                                                    <img src={Bz} />{" "}
                                                    <span className={classes.img_title}> BZ </span>
                                                </Col>
                                                <Col lg="6" sm="6" xs="6">
                                                    <img src={Cz} />{" "}
                                                    <span className={classes.img_title}> CZ </span>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col lg="6" sm="6" xs="6">
                                                    <img src={Bike} />{" "}
                                                    <span className={classes.img_title}> Bike </span>
                                                </Col>
                                                <Col lg="6" sm="6" xs="6">
                                                    <img src={Car} />{" "}
                                                    <span className={classes.img_title}> Car </span>
                                                </Col>
                                            </Row>
                                            <div className="stline"></div>
                                            <Typography
                                                className={classes.platform}
                                                variant="h6"
                                                component="div"
                                            >
                                                Following school, personas come with the platform.
                                            </Typography>
                                            <Typography component="div">
                                                <CheckIcon />
                                                &nbsp;
                                                {planData && planData[0]?.staff} staff
                                            </Typography>
                                            <Typography component="div">
                                                <CheckIcon />
                                                &nbsp;
                                                {planData && planData[0]?.instructor} instructor
                                            </Typography>
                                            <Typography component="div">
                                                <CheckIcon />
                                                &nbsp;
                                                {planData && planData[0]?.admin} admin
                                            </Typography>
                                            <Typography component="div">
                                                <CheckIcon />
                                                &nbsp;1 owner
                                            </Typography>
                                            <Typography component="div">
                                                <CheckIcon />
                                                &nbsp;
                                                {planData && planData[0]?.students} students per year
                                            </Typography>
                                        </CardContent>
                                        <div className="pricing-plan">
                                            <Button
                                                type="button"
                                                variant="contained"
                                                className={renewPlan ? classes.saveEmailButtonDisabled : classes.saveEmailButton}
                                                onClick={() => handleClickOpenRenew()}
                                                disabled={renewPlan}
                                            >
                                                {"Renew Now"}
                                            </Button>
                                        </div>
                                        <span style={{ textAlign: 'center', fontSize: '13px' }} className={classes.renew}>*Renew possible from {moment(moment(user.School.License?.expiry_timestamp).subtract(1, 'months').toISOString()).format("Do MMMM YYYY")}</span>
                                    </Card>
                                </Col>
                                : null}

                            <Col xl="4" lg="6" md="12" sm="12" xs="12">
                                <Card className={classes.addons_plan}>
                                    <div className={classes.bestplan}>
                                    </div>
                                    {user?.School.License?.Plan?.id == "1" ?
                                        <div className={classes.bestplan1}>
                                            <p className={classes.offer}>BEST OFFER PLAN</p>
                                        </div> : null}
                                    <CardContent className="">
                                        <Typography
                                            className={classes.addons_title}
                                            variant="h6"
                                            component="div"
                                        >
                                            {user?.School.License?.Plan?.id == "1" ? `${planData && planData[1]?.name} Plan` : `Your Current Plan (${planData && planData[1]?.name})`}
                                            <br />{" "}
                                            {user?.School.License?.Plan?.id == "1" ? <> {`${planData && planData[1]?.symbol} ${planData && planData[1]?.price}`}<span style={{ fontSize: '18px' }}>/year</span></> : ""}
                                        </Typography>
                                        <div className="stline_1"></div>
                                        <Typography component="div" className="text-white">
                                            One license for all course types.
                                        </Typography>
                                        <div className="stline_1"></div>
                                        <Row>
                                            <Col lg="6" sm="6" xs="6">
                                                <img src={Group} />{" "}
                                                <span className={classes.img_title1}> AZ </span>
                                            </Col>
                                            <Col lg="6" sm="6" xs="6">
                                                <img src={Group5} />{" "}
                                                <span className={classes.img_title1}> DZ </span>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col lg="6" sm="6" xs="6">
                                                <img src={Group2} />{" "}
                                                <span className={classes.img_title1}> BZ </span>
                                            </Col>
                                            <Col lg="6" sm="6" xs="6">
                                                <img src={Group4} />{" "}
                                                <span className={classes.img_title1}> CZ </span>
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col lg="6" sm="6" xs="6">
                                                <img src={Group1} />{" "}
                                                <span className={classes.img_title1}> Bike </span>
                                            </Col>
                                            <Col lg="6" sm="6" xs="6">
                                                <img src={Group3} />{" "}
                                                <span className={classes.img_title1}> Car </span>
                                            </Col>
                                        </Row>
                                        <div className="stline_1"></div>
                                        <Typography
                                            className="text-white mb-2"
                                            variant="h6"
                                            component="div"
                                        >
                                            Following school, personas come with the platform.
                                        </Typography>
                                        <Typography className="text-white" component="div">
                                            <Row>
                                                <Col lg="1" sm="1">
                                                    <CheckIcon />
                                                </Col>
                                                {
                                                    planData ?
                                                        <Col lg="11" sm="11">
                                                            {`${planData[1]?.staff !== 99999
                                                                ? planData[1]?.staff
                                                                : "Unlimited"
                                                                } Staff`}
                                                        </Col>
                                                        :
                                                        <></>
                                                }
                                            </Row>
                                        </Typography>
                                        <Typography className="text-white" component="div">
                                            <Row>
                                                <Col lg="1" sm="1">
                                                    <CheckIcon />
                                                </Col>
                                                {
                                                    planData ?
                                                        <Col lg="11" sm="11">
                                                            {`${planData[1]?.instructor !== 99999
                                                                ? planData[1]?.instructor
                                                                : "Unlimited"
                                                                } Instructor`}
                                                        </Col>
                                                        :
                                                        <></>
                                                }
                                            </Row>
                                        </Typography>
                                        <Typography className="text-white" component="div">
                                            <Row>
                                                <Col lg="1" sm="1">
                                                    <CheckIcon />
                                                </Col>
                                                {
                                                    planData ?
                                                        <Col lg="11" sm="11">
                                                            {`${planData[1]?.admin !== 99999
                                                                ? planData[1]?.admin
                                                                : "Unlimited"
                                                                } Admin`}
                                                        </Col>
                                                        :
                                                        <></>
                                                }
                                            </Row>
                                        </Typography>
                                        <Typography className="text-white" component="div">
                                            <Row>
                                                <Col lg="1" sm="1">
                                                    <CheckIcon />
                                                </Col>
                                                <Col lg="11" sm="11">
                                                    1 owner
                                                </Col>
                                            </Row>
                                        </Typography>
                                        <Typography className="text-white" component="div">
                                            <Row>
                                                <Col lg="1" sm="1">
                                                    <CheckIcon />
                                                </Col>
                                                {
                                                    planData ?
                                                        <Col lg="11" sm="11">
                                                            {`${planData[1]?.students !== 99999
                                                                ? planData[1]?.students
                                                                : "Unlimited"
                                                                } Students per year`}
                                                        </Col>
                                                        :
                                                        <></>
                                                }
                                            </Row>
                                        </Typography>
                                    </CardContent>
                                    <div className="pricing-plan">
                                        {user?.School.License?.Plan?.id == "1" ?
                                            <Button
                                                name="renew"
                                                type="button"
                                                variant="contained"
                                                className={user?.School?.License?.plan_id == "2" ? classes.saveEmailButtonDisabled1 : classes.saveEmailButton1}
                                                onClick={() => handleClickOpen()}
                                                disabled={user?.School?.License?.plan_id == "2" ? true : false}
                                            >
                                                {`Upgrade Now : CAD ${Number(upgradePlanAmount).toFixed(2)}`}<br /> (Platinum Amount)
                                            </Button>
                                            :
                                            <Button
                                                type="button"
                                                variant="contained"
                                                className={renewPlan ? classes.saveEmailButtonDisabled : classes.saveEmailButton}
                                                onClick={() => handleClickOpenRenew()}
                                                disabled={renewPlan}
                                            >
                                                {"Renew Now"}
                                            </Button>
                                        }
                                    </div>
                                    <span style={{ textAlign: 'center', fontSize: '13px' }}>{user?.School.License?.Plan?.id == "1" ? "*Upgrade amount calculated on prorata basis until current plan expiry" : `*Next Renew Possible From ${moment(moment(user.School.License?.expiry_timestamp).subtract(1, 'months').toISOString()).format("Do MMMM YYYY")}`}</span>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="ypur_plan_1"></div>
            </section>

            <BootstrapDialog
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
                aria-labelledby="customized-dialog-title"
                open={openRenewPayment || openPayment}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    {openRenewPayment ? "Renew Plan" : "Plan Upgrade"}
                </BootstrapDialogTitle>

                <DialogContent dividers className={classes.dialogContent} >
                    <GiUpCard color="#FDB924" size={28} style={{ marginRight: '6px', marginBottom: '4px' }} />
                    <Typography variant="h6" component="div">
                        Please pay CAD {openRenewPayment ? Number(renewPlanAmount).toFixed(2) : Number(upgradePlanAmount).toFixed(2)} to upgrade your plan.
                    </Typography>
                </DialogContent>

                <DialogActions className={classes.dialogAction} >
                    <Button
                        type="button"
                        variant="contained"
                        className={classes.noButton}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="button"
                        variant="contained"
                        className={classes.yesButton}
                        onClick={() => handleClickPayment()}
                    >
                        Proceed
                    </Button>
                </DialogActions>
            </BootstrapDialog>

            <BootstrapDialog
                onClose={handleCloseFirstPayment}
                fullWidth
                maxWidth="sm"
                aria-labelledby="customized-dialog-title"
                open={openFirstPayment}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleCloseFirstPayment}
                >
                    Make Payment
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Row>
                        <Col lg="12" sm="12" className={classes.popRow}>
                            <Row>
                                <Col lg="12" sm="12">
                                    <Typography>
                                        <IconButton className={classes.lockicon}>
                                            <LockIcon />
                                        </IconButton>
                                        SECURE SSL ENCRYPTED PAYMENT
                                    </Typography>
                                </Col>
                            </Row>
                            <Row className={classes.popRow}>
                                <Col lg="2" sm="12" >
                                    <img src={MasterCardIcon} alt="visa" />
                                </Col>
                                <Col lg="2" sm="12">
                                    <img src={VisaCardIcon} alt="visa" />
                                </Col>
                                {/* <Col lg="2" sm="12">
                                    <img src={PayPalIcon} alt="visa" />
                                </Col> */}
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12" sm="12" className={classes.popRow}>
                            <Typography variant="subtitle1" >Payment Information (required)</Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12" sm="12" className={classes.popRow}>
                            <Typography >CREDIT CARD/DEBIT CARD</Typography>
                        </Col>
                    </Row>
                    <Elements stripe={stripePromise}>
                        <PaymentForm renew={openRenewPayment} />
                    </Elements>
                </DialogContent>
            </BootstrapDialog>

        </>
    );
};

export default MyPlan;
