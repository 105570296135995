import Drawer from "@material-ui/core/Drawer";
import MenuItemsList from "./MenuItemsList";
import clsx from "clsx";
import { makeStyles, Theme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useDrawerContext } from "../contexts/drawer-context";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    backgroundColor: "#FFFFFF",
    // background: "transparent linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
    position: "static",
    border: "none",
    margin: "0px",
  },
  closed: {
    overflowX: "hidden",
  },
  opened: {
    width: "230px",
  },
  drawerheight: {
    height: "100%",
  },
  icon: {
    justifyContent: "start",
    borderRadius: 0,
    padding: "12px 20px 12px 25px",
    display: "block !important",
    "@media (max-width: 768px)": {
      display: "none !important",
    },
  },
}));

const CustomDrawer = () => {
  const classes = useStyles();
  const { isOpened, toggleIsOpened } = useDrawerContext();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Drawer
      className={`${isOpened ? "drawer-opened" : "drawer-closed"} ${classes.drawerheight}`}
      variant={isLargeScreen ? "permanent" : "temporary"}
      open={!isLargeScreen && isOpened ? true : false}
      onClose={() => toggleIsOpened(!isOpened)}
      classes={{ paper: clsx(classes.drawer, { [classes.closed]: !isOpened, [classes.opened]: isOpened }) }}
    >
      <IconButton
        color="inherit"
        onClick={() => toggleIsOpened(!isOpened)}
        className={`${classes.icon} drawer-icon`}
      >
        {isOpened ? <ArrowBackIcon /> : <MenuIcon />}
      </IconButton>
      <MenuItemsList />
    </Drawer>
  );
};

export default CustomDrawer;
