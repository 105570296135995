import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CommonHeadPart from "../../../common-components/CommonHeadPart";
import { ROUTES } from "../../../constants/routes";
import ProgramIcon from "../../../svg_icons/program.svg";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepIconProps,
  Typography,
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import AddProgramModules from "./AddProgramModules";
import AddCourses from "./AddCourses";
import AddTopics from "./AddTopics";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { makeStyles } from "@material-ui/core/styles";

const stepsAdd = ["Add Program / Modules", "Add Course", "Add Topic"];

const stepsEdit = ["Edit Program / Modules", "Edit Course", "Edit Topic"];

const useStyles = makeStyles((theme) => ({
  iconHover: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "14px",
    alignItems: "center !important",
    '&:hover': {
      cursor: "pointer"
    }
  },
  backText: {
    font: 'normal normal normal 17px / 23px Roboto',
    letterSpacing: '0px',
    color: '#606060',
  }
}));

const AddNewProgram: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const params: any = useParams();
  const { id } = params;
  const programList = useSelector((state: any) => state.program);
  const { programs } = programList;
  const [editProgram, setEditProgram] = useState(null);
  const [steps, setSteps] = useState<any>([]);

  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("step");
  urlParams.set("redirect_status", "");
  const [activeStep, setActiveStep] = useState(myParam ? parseInt(myParam) : 1);

  const BreadCrumbProgram = [
    {
      labelText: "Home",
      linkUrl: ROUTES.dashboard,
    },
    {
      labelText: "Program",
      linkUrl: ROUTES.programs,
    },
    {
      labelText: steps === stepsEdit ? "Edit Program" : "Add New Program",
      linkUrl: "",
    },
  ];

  useEffect(() => {
    (async () => {
      if (id) {
        const editProgramData = await programs?.data?.filter((program: any) => {
          return program.id === parseInt(id);
        });
        setEditProgram(editProgramData[0]);
      }
    })();
  }, [id]);

  useEffect(() => {
    if (Object.keys(params).length === 0) {
      setSteps(stepsAdd);
    } else {
      setSteps(stepsEdit);
    }
  }, [])

  const handleBack = () => {
    history.push(ROUTES.programs);
  };

  function QontoStepIcon(props: StepIconProps) {
    const { completed } = props;

    return (
      <>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </>
    );
  }

  return (
    <>
      <CommonHeadPart
        componentText="Programs"
        Icon={ProgramIcon}
        buttonText=""
        urlLink=""
        BreadCrumbs={BreadCrumbProgram}
      />
      <div className={classes.iconHover}
        onClick={() => handleBack()}
      >
        <ArrowBackIosIcon fontSize="small" />
        <Typography className={classes.backText}>Back</Typography>
      </div>

      <Card>
        <CardContent>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label: any) => (
                <Step className="stepper-css" key={label}>
                  <StepLabel StepIconComponent={QontoStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === 1 ? (
              <AddProgramModules
                handleStep={setActiveStep}
                stepValue={activeStep}
                editProgramInfo={editProgram}
              />
            ) : activeStep === 2 ? (
              <AddCourses handleStep={setActiveStep} editProgramInfo={editProgram} stepValue={activeStep} />
            ) : activeStep === 3 ? (
              <AddTopics handleStep={setActiveStep} stepValue={activeStep} />
            ) : (
              ""
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default AddNewProgram;
