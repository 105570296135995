import axios from "axios";
import { toast } from "react-toastify";
import {
  LIST_OF_PROGRAMS,
  LIST_OF_PROGRAMS_ERROR,
  LIST_OF_MODUAL,
  LIST_OF_MODUAL_ERROR,
  LIST_OF_PROGRAM_FEE,
  LIST_OF_PROGRAM_FEE_ERROR,
} from "../ActionTypes";
import {
  ADD_PROGRAM_API,
  config,
  LIMIT,
  PAGE,
  PROGRAMS_LIST_API,
  ADD_MODUAL_API,
  MODUAL_LIST_API,
  COUNTRY_LIST,
  UPDATE_PROGRAM_API,
  UPDATE_MODUAL_API,
  ADD_COURSE_FEE_API,
  PROGRAM_FEE_LIST_API,

} from "../constants";

const token = sessionStorage.getItem("token");
const header = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST,GET,OPTIONS,PUT,DELETE,PATCH",
    "Access-Control-Allow-Headers": "Content-Type, X-Auth-Token, Origin, Authorization"
  },
};

export const ProgramList = (
  status = "0",
  page = PAGE,
  limit = LIMIT,
  token: string,
  order: string,
  search: string
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(`${PROGRAMS_LIST_API}?status=${status}&page=${page}&limit=${limit}&orderBy=${order}&search=${search}`, header)
      .then((res) => {
        dispatch({
          type: LIST_OF_PROGRAMS,
          payload: res.data,
        });
        return res.data;
      })
      .catch((error) => {
        dispatch({
          type: LIST_OF_PROGRAMS_ERROR,
        });
        toast.error(error.response.data.errorMsg);
      });
  };
};

export const addProgram = async (userObj: any, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;

  const body = JSON.stringify(userObj);
  return await axios
    .post(ADD_PROGRAM_API, body, header)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      toast.error(error.response.data.errorMsg);
    });
};

export const updateProgram = async (
  programId: string,
  userObj: object,
  token: string
) => {
  header.headers.Authorization = `Bearer ${token}`;
  const body = JSON.stringify(userObj);
  return await axios
    .put(`${UPDATE_PROGRAM_API}/${programId}`, body, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error.response.data.errorMsg);
    });
};

export const getProgramDetails = async (
  programId: string,
  token: string
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .get(`${UPDATE_PROGRAM_API}/${programId}`, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.errorMsg);
    });
};

export const addModual = async (userObj: any, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  const body = JSON.stringify(userObj);
  return await axios
    .post(ADD_MODUAL_API, body, header)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      toast.error(error.response.data.errorMsg);
    });
};

export const ModualList = (page = PAGE, programId: any, limit = LIMIT, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(`${MODUAL_LIST_API}?page=${page}&program_id=${programId}&limit=${limit}`, header)
      .then((res) => {
        dispatch({
          type: LIST_OF_MODUAL,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: LIST_OF_MODUAL_ERROR,
        });
        toast.error(error.response.data.errorMsg);
      });
  };
};

export const ModuaViewlList = (program_id: string, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(`${MODUAL_LIST_API}?program_id=${program_id}`, header)
      .then((res) => {
        dispatch({
          type: LIST_OF_MODUAL,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: LIST_OF_MODUAL_ERROR,
        });
        toast.error(error.response.data.errorMsg);
      });
  };
};

// Need to Uncomment after API Creted
export const GetCountryAndProvinceList = async () => {
  const { data }: any = await axios.get(COUNTRY_LIST);
  return data.data;
};

export const GetProvinceListByCountryId = async (id: string) => {
  const { data }: any = await axios.get(`${COUNTRY_LIST}/${id}`);
  return data.data;
};

export const updateModual = async (
  modualId: string,
  userObj: any,
  token: string
) => {
  header.headers.Authorization = `Bearer ${token}`;
  const body = JSON.stringify(userObj);
  return await axios
    .put(`${UPDATE_MODUAL_API}/${modualId}`, body, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error.response.data.errorMsg);
      return error.response.data;
    });
};

export const addCourseFee = async (
  userObj: any,
  pid: any,
  token: string,
) => {
  header.headers.Authorization = `Bearer ${token}`;
  const body = JSON.stringify(userObj);
  return await axios
    .put(`${ADD_COURSE_FEE_API}/${pid}`, body, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error.response.data.errorMsg);
      return error.response.data;
    });
};

export const ProgramFeeList = (
  pid: string,
  token: string
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(`${PROGRAM_FEE_LIST_API}/${pid}`, header)
      .then((res) => {
        dispatch({
          type: LIST_OF_PROGRAM_FEE,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: LIST_OF_PROGRAM_FEE_ERROR,
        });
        toast.error(error.response.data.errorMsg);
      });
  };
};
