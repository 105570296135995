import { ActionProps } from "../../ts";
import {
  LIST_OF_STUDENT_CONTRACT,
  LIST_OF_STUDENT_CONTRACT_ERROR,
} from "../ActionTypes";

const initialState = { studentContracts: null };

const studentContractStore = (state: any = initialState, action: ActionProps) => {
  const { type, payload } = action;
  switch (type) {
    case LIST_OF_STUDENT_CONTRACT: {
      return {
        ...state,
        studentContracts: payload,
      };
    }
    case LIST_OF_STUDENT_CONTRACT_ERROR: {
      return {
        ...state,
        studentContracts: null,
      };
    }
    default:
      return state;
  }
};

export default studentContractStore;
