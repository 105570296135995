import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Theme } from '../_theme';
const Footer = () => {
  const classes = useStyles()
  return (
    <>
      <div className="footer-content">
        <Typography>© 2023 Powered by Driving School Cloud. All rights reserved</Typography>
      </div>
    </>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    display: 'flex',
    background: '#fff',
    padding: '0.5rem 1rem',
    justifyContent: 'center',
  },
  footerTitle: {
    color: "#0b2c3d",
  },
  footerLink: {
    color: "#5a3593",
    textDecoration: "none",
  }
}))
export default Footer;