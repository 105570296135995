import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MainComponent from "../LoginPages/MainComponent";
import { useDispatch, useSelector } from "react-redux";
import { USER_LOGOUT } from "../../reducer-store/ActionTypes";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import * as authAction from "../../reducer-store/actions/authActions";

const useStyles = makeStyles((theme) => ({
    sectionTop: {
        marginTop: "150px",
        minHeight: "80vh",
        marginBottom: "100px",
    },
    loginButtonArea: {
        textAlign: "center",
        marginBottom: "40px",
        marginTop: 50,
    },
    loginButton: {
        textTransform: 'capitalize',
        fontSize: "30px",
        textAlign: "center",
        fontWeight: 600,
        backgroundColor: "#00A651 !important",
        borderRadius: "60px",
        borderColor: "#00A651",
        width: "300px",
        '&:active': {
            // backgroundColor: "#",
            // color: "#000",
            // border: "#00A651 !important",
            // boxShadow: "none !important",
        },
        "&:hover": {
            backgroundColor: "#00A651",
            borderColor: "#00A651"
        },
        "&:focus": {
            backgroundColor: "#00A651 !important",
            color: "#ffffff",
            border: "#00A651",
            boxShadow: "none !important",
        },
    },
    agreementfont: {
        textTransform: "none",
        textAlign: "center",
        marginTop: 50,
        font: 'normal normal bold 70px/92px Arial',
        color: '#030504',
    },
    details: {
        textTransform: "none",
        textAlign: "center",
        color: "#8F8F8F",
        marginTop: 30,
        font: 'normal normal bold 52px/68px Roboto',
    },
    loginfont: {
        textTransform: "none",
        textAlign: "center",
        font: 'normal normal normal 28px / 32px Arial',
        color: "#707070",
        marginTop: 30
    }
}));

const ThankCourseDetails: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const AuthToken = useSelector((state: any) => state.auth);
    const { token } = AuthToken;

    const { logoutUser } = authAction

    const loggedOutUser = async (e: any) => {
        e.preventDefault();
        await logoutUser(token);
        dispatch({ type: USER_LOGOUT });
        history.push(ROUTES.login);
    };

    return (
        <>
            <MainComponent>
                <section className={classes.sectionTop}>
                    <Container className="mt-4">
                        <Row className="stap-form">
                            <Col lg="12" sm="12" className="d-flex flex-column justify-content-center">
                                <Typography variant="h2" className={classes.agreementfont} >
                                    Thank you!
                                </Typography>
                            </Col>
                            <Col lg="12" sm="12" className="d-flex flex-column justify-content-center">
                                <Typography variant="h4" className={classes.details} >
                                    for {history.location.state ? "renewing" : "upgrading"} the plan.
                                </Typography>
                            </Col>
                            <Col lg="12" sm="12" className="d-flex flex-column justify-content-center">
                                <Typography variant="h6" className={classes.loginfont} >
                                    Please click login to continue.
                                </Typography>
                            </Col>
                            <Col lg="12" sm="12" className="d-flex flex-column justify-content-center">
                                <Row className="d-flex  justify-content-center">
                                    <Col lg="3" sm="12" className="d-flex justify-content-end">
                                        <div className={classes.loginButtonArea}>
                                            <Button type="submit" onClick={(e: any) => loggedOutUser(e)} className={classes.loginButton}>
                                                Login
                                            </Button>
                                        </div>
                                    </Col>
                                    {/* <Col lg="6" sm="12" className="d-flex justify-content-start">
                                        <div className={classes.loginButtonArea}>
                                            <Button type="submit" className={classes.loginButton}>
                                                Contact us
                                            </Button>
                                        </div>
                                    </Col> */}
                                </Row>
                            </Col>
                        </Row>
                    </Container >
                </section >
            </MainComponent >
        </>
    )
}

export default ThankCourseDetails;