import React, { useState, useEffect } from "react";
import "../Common.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { makeStyles } from "@material-ui/core/styles";
import CommonHeadPart from '../../common-components/CommonHeadPart';
import StudentContractManagementIcon from "../../svg_icons/StudentContractManagement.svg";
// import { Table, TableBody, TableHead, TableRow } from "@material-ui/core";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
// import { styled } from "@mui/material/styles";
// import FilterTabs from "../../common-components/FilterTabs";
// import { StudentContractFilterTabs } from "../../constants/tabs";
// import PaginationComponent from "../../common-components/PaginationComponent";
import { DATATABLELIMIT, LIMIT, PAGE } from "../../reducer-store/constants";
import { Visibility } from "@mui/icons-material";
import { yellow, green, red } from "@mui/material/colors";
// import { Button, Col, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { InnerPageRoutes, ROUTES } from "../../constants/routes";
import { bindActionCreators } from "redux";
import * as Actions from '../../reducer-store/actions/StudentContractAction'
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// import { MDBDataTable, MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdbreact';
import 'mdbreact/dist/css/mdb.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "../Common.css";
import MUIDataTable from "mui-datatables";
import { TextField, IconButton, Box } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { FaRegEdit } from "react-icons/fa";
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles(() => ({
    divInsideMain: {
        boxShadow: "0 0 5px #8e8e8e",
        borderRadius: "3px",
    },
    iconDiv: {
        // display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    searchBar: {
        borderColor: "black",
        "& hover": {
            borderColor: "black"
        },
        "& focused": {
            borderColor: "black"
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "black"
        }
    },
}));

const theme = createTheme({
    components: {
        MuiTableHead: {
            styleOverrides: {
                root: {
                    overflow: "hidden",
                    "& .css-1ygcj2i-MuiTableCell-root": {
                        color: "black",
                        fontFamily: "Arial",
                    },
                    "& .tss-1akey0g-MUIDataTableHeadCell-data": {
                        color: "black",
                        fontFamily: "Arial",
                    },
                    "& .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon ": {
                        color: "black",
                    },
                    "& .tss-1h9t3sl-MUIDataTableHeadCell-sortAction": {
                        display: "-webkit-box"
                    },
                    "& .tss-ylo811-MUIDataTableSelectCell-headerCell": {
                        borderBottomColor: '#707070',
                        borderBottomWidth: '1.2px',
                    },
                    "& .tss-10syd3x-MUIDataTableHeadCell-root ": {
                        borderBottomColor: '#707070',
                        borderBottomWidth: '1.2px',
                    },
                    "& .css-crz6gg-MuiTableCell-root": {
                        font: "normal normal normal 15px/17px Arial",
                        color: '#000000'
                    }
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontFamily: "Arial",
                    // fontSize: "15px",
                },
                head: {
                    backgroundColor: "#FFFFFF"
                },
                footer: {
                    backgroundColor: "#FFFFFF"
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    "&:nth-child(odd)": {
                        backgroundColor: "#DFDFDF"
                    },
                    "&:nth-child(even)": {
                        backgroundColor: "#F9F9F9"
                    },
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                colorInherit: {
                    backgroundColor: '#E53D12',
                    borderRadius: '4px',
                    color: 'white',
                    marginRight: '25px',
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    lineHeight: "1.30 !important"
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    "& .MuiTablePagination-displayedRows": {
                        marginTop: "9%"
                    },
                }
            }
        },
    }
});

const StudentContractManagement: React.FC = () => {
    // const [filter, setFilters] = useState("all");
    const [search, setSearch] = useState('');
    const [order, setOrder] = useState("desc");
    const classes = useStyles();
    const AuthToken = useSelector((state: any) => state.auth);
    const { token } = AuthToken;
    const [page, setPage] = useState(PAGE);
    const [rowsPerPage, setRowsPerPage] = useState(DATATABLELIMIT);
    const dispatch = useDispatch();
    const { StudentContractList } = bindActionCreators(Actions, dispatch);
    const contractList = useSelector((state: any) => state.studentContract);
    const { studentContracts } = contractList;
    // const [StudentContractListArr, setStudentContractListArr] = useState({
    //     columns: column,
    //     rows: []
    // });
    const [data, setData] = useState([]);
    const [searchApiData, setSearchApiData] = useState([]);
    const [filterVal, setFilterVal] = useState('');
    const [searchData, setSearchData] = useState(false);

    const BreadCrumbStudentContractManagement = [
        {
            labelText: "Home",
            linkUrl: ROUTES.home
        },
        {
            labelText: "Terms and Contracts",
            linkUrl: ROUTES.studentContractManagement
        },
    ];

    const handlefilter = (e: any) => {
        if (e.target.value == "") {
            setData(searchApiData);
        } else {
            const filterData = searchApiData.filter((items: any) =>
                items.StudentContractName.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
                || items.id.toString().includes(e.target.value.toString())
                || items.Description.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())
                || items.DateAdded.toString().includes(e.target.value.toString())
            );
            if (filterData.length > 0) {
                setData(filterData);
                setSearchData(false);
            } else {
                setData([]);
                setSearchData(true);
            }
        }
        setFilterVal(e.target.value);
    };

    useEffect(() => {
        (async () => {
            const newStudentContractListList = await StudentContractList(page, rowsPerPage, token, order, search);
            renderDataTable(newStudentContractListList);
        })();
    }, [page, search]);


    // const handleFilter = (newValue: string) => {
    //     setFilters(newValue);
    // };

    // const handleSearch = (value: string) => {
    //     setSearch(value);
    // };

    const renderDataTable = (newAssetsList: any) => {
        const rowsDataTable = newAssetsList && newAssetsList.data.data.map((row: any, index: string) => {
            return {
                id: index + 1,
                contract_type: row.contract_name !== null ? row.contract_name : "--",
                document_name: row.contract_description !== null ? row.contract_description : "--",
                date_created: moment(row.created_at).format("YYYY-MM-DD"),
                action: <>
                    <div className={classes.iconDiv}>
                        <Link className="mr-4" to={`${InnerPageRoutes.viewStudentContract}/${row.id}`}>
                            <Visibility sx={{ color: yellow[700], marginRight: '15px' }} />
                        </Link>
                        <Link to={`${InnerPageRoutes.editStudentContract}/${row.id}`}>
                            <FaRegEdit size={22} color="#00A651" />
                        </Link>
                    </div>
                </>
            };
        });
        setData(rowsDataTable);
        setSearchApiData(rowsDataTable);
    };

    const columns = [
        {
            label: 'Sr No',
            name: "id",
        },
        {
            label: 'Contract Type',
            name: "contract_type",
        },
        {
            label: 'Document Name',
            name: "document_name",
        },
        {
            label: "Date Created",
            name: "date_created",
        },
        {
            label: 'Action',
            name: "action",
            options: {
                sort: false
            }
        },
    ];

    const options = {
        search: false,
        print: false,
        viewColumns: false,
        filter: false,
        download: false,
        responsive: "vertical",
        expandableRows: false,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        selectableRows: "none",
        rowsPerPageOptions: [],
        textLabels: {
            body: {
                noMatch: searchData || data?.length == 0 && studentContracts?.data?.length == 0 ?
                    'Sorry, there is no matching data to display' :
                    <Box style={{ alignItems: 'center', margin: "0 auto", justifyContent: "center" }}>
                        <CircularProgress sx={{ color: "#707070" }} />
                    </Box >
                ,
            },
        },
        customToolbar: () => {
            return (<>
                <TextField
                    variant="standard"
                    placeholder="Search"
                    onInput={(e: any) => handlefilter(e)}
                    value={filterVal}
                    className={classes.searchBar}
                    InputProps={{
                        style: { color: "black" },
                        endAdornment: (
                            <IconButton>
                                <SearchOutlined />
                            </IconButton>
                        ),
                    }}
                />
            </>)
        },
    };

    return (
        <>
            <CommonHeadPart
                componentText="Terms and Contracts"
                Icon={StudentContractManagementIcon}
                buttonText="Add New Student Contract"
                urlLink={InnerPageRoutes.newStudentContract}
                BreadCrumbs={BreadCrumbStudentContractManagement}
            />
            <div className={classes.divInsideMain}>
                <ThemeProvider theme={theme} >
                    <MUIDataTable
                        title={"All Student Contract List"}
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </ThemeProvider>
            </div>
        </>);
};
export default StudentContractManagement;