import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, FormControlLabel, Paper, Typography } from "@material-ui/core";
import "../../Common.css";
import { ProgramDetails } from "../../../constants/tabs";
import CommonHeadPart from "../../../common-components/CommonHeadPart";
import FilterTabs from "../../../common-components/FilterTabs";
import ProgramIcon from "../../../svg_icons/program.svg";
import { InnerPageRoutes, ROUTES } from "../../../constants/routes";
import { useDispatch, useSelector } from "react-redux";
import ProgramViewDetail from "../../../common-components/ProgramViewComponents/ProgramViewComponent";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ModuleView from "../../../common-components/ProgramViewComponents/ModuleView";
import CourseView from "../../../common-components/ProgramViewComponents/CourseView";
import TopicView from "../../../common-components/ProgramViewComponents/TopicView";
import { Col, Row } from 'react-bootstrap';
import { Button } from "@mui/material";
import ToastMessage from "../../../common-components/ToastMessage";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { InputField } from "../../../common-components/FormComponent/FormFieldsComponent";
import { SaveButtonComponent } from "../../../common-components/ButtonComponent";
import Checkbox from '@mui/material/Checkbox';
import {
    Table,
    TableBody,
    TableHead,
    TableRow,
} from "@material-ui/core";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { isRequiredField } from "../../../common-components/FormComponent/FormValidator";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { PROGRAM_FEES_ADD_SUCCESSFULLY, PROGRAM_FEES_UPDATE_SUCCESSFULLY } from "../../../reducer-store/ToastMessage";
import * as Actions from "../../../reducer-store/actions/programsAction"
import { bindActionCreators } from "redux";
import { FaRegEdit } from "react-icons/fa";
import Collapse from "@mui/material/Collapse";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    divInsideMain: {
        boxShadow: "0 0 5px #8e8e8e",
        borderRadius: "3px",
    },
    boxBorder: {
        margin: "10px",
        boxShadow: "none",
        backgroundColor: "#e6e6e6",
        borderRadius: "5px",
    },
    course: {
        borderRadius: "40px !important",
        backgroundColor: "#00A651 !important",
        border: "1px solid #00A651 !important",
        color: "#ffffff !important",
        padding: "0 35px !important",
        height: "40px !important",
        fontWeight: 500,
        fontSize: "0.875rem",
        lineHeight: 1.75,
        letterSpacing: "0.02857em",
        textTransform: "none",
        minWidth: "64px",
    },
    divStyling: {
        // padding: "15px 20px",
    },
    switchRight: {
        textAlign: "end",
        display: 'flex',
        justifyContent: 'end'
    },
    saveEmailButton: {
        borderRadius: "40px !important",
        backgroundColor: "#00A651 !important",
        border: "1px solid #00A651 !important",
        color: "#ffffff !important",
        padding: "0 35px !important",
        height: "40px !important",
        boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        fontWeight: 500,
        fontSize: "16px !important",
        textTransform: 'capitalize',
    },
    saveEmailButtonDisabled: {
        borderRadius: "40px !important",
        backgroundColor: "#FFFFFF !important",
        border: "1px solid #000000 !important",
        color: "#D0D0D0 !important",
        padding: "0 35px !important",
        height: "40px !important",
        boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        fontWeight: 500,
        fontSize: "16px !important",
        textTransform: 'capitalize',
    },
    saveEmailButton1: {
        borderRadius: "40px !important",
        backgroundColor: "#ffffff !important",
        border: "1px solid #000000 !important",
        color: "#000000 !important",
        padding: "0 35px !important",
        height: "40px !important",
        textTransform: 'capitalize',
        fontSize: "0.875rem",
    },
    Right: {
        marginTop: 7
    },
    iconHover: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center !important",
        '&:hover': {
            cursor: "pointer"
        }
    },
    backText: {
        font: 'normal normal normal 17px / 23px Roboto',
        letterSpacing: '0px',
        color: '#606060',
    },

    boxBorder1: {
        textAlign: "left",
        margin: "15px 10px",
        boxShadow: "none",
        backgroundColor: "none",
    },
    button1234: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "20px",
        height: "40px",
    },
    courseTableHead: {
        borderBottomWidth: "2px",
        borderBottomColor: "#8e8e8e"
    }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(2),
    },
}));
export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} style={{ color: '#FFFFFF', backgroundColor: '#404040' }} {...other}>
            {children}
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
    );
};

const ProgramView: React.FC<any> = (props) => {
    const classes = useStyles();
    const params: any = useParams();
    const { id } = params;
    const [filter, setFilters] = useState("Program Details");
    const [isSubmit, setIsSubmit] = useState(false);
    const [isValidValues, setIsValidValues] = useState(true);
    const [search, setSearch] = useState('');
    const [buttonLabel, setButtonLabel] = useState("Modules");
    const AuthToken = useSelector((state: any) => state.auth);
    const { token } = AuthToken;
    const { addCourseFee } = Actions;
    const dispatch = useDispatch();
    const { ProgramFeeList } = bindActionCreators(Actions, dispatch);
    const { updateProgram } = Actions;
    const programFeelist = useSelector((state: any) => state.program);
    const { programFee } = programFeelist;
    const [open, setOpen] = useState(false);
    const [expand, setExpand] = useState(false);
    const [edit, setEdit] = useState(false);
    const [fees, setFees] = useState("");
    const [partCheck, setPartCheck] = useState(false);
    const [partNumber, setPartNumber] = useState("");
    const [premiumCheck, setPremiumCheck] = useState(false);
    const [premiumAmount, setPremiumAmount] = useState("");
    const [partPaymentArr, setPartPaymentArr] = useState<any>([]);
    const [fullPayement, setFullPayment] = useState("");
    const [cashCheck, setCashCheck] = useState(false);
    const history = useHistory();

    const BreadCrumbProgram = [
        {
            labelText: "Home",
            linkUrl: ROUTES.home,
        },
        {
            labelText: "Program",
            linkUrl: ROUTES.programs,
        },
        {
            labelText: `${props.location.state}`,
            linkUrl: "",
        },
    ];

    useEffect(() => {
        if (history.location.key == "setFee") {
            setFilters("Course Fee")
        }
    }, [])

    useEffect(() => {
        (async () => {
            const feeList: any = await ProgramFeeList(id, token);
            const { data } = feeList;
            setPremiumAmount(data?.data?.premium_amount)
            setFullPayment(data?.data?.total_fees);

            let total_fees = feeList?.data?.data?.total_fees;
            let parts_number = feeList?.data?.data?.parts_payment_number;
            let i;
            let array = []

            for (i = 0; i < parts_number; i++) {
                array.push(total_fees / parts_number);
                setPartPaymentArr(array);
            }
        })();
    }, [open]);

    const handleBack = () => {
        history.push(ROUTES.programs);
    };

    const handlePartCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPartCheck(event.target.checked);
        setPremiumCheck(event.target.checked);
    };

    const handleCashCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCashCheck(event.target.checked);
    };

    const handleFilter = (newValue: string) => {
        setFilters(newValue);
    };
    const handleSearch = (value: string) => {
        setSearch(value);
    };

    const handleRedirection = (value: string) => {
        setButtonLabel(value)
    }

    const openCourseModal = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setIsSubmit(false);
        resetData();
    };

    const resetData = () => {
        setFees("");
        setPartCheck(false);
        setPartNumber("");
        setPremiumCheck(false);
        setPremiumAmount("");
        setCashCheck(false);
    };

    const handleEdit = () => {
        setEdit(true);
        if (programFee?.data?.parts_payment_number) {
            setPartCheck(true);
            setPremiumCheck(true);
        }
        setFees(programFee?.data?.total_fees);
        setPartNumber(programFee?.data?.parts_payment_number);
        setPremiumAmount(programFee?.data?.premium_amount);
        setOpen(true);
    }

    const SubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmit(true);
        if (
            isRequiredField(fees)
        ) {
            setIsSubmit(false);
            let useObj = {
                total_fees: fees,
                parts_payment_allowed: partCheck,
                parts_payment_number: partNumber ? partNumber : null,
                parts_payment_premium: premiumCheck,
                premium_amount: premiumAmount ? premiumAmount : null,
                // tax: tax,
                cash_allowed: cashCheck,
                status: "1"
            };
            let resData: any = await addCourseFee(useObj, id, token);
            if (!resData.error) {
                toast.success(resData?.msg);
                setTimeout(() => {
                    resetData();
                    setOpen(false);
                    // return history.push(ROUTES.programs);
                }, 2000)
                return;
            };
        }
        setIsValidValues(false);
    };

    return (
        <>
            <ToastMessage />
            <Row>
                <Col lg="6" sm="12">
                    <CommonHeadPart
                        componentText="Programs"
                        Icon={ProgramIcon}
                        buttonText=""
                        BreadCrumbs={BreadCrumbProgram}
                        urlLink="/"
                    />
                </Col>
            </Row>
            <Row className={classes.button1234}>
                <Col lg="6" sm="12">
                    <div className={classes.iconHover}
                        onClick={() => handleBack()}
                    >
                        <ArrowBackIosIcon fontSize="small" />
                        <Typography className={classes.backText}>Back</Typography>
                    </div>
                </Col>
                <Col lg="6" sm="12" className={classes.switchRight}>
                    {
                        filter === "Course Fee" ?
                            <Button
                                type="button"
                                variant="contained"
                                className={programFee?.data?.total_fees ? classes.saveEmailButtonDisabled : classes.saveEmailButton}
                                onClick={() => openCourseModal()}
                                disabled={programFee?.data.total_fees ? true : false}
                            >
                                {"Add Course Fees"}
                            </Button> : ""
                    }
                </Col>
            </Row>


            <Card className={classes.divInsideMain}>
                <CardContent>
                    <Card className={classes.boxBorder}>
                        <CardContent>
                            <Row>
                                <Col>
                                    <FilterTabs
                                        SelectedFilter={filter}
                                        Filters={ProgramDetails}
                                        HandleChange={handleFilter}
                                        isFilter={false}
                                        HandleSearch={handleSearch}
                                        isSearch={false}
                                    />
                                </Col>

                            </Row>
                        </CardContent>
                    </Card>
                    <div className={classes.boxBorder1}>
                        {filter === "Program Details" ? (<>
                            <ProgramViewDetail />
                            {buttonLabel === "Modules" ? (<>
                                <ModuleView >
                                    <Row className="mt-4">
                                        <Col lg="12" sm="12">
                                            <div className="stepperButton">
                                                <Button type="submit" className={classes.course} variant="contained"
                                                    onClick={() => handleRedirection("Course")}
                                                >
                                                    Course
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </ModuleView>
                            </>) :
                                buttonLabel === "Course" ? (<>

                                    <CourseView />
                                    <Row className="mt-4">
                                        <Col lg="12" sm="12">
                                            <div className="stepperButton">
                                                <Button
                                                    type="submit"
                                                    className={classes.course}
                                                    variant="contained"
                                                    onClick={() => handleRedirection("Topic")}
                                                >
                                                    Topic
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row></>)
                                    :
                                    buttonLabel === "Topic" ? (<>
                                        <TopicView />
                                    </>) : null}
                        </>) : <>
                            <Paper >
                                <Table style={{ backgroundColor: "#e6e6e6" }}>
                                    <TableHead>
                                        <TableRow className={classes.courseTableHead}>
                                            <StyledTableCell component="th" align="left">
                                                &nbsp;
                                            </StyledTableCell>
                                            <StyledTableCell component="th" align="left">
                                                Total Fee Amount
                                            </StyledTableCell>
                                            <StyledTableCell component="th" align="left">
                                                Number of Part Payments
                                            </StyledTableCell>
                                            <StyledTableCell component="th" align="left">
                                                Premium Amount
                                            </StyledTableCell>
                                            {programFee?.data?.total_fees ?
                                                <StyledTableCell component="th" align="left">
                                                    Edit
                                                </StyledTableCell>
                                                : null}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {!programFee ?
                                            (<StyledTableRow>
                                                <StyledTableCell align="center" colSpan={8}>
                                                    No Data Found
                                                </StyledTableCell>
                                            </StyledTableRow>)
                                            :
                                            (<><TableRow>
                                                <StyledTableCell component="td" align="left">
                                                    {programFee?.data?.total_fees && partPaymentArr?.length > 0 ?
                                                        <IconButton
                                                            aria-label="expand row"
                                                            size="small"
                                                            onClick={() => {
                                                                setExpand(!expand);
                                                            }}
                                                        >
                                                            {expand ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                        </IconButton>
                                                        : null}
                                                </StyledTableCell>
                                                <StyledTableCell component="td" align="left">
                                                    {programFee?.data?.currency}{" "}
                                                    {programFee?.data?.total_fees ?
                                                        `CAD ${Number(programFee?.data?.total_fees).toFixed(2)}`
                                                        : "--"}
                                                </StyledTableCell>
                                                <StyledTableCell component="td" align="left">
                                                    {programFee?.data?.parts_payment_number !== null ?
                                                        programFee?.data?.parts_payment_number
                                                        : "--"}
                                                </StyledTableCell>
                                                <StyledTableCell component="td" align="left">
                                                    {programFee?.data?.currency}{" "}
                                                    {programFee?.data?.premium_amount !== null ?
                                                        `CAD ${Number(programFee?.data?.premium_amount).toFixed(2)}`
                                                        : "--"}
                                                </StyledTableCell>
                                                {programFee?.data?.total_fees ?
                                                    <StyledTableCell component="td" align="left">
                                                        <FaRegEdit size={22} color="#000000" onClick={() => handleEdit()} />
                                                    </StyledTableCell> : null}
                                            </TableRow>
                                                <TableRow>
                                                    <TableCell colSpan={8}>
                                                        <Collapse hidden={partPaymentArr?.length > 0 ? false : true} in={expand} timeout="auto" unmountOnExit>
                                                            <Box className={classes.boxBorder1}>
                                                                <Typography variant="h6" gutterBottom component="div">
                                                                    <b>Parts of Payment :</b>
                                                                </Typography>
                                                                {partPaymentArr && partPaymentArr?.map((data: any, index: any) => {
                                                                    return (
                                                                        <div>
                                                                            <Typography
                                                                                key={index}
                                                                                variant="subtitle1"
                                                                                gutterBottom
                                                                                component="div"
                                                                            >
                                                                                Part {index + 1}: CAD {data}  {index == 0 ? `+ CAD ${premiumAmount}` : ""}
                                                                            </Typography>
                                                                        </div>)
                                                                })}
                                                                <Typography gutterBottom component="div" variant="subtitle1">
                                                                    <b>Total Part Payment :</b> CAD {fullPayement + premiumAmount}
                                                                </Typography>
                                                                <br />

                                                                <Typography variant="h6" gutterBottom component="div">
                                                                    <b>Full Payment :</b> CAD {fullPayement}
                                                                </Typography>
                                                            </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                            )}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </>}
                    </div>
                </CardContent>
            </Card>

            <BootstrapDialog
                onClose={handleClose}
                fullWidth
                maxWidth="xs"
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    Course Fee
                </BootstrapDialogTitle>
                <form onSubmit={SubmitHandler}>
                    <DialogContent dividers>
                        <Row>
                            <Col lg="12" sm="12">
                                <InputField
                                    type="text"
                                    lable="Total Fee Amount"
                                    name="Fees"
                                    rows={1}
                                    multiline={false}
                                    placeHolder="Enter Total fee amount"
                                    className="form-textfield-styles"
                                    value={fees}
                                    handleChange={(e) => setFees(e.target.value)}
                                    isValidValue={isValidValues}
                                    isSubmit={isSubmit}
                                    requiredMessage="Total Fee Amount field is required"
                                />
                            </Col>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={partCheck}
                                        onChange={handlePartCheck}
                                        color="success"
                                        size="small"
                                    />}
                                label="Part Payment Allowed"
                            />
                            <Col lg="12" sm="12">
                                {
                                    partCheck ?
                                        <InputField
                                            type="text"
                                            lable="Number of Part Payments"
                                            name="PartNumber"
                                            rows={1}
                                            multiline={false}
                                            placeHolder="Enter Number of Part Payments"
                                            className="form-textfield-styles"
                                            value={partNumber}
                                            handleChange={(e) => setPartNumber(e.target.value)}
                                            isValidValue={isValidValues}
                                            isSubmit={isSubmit}
                                            requiredMessage="Number of Part Payments field is required"
                                        /> : ""}
                            </Col>
                            {/* <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={premiumCheck}
                                            onChange={handlepremiumCheck}
                                            color="success"
                                            size="small"
                                        />}
                                    label="Charge extra premium for part payment"
                                /> */}
                            <Col lg="12" sm="12">
                                {
                                    premiumCheck ?
                                        <InputField
                                            type="text"
                                            lable="Premium for Installment"
                                            name="PremiumAmount"
                                            rows={1}
                                            multiline={false}
                                            placeHolder="Enter Premium Amount"
                                            className="form-textfield-styles"
                                            value={premiumAmount}
                                            handleChange={(e) => setPremiumAmount(e.target.value)}
                                            isValidValue={isValidValues}
                                            isSubmit={isSubmit}
                                            requiredMessage="Premium Amount field is required"
                                        /> : ""
                                }
                            </Col>
                            {/* <Col lg="12" sm="12">
                                    <InputField
                                        type="text"
                                        lable="Tax (%)"
                                        name="tax"
                                        rows={1}
                                        multiline={false}
                                        placeHolder="Enter Tax"
                                        className="form-textfield-styles"
                                        value={tax}
                                        handleChange={(e) => setTax(e.target.value)}
                                        isValidValue={isValidValues}
                                        isSubmit={isSubmit}
                                        requiredMessage="Tax field is required"
                                    />
                                </Col> */}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={cashCheck}
                                        onChange={handleCashCheck}
                                        color="success"
                                        size="small"
                                    />}
                                label="Cash Allowed"
                            />
                        </Row>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            type="button"
                            variant="contained"
                            onClick={handleClose}
                            className={classes.saveEmailButton1}
                        >
                            Cancel
                        </Button>
                        <SaveButtonComponent ButtonType="submit" ButtonLabel={edit ? "Update" : "Save"} />
                    </DialogActions>
                </form>
            </BootstrapDialog>
        </>
    );
};

export default ProgramView;
