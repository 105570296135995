import { ActionProps } from "../../ts";
import { LIST_OF_ASSETS_MANAGEMENT, LIST_OF_ASSETS_MANAGEMENT_ERROR } from "../ActionTypes";

const initialState = { assets: null };

const assetsStore = (state: any = initialState, action: ActionProps) => {
    const { type, payload } = action;
    switch (type) {
        case LIST_OF_ASSETS_MANAGEMENT: {
            return {
                ...state,
                assets: payload,
            };
        }
        case LIST_OF_ASSETS_MANAGEMENT_ERROR: {
            return {
                ...state,
                assets: null,
            };
        }
        default:
            return state;
    }
};

export default assetsStore;
