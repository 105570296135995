import doc from "../../src/svg_icons/doc.png";
import txtIcon from "../../src/svg_icons/txtIcon.png";
import excelIcon from "../../src/svg_icons/excelIcon.png";
import pptIcon from "../../src/svg_icons/pptIcon.png";
import pdfIcon from "../../src/svg_icons/pdfIcon.png";

export const API_END_POINT = process.env.REACT_APP_BACK_END_API;
export const config = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};

export const RELOAD_NOTIFICATION = 5000;

export const MESSAGE_LENGTH = 25;

export const LIMIT = 10;
export const PAGE = 1;
export const IMPORTANT = 4;
export const SENT = 2;
export const DELETED = 5;
export const HOURSLIMIT = 2;
export const MARKSLIMIT = 3;
export const PHONELIMIT = 10;
export const LARGELIMIT = 100;
export const DATATABLELIMIT = 10000;
export const CONTACTNO = 13;
export const MESSAGES_REFRESH_INTERVAL = 30000;

export const sales_color = "#cc0505";
export const support_color = "#008000";
export const account_color = "#fdbe36";
export const technical_color = "#342fc8";
export const other_color = "#e621c5";

export const SALES = <span style={{ color: "#cc0505" }}>Sales</span>;
export const SUPPORT = <span style={{ color: "#008000" }}>Support</span>;
export const ACCOUNT = <span style={{ color: "#fdbe36" }}>Account</span>;
export const TECHNICAL = <span style={{ color: "#342fc8" }}>Technical</span>;
export const OTHER = <span style={{ color: "#e621c5" }}>Other</span>;

/*
  Admin APIs = { LOGIN, SEND OTP, RESEND OTP, VERIFY OTP}
*/
export const ADMIN_LOGIN_API = API_END_POINT + "/auth/user/school/signin";
export const ADMIN_SEND_LOGIN_OTP_API =
  API_END_POINT + "/auth/user/signin/otp/email/send";
export const ADMIN_RESEND_LOGIN_OTP_API =
  API_END_POINT + "/auth/user/signin/otp/email/resend";
export const ADMIN_VERIFY_LOGIN_OTP_API =
  API_END_POINT + "/auth/user/signin/otp/email/verify";

export const ADMIN_SEND_LOGIN_OTP_API_PHONE =
  API_END_POINT + "/auth/user/signin/otp/phone/send";
export const ADMIN_RESEND_LOGIN_OTP_API_PHONE =
  API_END_POINT + "/auth/user/signin/otp/phone/resend";
export const ADMIN_VERIFY_LOGIN_OTP_API_PHONE =
  API_END_POINT + "/auth/user/signin/otp/phone/verify";

export const RESET_PASSWORD_OTP_ON_EMAIL =
  API_END_POINT + "/auth/reset/otp/email";

export const RESET_PASSWORD_OTP_VERIFY =
  API_END_POINT + "/auth/reset/verify/email";

export const RESET_PASSWORD_API =
  API_END_POINT + "/auth/reset/password";

export const SCHOOL_DETAILS_API = API_END_POINT + "/user/me";
export const USER_DETAILS_BY_ID = API_END_POINT + "/user"

export const SCHOOL_LIST_API = API_END_POINT + "/school";
export const ADD_SCHOOL_API = API_END_POINT + "/school";
export const SINGLE_SCHOOL_DATA = API_END_POINT + "/school";
export const SINGLE_SCHOOL_COMMUNICATION_DATA =
  API_END_POINT + "/message/school";

export const PROVINCE_TAX = API_END_POINT + "/provinceTax"

export const SCHOOLPAYMENT_LIST_API = API_END_POINT + "/payment";
export const ADD_SCHOOLPAYMENTREFUND_API = API_END_POINT + "/payment/refund";

export const PLAN_LIST_API = API_END_POINT + "/plan";
export const SINGLE_PLAN_API = API_END_POINT + "/plan";
export const UPDATE_PLAN_API = API_END_POINT + "/plan";

export const PROGRAMS_LIST_API = API_END_POINT + "/program/school";
export const ADD_PROGRAM_API = API_END_POINT + "/program";
export const UPDATE_PROGRAM_API = API_END_POINT + "/program";

export const ADD_COURSE_FEE_API = API_END_POINT + "/program";
export const PROGRAM_FEE_LIST_API = API_END_POINT + "/program";

export const MODUAL_LIST_API = API_END_POINT + "/module";
export const ADD_MODUAL_API = API_END_POINT + "/module";
export const UPDATE_MODUAL_API = API_END_POINT + "/module";

export const ADD_COURSE_API = API_END_POINT + "/course";
export const COURSE_LIST_API = API_END_POINT + "/course";
export const UPDATE_COURSE_API = API_END_POINT + "/course";


export const ADD_TOPIC_API = API_END_POINT + "/topic";
export const TOPIC_LIST_API = API_END_POINT + "/topic";
export const UPDATE_TOPIC_API = API_END_POINT + "/topic";


export const COUNTRY_LIST = API_END_POINT + "/country";

export const COMMUNICATION_LIST_API = API_END_POINT + "/message/school";
export const SINGLE_COMMUNICATION_API = API_END_POINT + "/message/";
export const SEND_MESSAGE = API_END_POINT + "/message/send";
export const SAVE_MESSAGE = API_END_POINT + "/message/save";
export const REPLY_MESSAGE = API_END_POINT + "/message/reply";
export const IMPORTANT_MESSAGE = API_END_POINT + "/message/important";
export const DELETE_MESSAGE = API_END_POINT + "/message/delete";
export const DELETE_MESSAGE_PERMENANT = API_END_POINT + "/message";
export const READ_MESSAGE = API_END_POINT + "/message/read";

export const LICENSE_LIST_API = API_END_POINT + "/license";
export const ADD_LICENSE_API = API_END_POINT + "/license";
export const DELETE_LICENSE_API = API_END_POINT + "/license";
export const SINGLE_LICENSE_API = API_END_POINT + "/license";
export const UPDATE_LICENSE_API = API_END_POINT + "/license";

export const INVOICE_LIST_API = API_END_POINT + "/invoice";
export const ADD_INVOICE_API = API_END_POINT + "/invoice";
export const SAVE_INVOICE = API_END_POINT + "/invoice/save";
export const SEND_INVOICE = API_END_POINT + "/invoice/send";
export const INVOICE_DELETE_API = API_END_POINT + "/invoice";
export const SINGLE_INVOICE_API = API_END_POINT + "/invoice";

export const ASSETS_MANAGEMENT_LIST_API = API_END_POINT + "/schoolAssets";
export const ADD_ASSETS_MANAGEMENT_API = API_END_POINT + "/schoolAssets";

export const DASHBOARD_API = API_END_POINT + "/schoolAdminDashboard";

export const STUDENT_CONTRACT_LIST_API = API_END_POINT + "/contract";
export const ADD_STUDENT_CONTRACT_API = API_END_POINT + "/contract";
export const UPDATE_STUDENT_CONTRACT_API = API_END_POINT + "/contract";
export const SINGLE_STUDENT_CONTRACT_LIST_API = API_END_POINT + "/contract";

export const PAYMNET_LIST_API = API_END_POINT + "/payment/school";
export const PAYMENT_STUDENT_LIST_API = API_END_POINT + "/payment/students/all";
export const PAYMENT_KEY = API_END_POINT + "/gateway";

export const PAYMENT_INVOICE_URL = API_END_POINT + "/payment/invoice/student";

export const ADD_SCHOOL_SETTINGS_API = API_END_POINT + "/schoolSettings";
export const LIST_OF_SCHOOL_SETTINGS_API = API_END_POINT + "/schoolSettings";
export const LIST_OF_WHITE_IP_API = API_END_POINT + "/ipWhitelist";
export const ADD_WHITE_LIST_API = API_END_POINT + "/ipWhitelist";
export const UPDATE_WHITE_LIST_API = API_END_POINT + "/ipWhitelist";
export const UPDATE_SCHOOLSETTING_API = API_END_POINT + "/schoolSettings";
export const DELETE_WHITE_LIST_API = API_END_POINT + "/ipWhitelist";

export const ADD_TAX_API = API_END_POINT + "/schoolTax";
export const LIST_OF_TAX_API = API_END_POINT + "/schoolTax";

export const STAFF_MANAGEMENT_LIST_API = API_END_POINT + "/schoolStaff";
export const ADD_STAFF_MANAGEMENT_API = API_END_POINT + "/schoolStaff";
export const UPDATE_STAFF_MANAGEMENT_API = API_END_POINT + "/schoolStaff";
export const SINGLE_STAFF_MANAGEMENT_API = API_END_POINT + "/schoolStaff";

export const NOTIFICATION_LIST = API_END_POINT + "/notifications/school";
export const NOTIFICATION_UPDATE = API_END_POINT + "/notifications";
export const SIGNED_URL = API_END_POINT + "/file/signed-url";

export const GET_PROVINCE_LIST = API_END_POINT + "/country";

export const GET_PLAN_HISTORY = API_END_POINT + "/plan/history/school";
export const PLAN_UPGRADE_PAYMENT = API_END_POINT + "/payment/plan-upgrade";
export const PLAN_UPGRADE = API_END_POINT + "/plan/upgrade";
export const PLAN_RENEW_PAYMENT = API_END_POINT + "/payment/plan-renew";
export const PLAN_RENEW = API_END_POINT + "/plan/renew";

export const LOGOUT_API = API_END_POINT + "/auth/logout"

export const manageIconType = (value: string) => {
  switch (value) {
    case "application/pdf":
      return pdfIcon;
    case "application/msword":
      return doc;
    case "text/plain":
      return txtIcon;
    case "application/vnd.ms-excel":
      return excelIcon;
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return excelIcon;
    case "application/vnd.ms-powerpoint":
      return pptIcon;
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return pptIcon;
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return doc;
  }
};

export const weekDaysArr = (value: string) => {
  switch (value) {
    case "1":
      return "Monday";
    case "2":
      return "Tuesday";
    case "3":
      return "Wednesday";
    case "4":
      return "Thursday";
    case "5":
      return "Friday";
    case "6":
      return "Saturday";
    case "0":
      return "Sunday";
  }
}

export const contracts = [
  { id: "", name: "Select Type" },
  { id: "general", name: "General T&C's" },
  { id: "privacy", name: "Privacy Policy" },
  { id: "data", name: "Data Protection" },
  { id: "payment", name: "Payment & Refund Policy" },
  { id: "sexual", name: "Sexual Voilence Policy" },
  { id: "expulsion", name: "Expulsion Policy" },
  { id: "compliant", name: "Compliant Procedure" },
  { id: "statement", name: "Statement of Students Right" },
  { id: "grading", name: "Grading Policy" },
  { id: "midpoint", name: "Pre-Midpoint Evalution Policy" },
]