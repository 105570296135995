import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CommonHeadPart from "../../common-components/CommonHeadPart";
import { InnerPageRoutes, ROUTES } from "../../constants/routes";
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Button, Paper, Typography } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Image } from "react-bootstrap";
import CommunicationIcon from "../../svg_icons/communication.svg";
import ToastMessage from "../../common-components/ToastMessage";
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import * as Actions from "../../reducer-store/actions/communicationAction";
import * as authActions from "../../reducer-store/actions/authActions";
import { Avatar, CircularProgress } from "@mui/material";
import { fileSignedURL } from "../../reducer-store/actions/authActions";
import ImageComponent from "../../common-components/ImageComponent";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));

const useStyles = makeStyles(() => ({
	saveEmailButton: {
		borderRadius: "40px !important",
		backgroundColor: "#00A651 !important",
		border: "1px solid #00A651 !important",
		color: "#ffffff !important",
		padding: "0 35px !important",
		height: "40px !important",
		textTransform: 'capitalize'
	},
	messageArea: {
		// height: '50vh',
	},
	avtar: {
		width: "40px",
		height: "40px",
		color: "#707070",
		backgroundColor: "#BEBEBE",
		borderColor: "#707070",
		borderRadius: 100,
		textAlign: "end",
		marginLeft: "10px"
	},
	userName1: {
		marginTop: "0",
		paddingLeft: "10px",
		textAlign: "start"
	},
	listUser: {
		backgroundColor: "#FFD985",
		color: "#292929",
		borderRadius: 10
	},
	listuser1: {
		backgroundColor: "#84e0b1",
		color: "",
		borderRadius: 10
	},
	divInsideMain: {
		boxShadow: "0 0 5px #8e8e8e",
		borderRadius: "3px",
	},
	itemMsg: {
		marginLeft: "10px !important",
		background: "transparent !important",
		boxShadow: "none !important"
	},
	itemTimeZone: {
		background: "transparent !important",
		boxShadow: "none !important",
	},
	headingChat: {
		display: "flex",
		background: "#000000b3",
		color: "#fff",
		alignItems: "center",
		padding: "15px 5px",
		borderRadius: "5px 5px 0 0"
	},
	boxRowClass: {
		boxShadow: "0 0 1px #000",
		borderRadius: "0 0 5px 5px",
		margin: "0",
		padding: "15px"
	},
	demoColor: {
		backgroundColor: "#84e0b1",
		borderRadius: "5px",
		padding: "10px",
		width: '100%'
	},
	demoColor2: {
		backgroundColor: "#FFD985",
		borderRadius: "5px",
		padding: "10px",
		width: '100%'
	},
	demoLeft: {
		textAlign: "left",
		marginTop: "5px"
	},
	demoRight: {
		textAlign: "right",
		marginTop: "5px"
	},
}));

const BreadCrumbCommunication = [
	{
		labelText: "Home",
		linkUrl: ROUTES.home,
	},
	{
		labelText: "Communication",
		linkUrl: ROUTES.communication,
	},
	{
		labelText: "Reply",
		linkUrl: "",
	},
];

const ReplyMessage: React.FC<any> = (props) => {
	const classes = useStyles();
	const { id }: any = useParams();
	const AuthToken = useSelector((state: any) => state.auth);
	const { user, token, userUpdated } = AuthToken;
	const dispatch = useDispatch();
	const { CommunicationList } = bindActionCreators(Actions, dispatch);
	const { GetReplyMessaage, } = Actions;
	const { SingleUserData } = bindActionCreators(authActions, dispatch)
	const [messageData, setMessageData] = useState<any>(null);
	const [toProfile, setToProfile] = useState("");
	const [fromProfile, setFromProfile] = useState("");
	// const [attachmentFile, setAttachmentFile] = useState<any>({});

	useEffect(() => {
		(async () => {
			const lastReplyMessage: any = await GetReplyMessaage(id, token);
			const { data } = lastReplyMessage;
			setMessageData(data);
			// const resData: any = await SingleUserData(data?.sent_to, token);
		})();
	}, []);

	useEffect(() => {
		(async () => {
			if (userUpdated) {
				if (userUpdated.data?.profile_url) {
					const newData = await fileSignedURL(userUpdated && userUpdated.data?.profile_url, token);
					setToProfile(newData?.url);
				}
			} else {
				if (user?.profile_url) {
					const newData = await fileSignedURL(user && user?.profile_url, token);
					setToProfile(newData?.url);
				}
			}
			// const newData: { url: string, msg: string, error: boolean } = await fileSignedURL(messageData?.School?.logo, token);
			// const { url } = newData;
			// setProfileData(url);
		})();
	}, [userUpdated, user]);

	if (!messageData) {
		return (
			<Box style={{ position: "absolute", margin: "0 auto", top: "50%", left: "50%", justifyContent: "center" }} >
				<CircularProgress sx={{ color: "#707070" }} />
			</Box>)
	}

	return (
		<>
			<ToastMessage />
			<CommonHeadPart
				componentText="Communication"
				Icon={CommunicationIcon}
				buttonText=""
				urlLink=""
				BreadCrumbs={BreadCrumbCommunication}
			/>
			<div className={classes.headingChat}>
				<Link to={ROUTES.communication}>
					<KeyboardBackspaceIcon style={{ marginLeft: 10, color: "#fff" }} fontSize="large" />
				</Link>
				<Image className={classes.avtar} />
				<Typography variant="body1" className={classes.userName1}>
					{/* {messageData?.SentBy?.username} */}
					Driving School Cloud / {messageData?.subject}
				</Typography>
			</div>
			<Row className={classes.boxRowClass}>
				{/* <Grid item xs={12}> */}
				<div className={classes.messageArea}>
					{
						messageData && messageData?.threads?.length === 0 ? "Not found" : messageData?.threads?.map((thread: any, index: number) =>
							<Row key={`${thread?.body}_${thread?.id}_${index}`}>
								<div>
									{
										parseInt(user.id) !== thread?.sent_by ? <>
											<Row>
												<Col md="6" style={{ display: 'flex', flexDirection: 'row', justifyContent: "start", gridGap: '10px' }}>
													<Avatar
														alt="M"
														src={""}
													/>
													<div className={classes.demoColor}>
														<Typography>
															{thread?.body}
														</Typography>
														{thread?.MessageAttachments.map((data: any) => {
															return (
																<ImageComponent signedUrl={data?.attachment_key} type={data?.attachment_type} fileName={data?.attachment_name} />
															)
														})}
													</div>
												</Col>
												<Col md='6'></Col>
											</Row>
											<Typography style={{ textAlign: "left", marginTop: "5px" }}>
												{moment(thread?.sent_timestamp).format("MMM DD, YYYY | h:mm a")}
											</Typography>

										</> : <>
											<Row>
												<Col md='6'></Col>
												<Col md="6" style={{ display: 'flex', flexDirection: 'row', justifyContent: "end", gridGap: '10px' }}>
													<div className={classes.demoColor2}>
														<Typography>
															{thread?.body}
														</Typography>
														{thread?.MessageAttachments.map((data: any) => {
															return (
																<ImageComponent signedUrl={data?.attachment_key} type={data?.attachment_type} fileName={data?.attachment_name} />
															)
														})}
													</div>
													<Avatar
														alt="M"
														src={toProfile}
													/>
												</Col>
												<Typography style={{ textAlign: "right", marginTop: "5px" }}>
													{moment(thread?.sent_timestamp).format("MMM DD, YYYY | h:mm a")}
												</Typography>
											</Row>
										</>
									}
								</div>
							</Row>
						)
					}
				</div>
				<Divider />

				< Grid container style={{ padding: '20px' }}>
					<Grid item xs={10}>
					</Grid>
					<Grid xs={2} style={{ textAlign: "right" }}>
						<Link to={{ pathname: `${InnerPageRoutes.messageReply}/${messageData?.threads[messageData?.threads?.length - 1].id}`, state: { messageData } }}>
							<Button
								type="button"
								variant="contained"
								className={classes.saveEmailButton}
							>
								Reply
							</Button>
						</Link>
					</Grid>
				</Grid>
			</Row>
		</>
	);
}

export default ReplyMessage;



