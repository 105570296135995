import React, { useState, useEffect, SetStateAction } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Row, Col } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { InputField, InputSelectField } from "../../../common-components/FormComponent/FormFieldsComponent";
import { isRequiredField, isValidRegisterID } from "../../../common-components/FormComponent/FormValidator";
import { SaveButtonComponent } from "../../../common-components/ButtonComponent";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { Button, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import TypographyComponent from "./TableComponent/TypographyComponent";
import * as Actions from "../../../reducer-store/actions/courseAction";
import { DropDownProgramList, DropDownModualList } from "../../../reducer-store/actions/topicAction";
import * as settingAction from "../../../reducer-store/actions/settingsAction";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import { HOURSLIMIT, PAGE } from "../../../reducer-store/constants";
import ToastMessage from "../../../common-components/ToastMessage";
import { COURSE_SUCCESSFULLY_CREATED, COURSE_SUCCESSFULLY_UPDATED } from "../../../reducer-store/ToastMessage";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { FaRegEdit } from "react-icons/fa";
import moment from "moment";
import FieldError from "../../../common-components/FormComponent/ErrorMessage";

type AddCoursesProps = {
  handleStep: React.Dispatch<SetStateAction<number>>;
  stepValue: number;
  editProgramInfo?: any;
};

const useStyles = makeStyles(() => ({
  programCard: {
    backgroundColor: "#ececec",
  },
  button_save: {
    textAlign: "right",
  },
  addCourse: {
    borderRadius: "40px !important",
    backgroundColor: "#00A651 !important",
    border: "1px solid #00A651 !important",
    color: "#ffffff !important",
    padding: "0 35px !important",
    height: "40px !important",
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: 1.75,
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    minWidth: "64px",
  },
  backButton: {
    borderRadius: "40px !important",
    backgroundColor: "#ffffff !important",
    border: "1px solid #393939 !important",
    color: "#393939 !important",
    padding: "0 35px !important",
    height: "40px !important",
    fontWeight: 500,
    fontSize: "0.875rem",
    fontFamily: 'Arial !important',
    lineHeight: 1.75,
    letterSpacing: "0.02857em",
    textTransform: "none",
    minWidth: "64px",
  },
  searchBar: {
    borderColor: "black",
    "& hover": {
      borderColor: "black"
    },
    "& focused": {
      borderColor: "black"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black"
    }
  }
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const theme = createTheme({
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          overflow: "hidden",
          color: '#FFFFFF',
          "& .css-1ygcj2i-MuiTableCell-root": {
            color: "#FFFFFF",
            fontweight: "bold",
          },
          "& .tss-1fz4yw6-MUIDataTableSelectCell-fixedLeft": {
            backgroundColor: "#404040",
          },
          "& .tss-1akey0g-MUIDataTableHeadCell-data": {
            color: "white",
          },
          "& .css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon ": {
            color: "white"
          },
          "& .tss-1h9t3sl-MUIDataTableHeadCell-sortAction": {
            display: "-webkit-box"
          },
          "& .css-11k72hi-MuiTableCell-root.MuiTableCell-head": {
            backgroundColor: "#646464",
          },
          "& .css-pfof31-MuiTableCell-root ": {
            color: "#FFFFFF"
          },
          "& .tss-10syd3x-MUIDataTableHeadCell-root": {
            fontSize: '15px',
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Arial"
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: "Arial",
        },
        head: {
          backgroundColor: "#404040",
          color: '#FFFFFF'
        },
        footer: {
          backgroundColor: '#FFFFFF'
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:nth-child(odd)": {
            backgroundColor: "#DFDFDF"
          },
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: "Arial"
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        colorInherit: {
          backgroundColor: '#E53D12',
          borderRadius: '4px',
          color: 'white',
          marginRight: '25px',
        }
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          "& .css-1ex1afd-MuiTableCell-root": {
            width: "18%",
          }
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          "& .MuiTablePagination-displayedRows": {
            marginTop: "9%"
          },
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Arial",
          "& .css-140puxv-MuiTypography-root-MuiDialogTitle-root": {
            backgroundColor: '#646464'
          }

        }
      }
    },
    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          fill: '#FFFFFF'
        }
      }
    }
  }
});

export default function AddCourses({ handleStep, editProgramInfo, stepValue }: AddCoursesProps) {
  const [Program, setProgram] = useState("");
  const [Module, setModule] = useState("");
  const [CourseName, setCourseName] = useState("");
  const [courseVisible, setCourseVisible] = useState(true);
  const [TotalHours, setTotalHours] = useState<any>(null);
  const [InclassTrainingHours, setInclassTrainingHours] = useState<any>(null);
  const [InyardTrainingHours, setInyardTrainingHours] = useState<any>(null);
  const [IncabTrainingHours, setIncabTrainingHours] = useState<any>(null);
  const [CourseHoursPerDay, setCourseHoursPerDay] = useState<any>("");
  const [Description, setDescription] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isValidValues, setIsValidValues] = useState(true);
  // const [order, setOrder] = useState('desc');
  const { addCourse, updateCourse } = Actions
  const dispatch = useDispatch();
  const { CourseList } = bindActionCreators(Actions, dispatch);
  const { schoolSettingList } = bindActionCreators(settingAction, dispatch);
  const [rowsPerPage, setRowsPerPage] = useState(1000);
  const [page, setPage] = useState(PAGE);
  const AuthToken = useSelector((state: any) => state.auth);
  const { token } = AuthToken;
  const courseList = useSelector((state: any) => state.course);
  const { courses } = courseList;
  const settingList = useSelector((state: any) => state.setting);
  const { settings } = settingList;
  const [saveUpdate, setSaveUpdate] = useState(true);
  const [moduleList, setModuleList] = useState([{ id: "", name: "Select Module" }])
  const [programList, setProgramList] = useState([{ id: "", name: "Select Program" }])
  const [courseId, setcourseId] = useState("");
  const [programName, setProgramName] = useState("");
  const [programId, setProgramId] = useState("");
  const [workingHours, setWorkingHours] = useState("");
  const [addCourseVisible, setAddCourseVisible] = useState(false);
  const [data, setData] = useState([]);
  const [searchApiData, setSearchApiData] = useState([]);
  const [workingHourError, setWorkingHourError] = useState(false);
  const [workingHourErrorMsg, setWorkingHourErrorMsg] = useState("");
  // const [filterVal, setFilterVal] = useState('');
  // const [searchData, setSearchData] = useState(false);
  const classes = useStyles();

  // const handleFilter = (e: any) => {
  //   if (e.target.value == "") {
  //     setData(searchApiData);
  //   } else {
  //     const filterData = searchApiData.filter((items: any) =>
  //       items.course_name.toLowerCase().includes(e.target.value.toLowerCase())
  //       || items.inclass.toString().includes(e.target.value.toString())
  //       || items.inyard.toString().includes(e.target.value.toString())
  //       || items.hours.toString().includes(e.target.value.toString())
  //       || items.incab.toString().includes(e.target.value.toString())
  //     );
  //     if (filterData.length > 0) {
  //       setData(filterData);
  //       setSearchData(false);
  //     } else {
  //       setData([]);
  //       setSearchData(true);
  //     }
  //   }
  //   setFilterVal(e.target.value);
  // };

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    (async () => {
      await schoolSettingList(token);

      const programId: any = sessionStorage.getItem("program_id");
      setProgramId(programId)
      const program_name: any = sessionStorage.getItem("program_name");
      setProgramName(program_name);

      if (programId === null || programId === "") {
        const newCourseList = await CourseList(page, -1, rowsPerPage, token);
        renderDataTable(newCourseList);
      } else {
        const newCourseList = await CourseList(page, programId, rowsPerPage, token);
        renderDataTable(newCourseList);
      }
    })();
  }, [page, programId, addCourseVisible, saveUpdate]);

  useEffect(() => {
    (async () => {
      var startTime = moment(settings?.data[0]?.working_time_from, 'HH:mm:ss');
      var endTime = moment(settings?.data[0]?.working_time_to, 'HH:mm:ss');
      // calculate total duration
      var duration: any = moment.duration(endTime.diff(startTime));
      var hours: any = parseInt(duration.asHours());
      setWorkingHours(Number.isNaN(hours) ? 8 : hours);
    })();
  }, [settings])

  useEffect(() => {
    (async () => {
      const programId: any = sessionStorage.getItem("program_id");
      setProgramId(programId)
      const program_name: any = sessionStorage.getItem("program_name");
      setProgramName(program_name);
      const moduleArr = await DropDownModualList(page, programId, token);
      const progrmArr = await DropDownProgramList(page, token);
      setProgramList([...programList, ...progrmArr.data])
      setModuleList([...moduleList, ...moduleArr.data])
    })();
  }, []);

  const renderDataTable = (newProgramList: any) => {
    const rowsDataTable = newProgramList && newProgramList?.data?.data?.map((row: any) => {
      return {
        course_name: row.name != null ? row.name : "---",
        module_name: row.ProgramModule?.name !== null ? row.ProgramModule?.name : "---",
        hours: row.total_duration_minutes !== null ? row.total_duration_minutes / 60 : "---",
        inclass: row.total_inclass_training_minutes !== null ? row.total_inclass_training_minutes / 60 : "---",
        inyard: row.total_inyard_training_minutes !== null ? row.total_inyard_training_minutes / 60 : "---",
        incab: row.total_incab_training_minutes !== null ? row.total_incab_training_minutes / 60 : "---",
        edit: <FaRegEdit size={18} style={{ cursor: 'pointer' }} onClick={() => handleEditCourse(row)} />,
        description: row.description
      };
    });
    setData(rowsDataTable);
    setSearchApiData(rowsDataTable);
  };

  const columns = [
    {
      label: 'Courses name',
      name: "course_name",
    },
    {
      label: 'Module name',
      name: "module_name",
    },
    {
      label: 'Total hours',
      name: "hours",
    },
    {
      label: 'Total inclass training hours',
      name: "inclass",
    },
    {
      label: 'Total inyard training hours',
      name: "inyard",
    },
    {
      label: 'Total incab training hours',
      name: "incab",
    },
    {
      label: "Des",
      name: "description",
      options: {
        display: false,
      }
    },
    {
      label: 'Edit',
      name: "edit",
      options: {
        sort: false
      }
    },
  ];

  const options = {
    responsive: "vertical",
    search: false,
    print: false,
    viewColumns: false,
    filter: false,
    download: false,
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    selectableRows: "none",
    rowsPerPageOptions: [],
    fixedHeader: false,
    textLabels: {
      body: {
        noMatch: !courses || (courses?.data?.length > 0 && data?.length == 0) ?
          <Box style={{ textAlign: 'center', margin: '0 auto', top: '50%', left: '50%', justifyContent: 'center' }}>
            <CircularProgress sx={{ color: '#707070' }} />
          </Box>
          : 'Sorry, there is no matching data to display',
      },
    },
    // customToolbar: () => {
    //   return (<>
    //     <TextField
    //       variant="standard"
    //       placeholder="Search"
    //       onInput={(e: any) => handleFilter(e)}
    //       value={filterVal}
    //       className={classes.searchBar}
    //       InputProps={{
    //         style: { color: "black" },
    //         endAdornment: (
    //           <IconButton>
    //             <SearchOutlined />
    //           </IconButton>
    //         ),
    //       }}
    //     />
    //   </>)
    // },
    renderExpandableRow: (rowData: any) => {
      return (
        <StyledTableRow >
          <StyledTableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={8}
          >
            <Box sx={{ margin: 1 }}>
              <Typography fontWeight={700} color="#404040">
                Description :
              </Typography>
              <Typography color="#606060">
                {rowData[6]}
              </Typography>
            </Box>
          </StyledTableCell>
        </StyledTableRow>
      );
    },

  };

  const handleBack = () => {
    handleStep((stepValue) => stepValue - 1);
  };

  const handleCancel = () => {
    resetData();
    setSaveUpdate(true);
    setIsSubmit(false);
  };

  const resetData = () => {
    // setModule("");
    // setProgram("");
    setCourseName("");
    setTotalHours(null);
    setInclassTrainingHours(null);
    setInyardTrainingHours(null);
    setIncabTrainingHours(null);
    setCourseHoursPerDay("");
    setDescription("");
  };

  const SubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    if (
      isRequiredField(Module) &&
      isRequiredField(programName) &&
      isRequiredField(CourseName) &&
      isRequiredField(CourseHoursPerDay) &&
      isRequiredField(Description) &&
      isValidRegisterID(Description) &&
      !workingHourError
    ) {
      setIsSubmit(false);
      let courseObj = {
        program_id: programId,
        module_id: Module,
        name: CourseName,
        description: Description,
        inclass_training_minutes: InclassTrainingHours * 60,
        inyard_training_minutes: InyardTrainingHours * 60,
        incab_training_minutes: IncabTrainingHours * 60,
        total_hours: TotalHours * 60,
        course_hours_per_day: CourseHoursPerDay * 60,
        assessment_name: "",
        assessment_type: "",
        total_score: 100,
        status: 1,
      };
      let resData: any = saveUpdate ? await addCourse(courseObj, token) : await updateCourse(
        courseId,
        courseObj,
        token
      );
      if (!resData.error) {
        toast.success(saveUpdate ? COURSE_SUCCESSFULLY_CREATED : COURSE_SUCCESSFULLY_UPDATED);
        setAddCourseVisible(true);
        const courseList = CourseList(page, programId, rowsPerPage, token);
        renderDataTable(courseList);
        setTimeout(() => {
          resetData();
          setSaveUpdate(true);
          // saveUpdate ? handleStep((stepValue) => stepValue + 1) : CourseList(page, programId, rowsPerPage, token) && setSaveUpdate(!saveUpdate);
        }, 2000);
      }
    }
    setIsValidValues(false);
  };

  const SubmitHandlerCourses = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    handleStep((stepValue) => stepValue + 1);
  };

  const handleEditCourse = async (row: any) => {
    window.scrollTo(0, 0);
    setcourseId(row.id);
    handleStep(stepValue);
    setModule(row.module_id);
    setProgram(row.program_id);
    setCourseName(row.name);
    setTotalHours(row.total_duration_minutes / 60);
    setInclassTrainingHours(row.total_inclass_training_minutes / 60);
    setInyardTrainingHours(row.total_inyard_training_minutes / 60);
    setIncabTrainingHours(row.total_incab_training_minutes / 60);
    setCourseHoursPerDay(row.course_hours_per_day ? row.course_hours_per_day / 60 : "");
    setDescription(row.description);
    setSaveUpdate(false);
  }

  const handleWokringHourCheck = (value: any) => {
    if (value > workingHours) {
      setWorkingHourError(true);
      setWorkingHourErrorMsg("Please enter as per school working hours");
    } else {
      setWorkingHourError(false);
      setWorkingHourErrorMsg("");
    }
  }

  const onChangeProgram = (value: any, setMethod: any) => {
    var regexp = /^[a-zA-Z0-9_]+$/;
    if (value === '' || regexp.test(value))
      setMethod(value);
  }

  return (
    <>
      <ToastMessage />
      <form onSubmit={SubmitHandler}>
        <Card className={classes.programCard}>
          <CardContent>
            <Row>
              <Col lg="4" sm="12">
                <InputField
                  disabled
                  type="text"
                  lable="Program Name"
                  name={programName}
                  value={programName}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Program Name"
                  className="form-textfield-styles"
                  isValidValue={false}
                  isSubmit={false}
                  requiredMessage="Program field is required"
                />
              </Col>
              <Col lg="4" sm="12">
                <InputSelectField
                  select={true}
                  lable="Select Module"
                  name={Module}
                  value={Module}
                  className="form-textfield-styles"
                  options={moduleList}
                  handleChange={(e) => setModule(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Module field is required"
                />
              </Col>
              <Col lg="4" sm="12">
                <InputField
                  disabled={courseVisible || saveUpdate ? false : true}
                  type="text"
                  lable="Course Name"
                  name={CourseName}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Course Name"
                  className="form-textfield-styles"
                  value={CourseName}
                  handleChange={(e) => onChangeProgram(e.target.value, setCourseName)}
                  isValidValue={false}
                  isSubmit={false}
                  requiredMessage="Course Name field is required"
                  isCheckValidRID={true}
                />
              </Col>
              {
                saveUpdate ? "" :
                  <>
                    <Col lg="4" sm="12">
                      <Typography className="fontLabel">
                        Total Hours
                      </Typography>
                      <Typography>
                        {TotalHours !== null ? TotalHours : "---"}
                        <hr style={{ marginTop: 2 }} />
                      </Typography>
                    </Col>
                    <Col lg="4" sm="12">
                      <Typography className="fontLabel">
                        Total Inclass Training Hours
                      </Typography>
                      <Typography>
                        {InclassTrainingHours !== null ? InclassTrainingHours : "---"}
                        <hr style={{ marginTop: 2 }} />
                      </Typography>
                    </Col>
                    <Col lg="4" sm="12">
                      <Typography className="fontLabel">
                        Total Inyard Training Hours
                      </Typography>
                      <Typography>
                        {InyardTrainingHours !== null ? InyardTrainingHours : "---"}
                        <hr style={{ marginTop: 2 }} />
                      </Typography>
                    </Col>
                    <Col lg="4" sm="12">
                      <Typography className="fontLabel">
                        Total Incab Training Hours
                      </Typography>
                      <Typography>
                        {IncabTrainingHours !== null ? IncabTrainingHours : "---"}
                        <hr style={{ marginTop: 2 }} />
                      </Typography>
                    </Col>
                  </>
              }
              <Col lg="4" sm="12">
                <InputField
                  type="number"
                  lable="Course Hours Per Day"
                  name={CourseHoursPerDay}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter Course Hours Per Day"
                  className={`${`form-textfield-styles ${workingHourError ? "errorBottom" : ""}`}`}
                  value={CourseHoursPerDay}
                  handleChange={(e) => { handleWokringHourCheck(e.target.value); setCourseHoursPerDay(e.target.value.slice(0, HOURSLIMIT)) }}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Course Hours Per Day field is required"
                  isCheckValidNoValue={true}
                  disablePrevious="0"
                  disableUpcoming={workingHours}
                />
                {
                  workingHourError ? <FieldError message={workingHourErrorMsg} /> : null
                }
              </Col>
              <Col lg="12" sm="12">
                <InputField
                  type="text"
                  lable="Description"
                  name={Description}
                  rows={5}
                  multiline={true}
                  placeHolder="Enter Description"
                  className="form-textfield-styles"
                  value={Description}
                  handleChange={(e) => setDescription(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Description field is required"
                  isCheckValidRID={true}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg="12" sm="12">
                <div className={classes.button_save}>
                  <Button
                    variant="outlined"
                    onClick={handleCancel}
                    className={classes.backButton}
                  >
                    Cancel
                  </Button>{" "}
                  {" "}
                  <SaveButtonComponent ButtonType="submit" ButtonLabel={saveUpdate ? "Save" : "Update"} />
                  {" "}
                </div>
              </Col>
            </Row>
          </CardContent>
        </Card>
        <TypographyComponent label="Courses List" />
        {/* <div className="main-datatable"> */}
        <ThemeProvider theme={theme} >
          <MUIDataTable
            title={""}
            data={data}
            columns={columns}
            options={options}
          />
        </ThemeProvider >
      </form>

      <form onSubmit={SubmitHandlerCourses}>
        <Row className="mt-4">
          <Col lg="12" sm="12">
            <div className="stepperButton">
              <Button
                variant="outlined"
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </Button>{" "}
              <Button
                type="submit"
                className={classes.addCourse}
                variant="contained"
                disabled={editProgramInfo || programId ? false : true}
              >
                {editProgramInfo ? "Add/Edit Topic" : "Add Topic"}
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};