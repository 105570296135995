import { ActionProps } from "../../ts";
import { LIST_OF_COURSE, LIST_OF_COURSE_ERROR } from "../ActionTypes";

const initialState = { courses: null };

const courseStore = (state: any = initialState, action: ActionProps) => {
  const { type, payload } = action;
  switch (type) {
    case LIST_OF_COURSE: {
      return {
        ...state,
        courses: payload,
      };
    }
    case LIST_OF_COURSE_ERROR: {
      return {
        ...state,
        courses: null,
      };
    }
    default:
      return state;
  }
};

export default courseStore;