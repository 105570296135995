import { ActionProps } from "../../ts";
import { LIST_OF_MODUAL, LIST_OF_MODUAL_ERROR } from "../ActionTypes";

const initialState = { moduals: null };

const modualStore = (state: any = initialState, action: ActionProps) => {
  const { type, payload } = action;
  switch (type) {
    case LIST_OF_MODUAL: {
      return {
        ...state,
        moduals: payload,
      };
    }
    case LIST_OF_MODUAL_ERROR: {
      return {
        ...state,
        moduals: null,
      };
    }
    default:
      return state;
  }
};

export default modualStore;