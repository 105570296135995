import axios from "axios";
import { toast } from "react-toastify";
import { LIST_OF_TOPIC, LIST_OF_TOPIC_ERROR } from "../ActionTypes";
import { LIMIT, PAGE, ADD_TOPIC_API, TOPIC_LIST_API, COURSE_LIST_API, PROGRAMS_LIST_API, MODUAL_LIST_API, UPDATE_TOPIC_API } from "../constants";
import { TOPIC_SUCCESSFULLY_CREATED } from "../ToastMessage";

const token = sessionStorage.getItem("token");
const header = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST,GET,OPTIONS,PUT,DELETE,PATCH",
    "Access-Control-Allow-Headers": "Content-Type, X-Auth-Token, Origin, Authorization"
  },
};

export const addTopic = async (userObj: any, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  const body = JSON.stringify(userObj);
  return await axios
    .post(ADD_TOPIC_API, body, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error.response.data.errorMsg);
    });
};

export const UpdateTopic = async (
  topicId: string,
  userObj: any,
  token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  const body = JSON.stringify(userObj);
  return await axios
    .put(`${UPDATE_TOPIC_API}/${topicId}`, body, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error.response.data.errorMsg);
      return error.response.data;
    });
};

export const TopicList = (page = PAGE, programId: any, limit = LIMIT, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(`${TOPIC_LIST_API}?page=${page}&program_id=${programId}&limit=${limit}`, header)
      .then((res) => {
        dispatch({
          type: LIST_OF_TOPIC,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: LIST_OF_TOPIC_ERROR,
        });
        toast.error(error.response.data.errorMsg);
      });
  };
};
export const TopicViewList = (program_id: string, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(`${TOPIC_LIST_API}?program_id=${program_id}`, header)
      .then((res) => {
        dispatch({
          type: LIST_OF_TOPIC,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: LIST_OF_TOPIC_ERROR,
        });
        toast.error(error.response.data.errorMsg);
      });
  };
};

export const DropDownCourseList = async (
  page = PAGE,
  program_id: any,
  token: string,
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .get(
      `${COURSE_LIST_API}?page=${page}&module_id=${program_id}`, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error.response.data.errorMsg);
    });
};

export const DropDownModualList = async (
  page = PAGE,
  program_id: any,
  token: string
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .get(`${MODUAL_LIST_API}?page=${page}&program_id=${program_id}`, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error.response.data.errorMsg);
    });
};

export const ModualListByProgramId = async (
  programId: any,
  token: string
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .get(`${MODUAL_LIST_API}?program_id=${programId}`, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error.response.data.errorMsg);
    });
};

export const DropDownProgramList = async (
  page = PAGE,
  token: string
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .get(`${PROGRAMS_LIST_API}?page=${page}`, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error.response.data.errorMsg);
    });
};

