import axios from "axios";
import { toast } from "react-toastify";
import { LIST_OF_PAYMENTS, LIST_OF_PAYMENTS_ERROR } from "../ActionTypes";
import { PAYMNET_LIST_API, PAGE, LIMIT, PAYMENT_KEY, PAYMENT_STUDENT_LIST_API, PAYMENT_INVOICE_URL, } from "../constants";

const token = sessionStorage.getItem("token");
const header = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST,GET,OPTIONS,PUT,DELETE,PATCH",
    "Access-Control-Allow-Headers": "Content-Type, X-Auth-Token, Origin, Authorization"
  },
};

export const paymentsList = (
  page = PAGE,
  limit = LIMIT,
  token: string,
  order: string,
  search: any,
  status: any
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(`${PAYMNET_LIST_API}?page=${page}&limit=${limit}&orderBy=${order}&search=${search}${status === "" ? "" : `&status=${status}`}`, header)
      .then((res) => {
        dispatch({
          type: LIST_OF_PAYMENTS,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: LIST_OF_PAYMENTS_ERROR,
        });
        toast.error(error.response.data.errorMsg);
      });
  };
};

export const studentPaymentsList = async (status: any, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .get(`${PAYMENT_STUDENT_LIST_API}${status === "" ? "" : `?status=${status}`}`, header)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      return error.response;
    });
};

export const addPaymentKey = async (userObj: any, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .post(PAYMENT_KEY, userObj, header)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      return error.response;
    });
};

export const getPaymentKey = async (token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .get(`${PAYMENT_KEY}`, header)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      return error.response;
    });
};

export const updatePaymentKey = async (id: string | number, userObj: any, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .put(`${PAYMENT_KEY}/${id}`, userObj, header)
    .then((res) => {
      return res?.data;
    })
    .catch((error) => {
      return error.response;
    });
};

export const generatePaymentUrl = async (userObj: any, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  const body = JSON.stringify(userObj);
  return await axios
    .post(PAYMENT_INVOICE_URL, body, header)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.errorMsg);
      return error.response;
    });
};