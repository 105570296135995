import { combineReducers } from "redux";
import assetsStore from "./assetsStore";
import AuthStore from "./authStore";
import communicationStore from "./communicationStore";
import courseStore from "./courseStore";
import dashboardStore from "./dashboardStore";
import modualStore from "./modualStore";
import programStore from "./programStore";
import topicStore from "./topicStore";
// import dashboardStore from "./dashboardStore";
import studentContractStore from "./studentContractStore";
import paymentStore from "./paymentStore";
import settingStore from "./settingStore";
import staffmanagementStore from "./staffmanagementStore";
import notificationStore from "./notificationStore";
import planStore from "./planStore";

export default combineReducers({
  auth: AuthStore,
  dashboard: dashboardStore,
  communication: communicationStore,
  program: programStore,
  course: courseStore,
  topic: topicStore,
  modual: modualStore,
  asset: assetsStore,
  studentContract: studentContractStore,
  payment: paymentStore,
  setting: settingStore,
  staff: staffmanagementStore,
  notification: notificationStore,
  plan: planStore,
});
