import React, { Component } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { ROUTES } from "./constants/routes";

type StateProps = {
  auth: AuthProps;
};

type AuthProps = {
  isAuthenticated: string;
  isVerified: string;
  token: string;
};

type RouteProps = {
  Component: any;
  path: string;
  exact?: boolean;
  restricted?: boolean;
};

export const PrivateRoute: React.FC<RouteProps> = ({ Component, path,}) => {
  const auth: AuthProps = useSelector((state: StateProps) => state.auth);
  const { isAuthenticated, isVerified } = auth;
  return (
    <Route
      exact
      path={path}
      render={(props: any) =>
        isAuthenticated && isVerified ? 
        (
          <Component {...props} />
        ) 
        : (
          <Redirect to={ROUTES.login} />
         )
      }
    />
  );
};

export const PublicRoute: React.FC<RouteProps> = ({
  Component,
  path,
  restricted,
  exact,
}) => {
  const auth: AuthProps = useSelector((state: StateProps) => state.auth);
  const { isAuthenticated, isVerified, token } = auth;
  return (
    <Route
      exact
      path={path}
      render={(props: any) =>
        isAuthenticated && isVerified && restricted ? (
          <Redirect to={ROUTES.dashboard} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};
