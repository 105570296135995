import axios from "axios";
import { toast } from "react-toastify";
import { LIST_OF_STAFF_MANAGEMENT, LIST_OF_STAFF_MANAGEMENT_ERROR, SINGLE_STAFF_ERROR, SINGLE_STAFF_INFO } from "../ActionTypes";
import {
  STAFF_MANAGEMENT_LIST_API,
  ADD_STAFF_MANAGEMENT_API,
  UPDATE_STAFF_MANAGEMENT_API,
  SINGLE_STAFF_MANAGEMENT_API,
  PAGE,
  LIMIT,
} from "../constants";

const token = sessionStorage.getItem("token");
const header = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST,GET,OPTIONS,PUT,DELETE,PATCH",
    "Access-Control-Allow-Headers": "Content-Type, X-Auth-Token, Origin, Authorization"
  },
};

export const staffManagementList = (
  page = PAGE,
  limit = LIMIT,
  token: string,
  order: string,
  search: any,
) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(
        `${STAFF_MANAGEMENT_LIST_API}?page=${page}&limit=${limit}&orderBy=${order}&search=${search}`, header
      )
      .then((res) => {
        dispatch({
          type: LIST_OF_STAFF_MANAGEMENT,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: LIST_OF_STAFF_MANAGEMENT_ERROR,
        });
        toast.error(error.response.data.errorMsg);
      });
  };
};

export const addStaffManagement = async (userObj: any, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .post(ADD_STAFF_MANAGEMENT_API, userObj, header)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.errorMsg);
      // return error?.response?.data;
    });
};

export const updateStaffManagement = async (id: string, userObj: any, token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return await axios
    .put(`${UPDATE_STAFF_MANAGEMENT_API}/${id}`, userObj, header)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.errorMsg);
      return error?.response?.data;
    });
};

export const SingleStaffManagementData = (id = "", token: string) => {
  header.headers.Authorization = `Bearer ${token}`;
  return async (dispatch: any) => {
    return await axios
      .get(`${SINGLE_STAFF_MANAGEMENT_API}/${id}`, header)
      .then((res) => {
        dispatch({
          type: SINGLE_STAFF_INFO,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: SINGLE_STAFF_ERROR,
        });
        toast.error(error.response.data.errorMsg);
        return error.response;
      });
  };
};