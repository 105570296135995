export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const SELECT_OTP_PLATFORM = "SELECT_OTP_PLATFORM";
export const OTP_SENT = "LOGIN_OTP_SENT_SUCCESS";
export const OTP_SENT_ERROR = "LOGIN_OTP_SENT_ENRRO";
export const OTP_VERIFY = "LOGIN_OTP_VERIFY_SUCCESS";
export const OTP_VERIFY_ERROR = "LOGIN_OTP_VERIFY_ERROR";
export const RESEND_OTP = "LOGIN_OTP_RESEND_SUCCESS";
export const RESEND_OTP_ERROR = "LOGIN_OTP_RESEND_ERROR";
export const USER_LOADING_SUCCESS = "USER_LOADING_SUCCESS";
export const USER_LOADING_ERROR = "USER_LOADING_ERROR";
export const USER_LOGOUT = "USER_LOGOUT";

export const PLANLIST = "PLAN_LIST";
export const PLANLIST_FAIL = "PLAN_LIST_FAIL";

export const LIST_OF_DASHBOARD = "LIST_OF_DASHBOARD";
export const LIST_OF_DASHBOARD_ERROR = "LIST_OF_DASHBOARD_ERROR";

export const LIST_OF_STUDENT_CONTRACT = "STUDENT_CONTRACTS"
export const LIST_OF_STUDENT_CONTRACT_ERROR = "STUDENT_CONTRACTS_ERROR"

export const SINGLEL_STUDENT_CONTRACT = "SINGLE_STUDENT_CONTRACTS"
export const SINGLEL_STUDENT_CONTRACT_ERROR = "SINGLE_STUDENT_CONTRACTS_ERROR"

export const LIST_OF_PAYMENTS = "PAYMENTS_LIST"
export const LIST_OF_PAYMENTS_ERROR = "PAYMENTS_LIST_ERROR"

export const LIST_OF_SCHOOL_SETTINGS = "SCHOOL_SETTINGS_LIST"
export const LIST_OF_SCHOOL_SETTINGS_ERROR = "SCHOOL_SETTINGS_ERROR"
export const LIST_OF_TAX = "TAX_LIST"
export const LIST_OF_TAX_ERROR = "TAX_ERROR"
export const LIST_OF_WHITE_IP = "WHITE_IP_LIST"
export const LIST_OF_WHITE_IP_ERROR = "WHITE_IP_ERROR"
export const DELETE_WHITE_IP = "DELETE_WHITE_IP"
export const DELETE_OF_WHITE_IP_ERROR = "DELETE_WHITE_IP_ERROR"

export const LIST_OF_ASSETS_MANAGEMENT = "LIST_OF_ASSETS_MANAGEMENT";
export const LIST_OF_ASSETS_MANAGEMENT_ERROR = "LIST_OF_ASSETS_MANAGEMENT_ERROR";

export const LIST_OF_STAFF_MANAGEMENT = "STAFF_MANAGEMENTS_LIST";
export const LIST_OF_STAFF_MANAGEMENT_ERROR = "STAFF_MANAGEMENT_ERROR";
export const SINGLE_STAFF_INFO = "LIST_OF_STAFF";
export const SINGLE_STAFF_ERROR = "LIST_OF_STAFF_ERROR";

export const LIST_OF_TOPIC = "LIST_OF_TOPIC";
export const LIST_OF_TOPIC_ERROR = "LIST_OF_TOPIC_ERROR";

export const LIST_OF_PROGRAMS = "LIST_OF_PROGRAMS";
export const LIST_OF_PROGRAMS_ERROR = "LIST_OF_PROGRAMS_ERROR";

export const LIST_OF_MODUAL = "LIST_OF_MODUAL";
export const LIST_OF_MODUAL_ERROR = "LIST_OF_MODUAL_ERROR";

export const LIST_OF_COURSE = "LIST_OF_COURSE";
export const LIST_OF_COURSE_ERROR = "LIST_OF_COURSE_ERROR";

export const LIST_OF_COMMUNICATION = "LIST_OF_COMMUNICATION";
export const LIST_OF_COMMUNICATION_ERROR = "LIST_OF_COMMUNICATION_ERROR";

export const COUNT_OF_UNREAD_COMMUNICATION = "COUNT_OF_UNREAD_COMMUNICATION";
export const COUNT_OF_UNREAD_COMMUNICATION_ERROR = "COUNT_OF_UNREAD_COMMUNICATION_ERROR";

export const LIST_OF_NOTIFICATION = "LIST_OF_NOTIFICATION";
export const LIST_OF_NOTIFICATION_ERROR = "LIST_OF_NOTIFICATION_ERROR";

export const LIST_OF_PROGRAM_FEE = "LIST_OF_PROGRAM_FEE";
export const LIST_OF_PROGRAM_FEE_ERROR = "LIST_OF_PROGRAM_FEE_ERROR";

export const LIST_OF_PLAN_HISTORY = "LIST_OF_PLAN_HISTORY";
export const LIST_OF_PLAN_HISTORY_ERROR = "LIST_OF_PLAN_HISTORY_ERROR";

export const LIST_OF_SINGLE_PLAN_HISTORY = "LIST_OF_PLAN_HISTORY";
export const LIST_OF_SINGLE_PLAN_HISTORY_ERROR = "LIST_OF_PLAN_HISTORY_ERROR";

export const LIST_OF_PLAN = "LIST_OF_PLAN";
export const LIST_OF_PLAN_ERROR = "LIST_OF_PLAN_ERROR";

export const GET_SCHOOL_UPDATED_DATA = "GET_SCHOOL_UPDATED_DATA";
export const GET_SCHOOL_UPDATED_DATA_ERROR = "GET_SCHOOL_UPDATED_DATA_ERROR";

export const TAX_DETAILS = "TAX_DETAILS";
export const TAX_DETAILS_ERROR = "TAX_DETAILS_ERROR";