import React, { useState, useEffect } from "react";
import "../../Common.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Box, Table, TableBody, TableHead, TableRow } from "@material-ui/core";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import PaginationComponent from "../../../common-components/PaginationComponent";
// import Paypal from '../../../svg_icons/paypal.png';
import { PAGE, LIMIT } from "../../../reducer-store/constants";
import { bindActionCreators } from "redux";
import * as Actions from '../../../reducer-store/actions/paymentsAction'
import { useDispatch, useSelector } from "react-redux";
import Master from '../../../svg_icons/mastro.png';
import Visa from '../../../svg_icons/visa.png';
import { LIST_OF_PAYMENTS_ERROR } from "../../../reducer-store/ActionTypes";
import { CircularProgress } from "@mui/material";

const Refunded: React.FC = () => {
    const [page, setPage] = useState(PAGE);
    const [search, setSearch] = useState('');
    const [order, setOrder] = useState("asc");
    const AuthToken = useSelector((state: any) => state.auth);
    const { token } = AuthToken;
    const [rowsPerPage, setRowsPerPage] = useState(LIMIT);
    const dispatch = useDispatch();
    const { paymentsList } = bindActionCreators(Actions, dispatch);
    const listOfPayment = useSelector((state: any) => state.payment);
    const { payments } = listOfPayment;
    const status = 5


    const handleSearch = (value: string) => {
        setSearch(value);
    };

    useEffect(() => {
        (async () => {
            dispatch({ type: LIST_OF_PAYMENTS_ERROR })
            await paymentsList(page, rowsPerPage, token, order, search, status);
        })();
    }, [page]);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));

    const cardshow = (value: any) => {
        if (value.length === 0) {
            return "---";
        }
        switch (value[0].brand) {
            case "visa":
                return <img src={Visa} alt="visa" />;
            case "master":
                return <img src={Master} alt="visa" />;
            default:
                return <img src={Visa} alt="visa" />;
        }
    };

    return (
        <>
            <div>
                <Table className="communication-table" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left" component="th">
                                ID
                            </StyledTableCell>
                            <StyledTableCell align="left" component="th">
                                Amount
                            </StyledTableCell>
                            <StyledTableCell align="left" component="th">
                                Transaction ID
                            </StyledTableCell>
                            <StyledTableCell align="left" component="th">
                                Fee Type
                            </StyledTableCell>
                            <StyledTableCell align="left" component="th">
                                Transaction Acc
                            </StyledTableCell>
                            <StyledTableCell align="left" component="th">
                                Instrument Logo
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!payments ? (
                            <TableRow>
                                <StyledTableCell align="center" colSpan={6}>
                                    <Box style={{ textAlign: 'center', margin: '0 auto', top: '50%', left: '50%', justifyContent: 'center' }}>
                                        <CircularProgress sx={{ color: '#707070' }} />
                                    </Box>
                                </StyledTableCell>
                            </TableRow>
                        ) : payments.data.length > 0 ? (
                            payments.data.map((row: any) =>
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell align="left">{row.id}</StyledTableCell>
                                    <StyledTableCell align="left">{row.symbol ? row.symbol : "--"} {" "}{Number(row.total_amount).toFixed(2)}</StyledTableCell>
                                    <StyledTableCell align="left">{row.PaymentInfos ? row.PaymentInfos[0].transaction : "--"}</StyledTableCell>
                                    <StyledTableCell align="left">{row.feeType ? row.feeType : "--"}</StyledTableCell>
                                    <StyledTableCell align="left">**** **** **** {row.PaymentInfos ? row.PaymentInfos[0].last4 : "--"}</StyledTableCell>
                                    <StyledTableCell align="left">{row.PaymentInfos ? cardshow(row.PaymentInfos[0].network) : ""}</StyledTableCell>
                                </StyledTableRow>
                            )
                        ) : (
                            <StyledTableRow>
                                <StyledTableCell align="center" colSpan={8}>
                                    No Data Found
                                </StyledTableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                </Table>
                <PaginationComponent
                    page={page}
                    setPage={setPage}
                    paginationData={payments && payments.meta}
                />
            </div>

        </>
    );
};
export default Refunded;