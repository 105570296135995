import { DrawerItem } from '../ts';
import { ROUTES } from './routes';
import { ReactComponent as DashboardIcon } from "../svg_icons/dashboard.svg";
import { ReactComponent as CommunicationIcon } from "../svg_icons/communication.svg";
import { ReactComponent as ProgramIcon } from "../svg_icons/program.svg";
/* import { ReactComponent as UpdatePlanFeeIcon } from "../svg_icons/update_plan_fee.svg"; */
import { ReactComponent as SettingIcon } from "../svg_icons/setting_new.svg";
import { ReactComponent as StaffManagementIcon } from "../svg_icons/staff_management_new.svg";
import { ReactComponent as StudentContractManagementIcon } from "../svg_icons/Student_Contract _Management.svg";
import { ReactComponent as AssetManagementIcon } from "../svg_icons/Asset_Management.svg";
import { ReactComponent as SchoolPaymentIcon } from "../svg_icons/school_payment.svg";
import { ReactComponent as PlanIcon } from "../svg_icons/plan.svg";

export const DRAWER_LIST: DrawerItem[] = [
  {
    route: ROUTES.dashboard,
    literal: "Dashboard",
    Icon: DashboardIcon,
  },
  {
    route: ROUTES.communication,
    literal: "Communication",
    Icon: CommunicationIcon,
  },
  {
    route: ROUTES.programs,
    literal: "Programs",
    Icon: ProgramIcon,
  },
  {
    route: ROUTES.assetManagement,
    literal: "Asset Management",
    Icon: AssetManagementIcon,
  },
  {
    route: ROUTES.staffManagement,
    literal: "Staff Management",
    Icon: StaffManagementIcon,
  },
  {
    route: ROUTES.studentContractManagement,
    literal: "Terms and Contracts",
    Icon: StudentContractManagementIcon,
  },
  {
    route: ROUTES.payments,
    literal: "Payments",
    Icon: SchoolPaymentIcon,
  },
  {
    route: ROUTES.studentPayments,
    literal: "Student Payments",
    Icon: SchoolPaymentIcon,
  },
  {
    route: ROUTES.settings,
    literal: "Settings",
    Icon: SettingIcon,
  },
  {
    route: ROUTES.myPlan,
    literal: "My Plan",
    Icon: PlanIcon,
  },
];
