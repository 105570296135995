import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
// import "../../Common.css";
import { Row, Col } from "react-bootstrap";
import Typography from "@material-ui/core/Typography";
import { InputField, InputSelectField } from "../../../common-components/FormComponent/FormFieldsComponent";
import { Button } from "react-bootstrap";
import { Table, TableBody, TableHead, TableRow } from "@material-ui/core";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Collapse from "@mui/material/Collapse";
import Box from "@material-ui/core/Box";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { SaveButtonComponent } from "../../../common-components/ButtonComponent";
import { isRequiredField } from "../../../common-components/FormComponent/FormValidator";
import * as Actions from "../../../reducer-store/actions/settingsAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { SCHOOL_SETTING_SUCCESSFULLY_ADDED, SCHOOL_SETTING_SUCCESSFULLY_UPDATED } from "../../../reducer-store/ToastMessage";
import { bindActionCreators } from "redux";
// import { LIMIT, PAGE } from "../../../reducer-store/constants";
import moment from "moment";
import ToastMessage from "../../../common-components/ToastMessage";
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { FaRegEdit } from "react-icons/fa";
import { CircularProgress } from "@mui/material";
import { weekDaysArr } from "../../../reducer-store/constants";

const useStyles = makeStyles((theme) => ({
  boxBorder: {
    boxShadow: "0px 0px 0px #8e8e8e",
    backgroundColor: "#e6e6e6",
    borderRadius: "0px",
  },
  divStyling: {
    padding: "15px 20px",
  },
  boxBorder1: {
    margin: "20px 0px",
  },
  iconHover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  button_save: {
    textAlign: "right",
  },
  saveEmailButton1: {
    borderRadius: "40px !important",
    backgroundColor: "#ffffff !important",
    border: "1px solid #000000 !important",
    color: "#000000 !important",
    padding: "0 35px !important",
    height: "40px !important",
    textTransform: 'capitalize',
    fontSize: "0.875rem",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const SchoolSetting: React.FC = () => {
  const classes = useStyles();
  const [dayFrom, setdayFrom] = useState("");
  const [dayTo, setdayTo] = useState("");
  const [fromTime, setfromTime] = useState("");
  const [toTime, settoTime] = useState("");
  const [lunchFromTime, setLunchFromTime] = useState("");
  const [lunchToTime, setLunchToTime] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isValidValues, setIsValidValues] = useState(true);
  const { addSchoolSettings, updateSchoolSetting } = Actions;
  const AuthToken = useSelector((state: any) => state.auth);
  const { token } = AuthToken;
  const dispatch = useDispatch();
  const { schoolSettingList } = bindActionCreators(Actions, dispatch);
  const settingList = useSelector((state: any) => state.setting);
  const { settings } = settingList;
  const [btnDisable, setBtnDisable] = useState(false);
  const [bookRoomData, setBookRoomData] = useState([{ breakTitle: "", from: "", to: "" },]);
  const [saveUpdate, setSaveUpdate] = useState(true);
  const [edit, setEdit] = useState(false);
  const [settingId, setSettingId] = useState("");
  const [removeBtn, setRemoveBtn] = useState(false);
  const weekDays = [
    { id: "", name: "Select Day" },
    { id: "1", name: "Monday" },
    { id: "2", name: "Tuesday" },
    { id: "3", name: "Wednesday" },
    { id: "4", name: "Thursday" },
    { id: "5", name: "Friday" },
    { id: "6", name: "Saturday" },
    { id: "0", name: "Sunday" },
  ]

  useEffect(() => {
    (async () => {
      await schoolSettingList(token);
    })();
  }, []);

  const manageDayFrom = (e: any) => {
    const str = e.target.value;
    setdayFrom(str);
  };

  const managedayTo = (e: any) => {
    const str = e.target.value;
    setdayTo(str);
  };

  const handleBreakTime = (indexNum: any, event: any) => {
    const values = [...bookRoomData];
    const newTimeList = values.map((data: any, index: number) => {
      if (index === parseInt(indexNum)) {
        if (event.target.name === "from")
          return { ...data, [event.target.name]: event.target.value };
        if (event.target.name === "to")
          return { ...data, [event.target.name]: event.target.value };
        return { ...data, [event.target.name]: event.target.value };
      }
      return data;
    });
    setBookRoomData(newTimeList);
  };

  const handleAddFields = () => {
    const values = [...bookRoomData];
    setRemoveBtn(true);

    if (values.length < 5) {
      values.push({ breakTitle: "", from: "", to: "" });
    } else {
      setBtnDisable(true);
    }
    setBookRoomData(values);
  };

  const handleRemoveFields = () => {
    const values = [...bookRoomData];
    if (values.length > 1) values.pop();
    if (values.length < 2) setRemoveBtn(false);
    setBookRoomData(values);
    setBtnDisable(false);
  };

  const resetData = () => {
    setBookRoomData([{ breakTitle: "", from: "", to: "" }]);
    setdayFrom("");
    setdayTo("");
    setfromTime("");
    settoTime("");
    setLunchFromTime("");
    setLunchToTime("");
  };

  const handleCancel = () => {
    resetData();
    setSaveUpdate(true);
    setIsSubmit(false);
    setRemoveBtn(false);
    setEdit(false);
  };

  const checkAndVerify = (value: any) => {
    if (value === undefined)
      return true;
    if (isRequiredField(value.breakTitle) &&
      isRequiredField(value.from) &&
      isRequiredField(value.to)
    ) {
      return true;
    }
    return false;
  }

  const SubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    if (
      isRequiredField(dayFrom) &&
      isRequiredField(dayTo) &&
      isRequiredField(fromTime) &&
      isRequiredField(toTime) &&
      isRequiredField(lunchFromTime) &&
      isRequiredField(lunchToTime) &&
      isRequiredField(dayFrom) &&
      isRequiredField(dayTo) &&
      isRequiredField(fromTime) &&
      isRequiredField(toTime) &&
      isRequiredField(lunchFromTime) &&
      isRequiredField(lunchToTime) &&
      checkAndVerify(bookRoomData[0]) &&
      checkAndVerify(bookRoomData[1]) &&
      checkAndVerify(bookRoomData[2]) &&
      checkAndVerify(bookRoomData[3]) &&
      checkAndVerify(bookRoomData[5])
    ) {
      setIsSubmit(false);
      let userObj = {
        working_day_from: dayFrom,
        working_day_to: dayTo,
        working_time_from: fromTime,
        working_time_to: toTime,
        lunch_time_from: lunchFromTime,
        lunch_time_to: lunchToTime,
        break_title1: bookRoomData[0] !== undefined ? bookRoomData[0].breakTitle ? bookRoomData[0].breakTitle : "" : null,
        break_time_from1: bookRoomData[0] !== undefined ? bookRoomData[0].from ? bookRoomData[0].from : null : null,
        break_time_to1: bookRoomData[0] !== undefined ? bookRoomData[0].to ? bookRoomData[0].to : null : null,
        break_title2: bookRoomData[1] !== undefined ? bookRoomData[1].breakTitle ? bookRoomData[1].breakTitle : "" : null,
        break_time_from2: bookRoomData[1] !== undefined ? bookRoomData[1].from ? bookRoomData[1].from : null : null,
        break_time_to2: bookRoomData[1] !== undefined ? bookRoomData[1].to ? bookRoomData[1].to : null : null,
        break_title3: bookRoomData[2] !== undefined ? bookRoomData[2].breakTitle ? bookRoomData[2].breakTitle : "" : null,
        break_time_from3: bookRoomData[2] !== undefined ? bookRoomData[2].from ? bookRoomData[2].from : null : null,
        break_time_to3: bookRoomData[2] !== undefined ? bookRoomData[2].to ? bookRoomData[2].to : null : null,
        break_title4: bookRoomData[3] !== undefined ? bookRoomData[3].breakTitle ? bookRoomData[3].breakTitle : "" : null,
        break_time_from4: bookRoomData[3] !== undefined ? bookRoomData[3].from ? bookRoomData[3].from : null : null,
        break_time_to4: bookRoomData[3] !== undefined ? bookRoomData[3].to ? bookRoomData[3].to : null : null,
        break_title5: bookRoomData[4] !== undefined ? bookRoomData[4].breakTitle ? bookRoomData[4].breakTitle : "" : null,
        break_time_from5: bookRoomData[4] !== undefined ? bookRoomData[4].from ? bookRoomData[4].from : null : null,
        break_time_to5: bookRoomData[4] !== undefined ? bookRoomData[4].to ? bookRoomData[4].to : null : null,
      };

      let resData: any = saveUpdate ? await addSchoolSettings(userObj, token) : await updateSchoolSetting(settingId, userObj, token);
      if (!resData.error) {
        toast.success(saveUpdate ? SCHOOL_SETTING_SUCCESSFULLY_ADDED : SCHOOL_SETTING_SUCCESSFULLY_UPDATED);
        setTimeout(() => {
          schoolSettingList(token);
          resetData();
          setSaveUpdate(true);
          setRemoveBtn(false);
        }, 2000);
        return;
      }
      toast.error(resData.data.errorMsg);
    }
    setIsValidValues(false);
  };

  const handleEditSetting = async (row: any) => {
    setEdit(true);
    var newArr: any = [];
    window.scrollTo(0, 0);
    setSettingId(row.id);
    setdayFrom(row.working_day_from);
    setdayTo(row.working_day_to);
    setfromTime(row.working_time_from);
    settoTime(row.working_time_to);
    setLunchFromTime(row.lunch_time_from);
    setLunchToTime(row.lunch_time_to);
    if (row.break_title1 && row.break_time_from1 && row.break_time_to1) {
      newArr[0] = {
        breakTitle: row.break_title1,
        from: row.break_time_from1,
        to: row.break_time_to1,
      };
    }
    if (row.break_title2 && row.break_time_from2 && row.break_time_to2) {
      newArr[1] = {
        breakTitle: row.break_title2,
        from: row.break_time_from2,
        to: row.break_time_to2,
      };
    }
    if (row.break_title3 && row.break_time_from3 && row.break_time_to3) {
      newArr[2] = {
        breakTitle: row.break_title3,
        from: row.break_time_from3,
        to: row.break_time_to3,
      };
    }
    if (row.break_title4 && row.break_time_from4 && row.break_time_to4) {
      newArr[3] = {
        breakTitle: row.break_title4,
        from: row.break_time_from4,
        to: row.break_time_to4,
      };
    }
    if (row.break_title5 && row.break_time_from5 && row.break_time_to5) {
      newArr[4] = {
        breakTitle: row.break_title5,
        from: row.break_time_from5,
        to: row.break_time_to5,
      };
    }

    if (newArr.length > 6) {
      setBtnDisable(true);
    }
    setBookRoomData(newArr);
    setSaveUpdate(false);
    if (newArr.length > 1) setRemoveBtn(true);
  };

  const manageTimeFormat = (time: string) => {
    return moment(time, "HH:mm").format("h:mm A")
  }

  const renderAllBreakTimes = (index: number, title: string, breakFrom: string, breakTo: string) => {
    return title && breakFrom && breakTo && <Col md={{ span: 4, offset: 1 }} lg="3" sm="3" xs="12">
      <Typography variant="h6" gutterBottom component="div">
        Break Time {index}:
      </Typography>
      <Typography
        variant="subtitle1"
        gutterBottom
        component="div"
      >
        Break Title: {title}
      </Typography>
      <Typography
        variant="subtitle1"
        gutterBottom
        component="div"
      >
        Break Time: {manageTimeFormat(breakFrom)} - {manageTimeFormat(breakTo)}
      </Typography>
    </Col>
  }

  function Rows(props: { row: any, index: string }) {
    const { row, index }: any = props;
    const [open, setOpen] = useState(false);
    return (
      <>
        <TableRow>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell align="left">{index + 1}</TableCell>
          <TableCell align="left">
            {weekDaysArr(row.working_day_from)} - {weekDaysArr(row.working_day_to)}
          </TableCell>
          <TableCell align="left">
            {manageTimeFormat(row.working_time_from)} - {manageTimeFormat(row.working_time_to)}
          </TableCell>
          <TableCell align="left">
            {manageTimeFormat(row.lunch_time_from)} - {manageTimeFormat(row.lunch_time_to)}
          </TableCell>
          <TableCell align="left">
            <FaRegEdit size={22} color="#000000" onClick={() => handleEditSetting(row)} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box className={classes.boxBorder1}>
                <div className="row">
                  {renderAllBreakTimes(1, row.break_title1, row.break_time_from1, row.break_time_to1)}
                  {renderAllBreakTimes(2, row.break_title2, row.break_time_from2, row.break_time_to2)}
                  {renderAllBreakTimes(3, row.break_title3, row.break_time_from3, row.break_time_to3)}
                  {renderAllBreakTimes(4, row.break_title4, row.break_time_from4, row.break_time_to4)}
                  {renderAllBreakTimes(5, row.break_title5, row.break_time_from5, row.break_time_to5)}
                </div>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

  return (
    <>
      <ToastMessage />

      <Paper className={classes.boxBorder}>
        {!settings || settings.data.length === 0 || edit ? (
          <div className={classes.divStyling}>
            <form onSubmit={SubmitHandler}>
              <Row>
                <Col lg="6" sm="12">
                  <Typography variant="h6" component="div">
                    Working Days :
                  </Typography>
                  <Row className="mt-3">
                    <Col lg="6" sm="12">
                      <InputSelectField
                        select={true}
                        lable="From"
                        name={dayFrom}
                        value={dayFrom}
                        className="form-textfield-styles"
                        options={weekDays}
                        handleChange={(e) => manageDayFrom(e)}
                        isValidValue={isValidValues}
                        isSubmit={isSubmit}
                        requiredMessage="Working From is required"
                      />
                    </Col>
                    <Col lg="6" sm="12">
                      <InputSelectField
                        select={true}
                        lable="To"
                        name={dayTo}
                        value={dayTo}
                        className="form-textfield-styles"
                        options={weekDays}
                        handleChange={(e) => managedayTo(e)}
                        isValidValue={isValidValues}
                        isSubmit={isSubmit}
                        requiredMessage="Working To is required"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg="6" sm="12">
                  <Typography variant="h6" component="div">
                    School Working Time :
                  </Typography>
                  <Row className="mt-3">
                    <Col lg="6" sm="12">
                      <InputField
                        type="time"
                        lable="From Time"
                        name={fromTime}
                        rows={1}
                        multiline={false}
                        placeHolder="Enter From Time"
                        className="form-textfield-styles"
                        value={fromTime}
                        handleChange={(e) => setfromTime(e.target.value)}
                        isValidValue={isValidValues}
                        isSubmit={isSubmit}
                        requiredMessage="Working From Time is required"
                      />
                    </Col>
                    <Col lg="6" sm="12">
                      <InputField
                        type="time"
                        lable="To Time"
                        name={toTime}
                        rows={1}
                        multiline={false}
                        placeHolder="Enter From Time"
                        className="form-textfield-styles"
                        value={toTime}
                        handleChange={(e) => settoTime(e.target.value)}
                        isValidValue={isValidValues}
                        isSubmit={isSubmit}
                        requiredMessage="Working To Time is required"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <hr className="hrline" />
              <Row>
                <Col lg="7" sm="12">
                  <Typography variant="h6" component="div">
                    Break Time :
                  </Typography>
                  {bookRoomData.map((data, i) => {
                    return (
                      <Row className="mt-3" key={i}>
                        <Col lg="4" sm="12">
                          <InputField
                            type="text"
                            lable={`Break Title ${i + 1}`}
                            name="breakTitle"
                            rows={1}
                            multiline={false}
                            placeHolder="Enter Break Title"
                            className="form-textfield-styles"
                            value={data.breakTitle}
                            handleChange={(e) => handleBreakTime(i, e)}
                            isValidValue={isValidValues}
                            isSubmit={isSubmit}
                            requiredMessage="Break Title is required"
                            isCheckValidRID={true}
                          />
                        </Col>
                        <Col lg="4" sm="12">
                          <InputField
                            type="time"
                            lable={`From Time ${i + 1}`}
                            name="from"
                            rows={1}
                            multiline={false}
                            placeHolder="Enter From Time"
                            className="form-textfield-styles"
                            value={data.from}
                            handleChange={(e) => handleBreakTime(i, e)}
                            isValidValue={isValidValues}
                            isSubmit={isSubmit}
                            disablePrevious={fromTime}
                            disableUpcoming={toTime}
                            requiredMessage="Break From Time is required"
                          />
                        </Col>
                        <Col lg="4" sm="12">
                          <InputField
                            type="time"
                            lable={`To Time ${i + 1}`}
                            name="to"
                            rows={1}
                            multiline={false}
                            placeHolder="Enter From Time"
                            className="form-textfield-styles"
                            value={data.to}
                            handleChange={(e) => handleBreakTime(i, e)}
                            isValidValue={isValidValues}
                            isSubmit={isSubmit}
                            disablePrevious={fromTime}
                            disableUpcoming={toTime}
                            requiredMessage="Break To Time is required"
                          />
                        </Col>
                      </Row>
                    );
                  })}
                  <Row>
                    <Col className="pt-3">
                      <div className="multi-row-add">
                        <button type="button"
                          disabled={btnDisable}
                          className="add-more"
                          onClick={handleAddFields}
                        >
                          <AddBoxIcon />
                        </button>

                        {" "}
                        {removeBtn ?
                          <button type="button" className="remove-button" onClick={handleRemoveFields}>
                            <IndeterminateCheckBoxIcon />
                          </button>
                          :
                          ""}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg="5" sm="12">
                  <Typography variant="h6" component="div">
                    Lunch Time :
                  </Typography>
                  <Row className="mt-3">
                    <Col lg="6" sm="12">
                      <InputField
                        type="time"
                        lable="From Time"
                        name={lunchFromTime}
                        rows={1}
                        multiline={false}
                        placeHolder="Enter From Time"
                        className="form-textfield-styles"
                        value={lunchFromTime}
                        handleChange={(e) => setLunchFromTime(e.target.value)}
                        isValidValue={isValidValues}
                        isSubmit={isSubmit}
                        disablePrevious={fromTime}
                        disableUpcoming={toTime}
                        requiredMessage="Lunch From Time is required"
                      />
                    </Col>
                    <Col lg="6" sm="12">
                      <InputField
                        type="time"
                        lable="To Time"
                        name={lunchToTime}
                        rows={1}
                        multiline={false}
                        placeHolder="Enter From Time"
                        className="form-textfield-styles"
                        value={lunchToTime}
                        handleChange={(e) => setLunchToTime(e.target.value)}
                        isValidValue={isValidValues}
                        isSubmit={isSubmit}
                        disablePrevious={fromTime}
                        disableUpcoming={toTime}
                        requiredMessage="Lunch To Time is required"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col lg="12" sm="12">
                  <div className={classes.button_save}>
                    <Button
                      type="button"
                      variant="contained"
                      onClick={handleCancel}
                      className={classes.saveEmailButton1}
                    >
                      Cancel
                    </Button>{" "}
                    <SaveButtonComponent
                      ButtonType="submit"
                      ButtonLabel={saveUpdate ? "Save" : "Update"}
                    />
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        ) : null}
      </Paper>

      <div className="mt-4">
        <Paper className={classes.boxBorder}>
          <Table className="communication-table" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell />
                <StyledTableCell component="th" align="left">
                  Id
                </StyledTableCell>
                <StyledTableCell align="left" component="th">
                  Working Days
                </StyledTableCell>
                <StyledTableCell align="left" component="th">
                  School Working Time
                </StyledTableCell>
                <StyledTableCell align="left" component="th">
                  Lunch Time
                </StyledTableCell>
                <StyledTableCell align="left" component="th">
                  Edit
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!settings ? (
                <TableRow>
                  <StyledTableCell align="center" colSpan={6}>
                    <Box style={{ position: 'absolute', margin: '0 auto', top: '50%', left: '50%', justifyContent: 'center' }}>
                      <CircularProgress sx={{ color: '#707070' }} />
                    </Box>
                  </StyledTableCell>
                </TableRow>
              ) : settings.data.length > 0 ? (
                settings.data.map((row: any, index: string) => <Rows key={row.id} row={row} index={index} />)
              ) : (
                <TableRow key="test1">
                  <StyledTableCell component="td" align="center" colSpan={7}>
                    No Data Found
                  </StyledTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      </div>
    </>
  );
};

export default SchoolSetting;
