import React from "react";
import { useState } from "react";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Box, } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { ROUTES } from "../../constants/routes";
import FormControl from "@mui/material/FormControl";
import MainComponent from "./MainComponent";
import {
  InputPasswordField,
  InputField,
} from "../../common-components/FormComponent/FormFieldsComponent";
import { isRequiredField } from "../../common-components/FormComponent/FormValidator";
import * as authAction from "../../reducer-store/actions/authActions";
import { toast } from "react-toastify";
import ToastMessage from "../../common-components/ToastMessage";
import { PASSWORD_UPDATED_SUCCESSFULLY } from "../../reducer-store/messages/actionMessages";


import Logo from "../../common-components/Logo/Logo";
import { styled } from '@mui/material/styles';
import useResponsive from '../../hooks/useResponsive';


const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const useStyles = makeStyles((theme) => ({
  sectionTop: {
    width: '100%',
    maxWidth: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: "#ffffff",
  },
  loginTitle: {
    fontSize: "40px",
    textAlign: "center",
    marginBottom: "40px",
    fontWeight: 700,
    color: "#000000",
    '@media (max-width: 1200px)': {
      fontSize: "32px",
    },
    '@media (max-width: 991px)': {
      fontSize: "30px",
    },
    '@media (max-width: 575px)': {
      fontSize: "26px",
    }
  },
  forgotpass: {
    fontSize: "20px",
    textAlign: "right",
    marginBottom: "20px",
    fontWeight: 600,
    color: "#000000",
  },
  forgotpass1: {
    textDecoration: "none",
    color: "black",
    "&:hover": {
      color: "#000000",
      textDecoration: "none",
    },
  },
  cardMain: {
    '@media (max-width: 991px)': {
      marginTop: "30px",
    },
  },
  logoP: {
    position: "fixed",
    '@media (max-width: 768px),(min-width: 480px)': {
      padding: "15px 30px",
    },
    '@media (max-width: 480px)': {
      padding: "15px 10px",
    }
  },
  footer: {
    position: "absolute",
    bottom: 0,
    backgroundColor: ' #000000',
    width: '100%',
    minHeight: 'auto',
    '@media (max-width: 768px)': {
      position: 'absolute',
      minHeight: 'auto',
      bottom: 0,
    },
  },
  FooterText: {
    textAlign: 'center',
    color: '#ffffff',
    margin: '15px 0px',
    '@media (max-width: 768px)': {
      margin: '15px 0px',
    },
  },
}));

const Newpassword = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, swetIsShowConfirmPassword] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isValidValues, setIsValidValues] = useState(true);
  const classes = useStyles();
  const history = useHistory();
  const { updateForgotPassword } = authAction;
  const [loaded, setLoader] = useState(false);
  const [show, setShow] = useState(false);

  const handleClickShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const handleClickShowConfirmPassowrd = () => {
    swetIsShowConfirmPassword(!isShowConfirmPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const checkPasswordFieldSave = (newPass: string, conPass: string) => {
    if (newPass !== conPass) {
      setShow(true)
      return false;
    } else {
      setShow(false)
      return true;
    };
  };

  const SubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    if (isRequiredField(password) &&
      isRequiredField(confirmPassword) &&
      checkPasswordFieldSave(password, confirmPassword)
    ) {
      setIsSubmit(false);
      const mail = localStorage.getItem("forgot-email");
      let userObj = {
        email: mail,
        password: password,
        confirm_password: confirmPassword
      }
      /* if (show === true) { */
      let resData: any = await updateForgotPassword(userObj);
      if (!resData.error) {
        toast.success(PASSWORD_UPDATED_SUCCESSFULLY);
        setTimeout(() => {
          setLoader(false);
          localStorage.clear();
          history.push(ROUTES.login);
        }, 2000);
        /*  } */
      }
      return;
    }
    setIsValidValues(false);
  };

  return (
    <MainComponent>
      <StyledRoot>
        <ToastMessage />
        <Box className={classes.logoP}>
          <Link to={ROUTES.login}><Logo size={30} /></Link>
        </Box>
        <section className={classes.sectionTop}>
          <Container className="mt-4">
            <Row className="stap-form">
              <Typography
                className={classes.loginTitle}
                variant="h5"
                component="div"
              >
                Enter new password!
              </Typography>
            </Row>
            <Row className="stap-form">
              <Col lg="6" sm="12" className=" offset-lg-3">
                <Box>
                  <Card variant="outlined">
                    <div className="Progress-bar"></div>
                    <Form onSubmit={SubmitHandler}>
                      <CardContent>
                        <FormControl variant="standard" fullWidth>
                          <InputPasswordField
                            type="password"
                            lable="New Password"
                            name={password}
                            rows={10}
                            multiline={false}
                            placeHolder="Enter password"
                            className="form-textfield-styles"
                            value={password}
                            handleChange={(e) => setPassword(e.target.value)}
                            clickToShowPassowrd={handleClickShowPassword}
                            hoverToPassword={handleMouseDownPassword}
                            showPassword={isShowPassword}
                            isValidValue={isValidValues}
                            isSubmit={isSubmit}
                            requiredMessage="Password field is required"
                            isCheckValidPassword={true}
                          />
                        </FormControl>
                        {
                          show ? <Typography style={{ color: 'red' }}>New Password and Confirm password must be same!</Typography> : null
                        }
                      </CardContent>
                      <CardContent>
                        <FormControl variant="standard" fullWidth>
                          <InputPasswordField
                            type="password"
                            lable="Confirm Password"
                            name={confirmPassword}
                            rows={10}
                            multiline={false}
                            placeHolder="Enter confirm password"
                            className="form-textfield-styles"
                            value={confirmPassword}
                            handleChange={(e) => setConfirmPassword(e.target.value)}
                            clickToShowPassowrd={handleClickShowConfirmPassowrd}
                            hoverToPassword={handleMouseDownPassword}
                            isValidValue={isValidValues}
                            isSubmit={isSubmit}
                            showPassword={isShowConfirmPassword}
                            requiredMessage="Confirm password field is required"
                            isCheckValidPassword={true}
                          />
                        </FormControl>
                        {
                          show ? <Typography style={{ color: 'red' }}>New Password and Confirm password must be same!</Typography> : null
                        }
                      </CardContent>
                      {/* <CardContent>
                      <FormControlLabel
                        name="checkbox3"
                        control={<Checkbox color="primary" />}
                        label="Remember me"
                      />
                    </CardContent> */}
                      <CardContent>
                        <div className="logbutton">
                          {/* <Button type="submit" className="btn btn-success">
                          Submit
                        </Button> */}
                          <button type="submit" className="btn btn-style">
                            Submit
                          </button>
                        </div>
                      </CardContent>
                    </Form>
                  </Card>
                </Box>
              </Col>
            </Row>
          </Container>
          <footer className={classes.footer}>
            <Container>
              <Row>
                <Col lg="12" sm="12">
                  <p className={classes.FooterText}>© 2023 Powered by Driving School Cloud.  All rights reserved.</p>
                </Col>
              </Row>
            </Container>
          </footer>
        </section>
      </StyledRoot>
    </MainComponent>
  );
};
export default Newpassword;
