import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import ReducerStore from "./reducer-store/stores";
import { composeWithDevTools } from "redux-devtools-extension";

const initialState = {};
const middlewares = [thunk];
const store = createStore(
  ReducerStore,
  initialState,
  composeWithDevTools(applyMiddleware(...middlewares))
);
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();
