import React, { useEffect, useState } from "react";
import MaskedInput from 'react-maskedinput';
import { makeStyles } from "@material-ui/core/styles";
// import Paper from "@material-ui/core/Paper";
import "../../Common.css";
import { Row, Col, Button } from "react-bootstrap";
import Typography from "@material-ui/core/Typography";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Switch from "@mui/material/Switch";
import {
    SaveButtonComponent,
} from "../../../common-components/ButtonComponent";
// import {
//     TableRow,
// } from "@material-ui/core";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
// import { AddCircle } from "@mui/icons-material";
import { isRequiredField, isValidIPAddress, isValidRegisterID } from "../../../common-components/FormComponent/FormValidator";
import * as Actions from "../../../reducer-store/actions/settingsAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
    WHITE_IP_SUCCESSFULLY_ADDED, WHITE_IP_SUCCESSFULLY_DELETED, WHITE_IP_SUCCESSFULLY_UPDATED
} from "../../../reducer-store/ToastMessage";
import { bindActionCreators } from "redux";
import { DELETE_WHITE_LIST_API, LIMIT, PAGE } from "../../../reducer-store/constants";
import moment from "moment";
import ToastMessage from "../../../common-components/ToastMessage";
import MUIDataTable from "mui-datatables";
// import { TextField } from '@material-ui/core';
// import { SearchOutlined } from '@material-ui/icons';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { FaRegEdit } from "react-icons/fa";
import { BsFillTrashFill } from 'react-icons/bs';
import { RiErrorWarningFill } from "react-icons/ri";
import axios from "axios";
import { Box } from "@material-ui/core";
import { CircularProgress } from "@mui/material";
import { InputField } from "../../../common-components/FormComponent/FormFieldsComponent";

const useStyles = makeStyles((theme) => ({
    boxBorder: {
        boxShadow: "0px 0px 0px #8e8e8e",
        backgroundColor: "#e6e6e6",
        borderRadius: "0px",
    },
    divStyling: {
        padding: "15px 20px",
    },
    switchRight: {
        textAlign: "end",
    },
    iconSpace: {
        marginRight: "20px !important",
    },
    saveEmailButton: {
        borderRadius: "40px !important",
        backgroundColor: "#00A651 !important",
        border: "1px solid #00A651 !important",
        color: "#ffffff !important",
        padding: "0 35px !important",
        height: "40px !important",
        textTransform: 'capitalize',
        fontSize: '16px !important',
        position: 'absolute',
        right: "20px",
        top: '125px',
    },
    saveEmailButton1: {
        borderRadius: "40px !important",
        backgroundColor: "#ffffff !important",
        border: "1px solid #000000 !important",
        color: "#000000 !important",
        padding: "0 35px !important",
        height: "40px !important",
        textTransform: 'capitalize',
        fontSize: "0.875rem",
    },
    ipInputField: {
        '&:focus-visible': {
            outline: 0,
        },
        borderWidth: 0,
        borderBottomWidth: 1,
        paddingBottom: "5px",
        marginBottom: "5px"
    },
    dialogContent: {
        textAlign: "center",
        display: 'flex',
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: "center"
    },
    dialogAction: {
        justifyContent: "center !important",
    },
    icon: {
        width: '30%',
        height: 'auto',
        color: "#000",
    },
    yesButton: {
        borderRadius: "40px !important",
        backgroundColor: "#00A651 !important",
        border: "1px solid #00A651 !important",
        color: "#ffffff !important",
        padding: "0 35px !important",
        height: "40px !important",
        fontFamily: 'Arial !important',
        fontSize: '15px',
        textTransform: 'capitalize'
    },
    noButton: {
        borderRadius: "40px !important",
        backgroundColor: "#ffffff !important",
        border: "1px solid #000000 !important",
        color: "#000000 !important",
        padding: "0 35px !important",
        height: "40px !important",
        textTransform: 'capitalize',
        fontSize: '15px',
    }
}));

const theme = createTheme({
    components: {
        MuiTableHead: {
            styleOverrides: {
                root: {
                    "& .css-1ygcj2i-MuiTableCell-root": {
                        fontweight: "bold",
                        backgroundColor: '#FFFFFF'
                    },
                    "& .tss-1h9t3sl-MUIDataTableHeadCell-sortAction": {
                        display: "-webkit-box"
                    },
                    "& .tss-1fbujeu-MUIDataTableHeadCell-toolButton": {
                        backgroundColor: '#FFFFFF'
                    },
                    '.tss-1akey0g-MUIDataTableHeadCell-data': {
                        fontSize: '15px'
                    },
                    "& .tss-1832qqn-MUIDataTableHeadRow-root": {
                        borderBottomColor: '#000',
                        borderBottomWidth: '1.2px',
                    },
                    "& .tss-10syd3x-MUIDataTableHeadCell-root": {
                        fontSize: '15px',
                    },
                    "& .tss-gm6zfk-MUIDataTableHeadCell-fixedHeader": {
                        borderBottomColor: "#000",
                        borderBottomWidth: "thin",
                    },
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    lineHeight: "1.30 !important"
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "Arial"
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontFamily: "Arial",
                },
                head: {
                    backgroundColor: "#FFFFFF"
                },
                footer: {
                    backgroundColor: "#FFFFFF"
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                colorInherit: {
                    backgroundColor: '#E53D12',
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    "&:nth-child(odd)": {
                        backgroundColor: "#D4D4D4"
                    },
                }
            }
        },
        MuiTableBody: {
            styleOverrides: {
                root: {
                    "& .css-1ex1afd-MuiTableCell-root": {
                        width: "13%",
                    }
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    "& .MuiTablePagination-displayedRows": {
                        marginTop: "9%"
                    },
                }
            }
        },
        MuiTableSortLabel: {
            styleOverrides: {
                icon: {
                    fill: '#FFFFFF'
                }
            }
        }
    }
});

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//     [`&.${tableCellClasses.head}`]: {
//         backgroundColor: theme.palette.common.black,
//         color: theme.palette.common.white,
//     },
//     [`&.${tableCellClasses.body}`]: {
//         fontSize: 14,
//     },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//     "&:nth-of-type(odd)": {
//         backgroundColor: theme.palette.action.hover,
//     },
//     // hide last border
//     "&:last-child td, &:last-child th": {
//         border: 0,
//     },
// }));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

// const BootstrapDialogTitle = (props: DialogTitleProps) => {
//     const { children, onClose, ...other } = props;
//     const [name, setname] = useState("");

//     return (
//         <DialogTitle sx={{ m: 0, p: 2 }} style={{ color: "#ffffff", backgroundColor: "#404040" }} {...other}>
//             {children}
//             {onClose ? (
//                 <IconButton
//                     aria-label="close"
//                     onClick={() => onClose()}
//                     sx={{
//                         position: "absolute",
//                         right: 8,
//                         top: 8,
//                         color: (theme) => theme.palette.grey[500],
//                     }}
//                 >
//                     <CloseIcon />
//                 </IconButton>
//             ) : null}
//         </DialogTitle>
//     );
// };


const WhitelistIps: React.FC = () => {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [whitelistIp, setwhitelistIp] = useState<any>("");
    const [whitelistId, setwhitelistId] = useState<any>("");
    // const [rowsPerPage, setRowsPerPage] = useState(1000);
    const [searchApiData, setSearchApiData] = useState([]);
    // const [filterVal, setFilterVal] = useState('');
    // const [order, setOrder] = useState("desc");
    // const [search, setSearch] = useState('');
    const [isSubmit, setIsSubmit] = useState(false);
    const [isValidValues, setIsValidValues] = useState(true);
    const { addWhiteListIP, updateWhiteListIP } = Actions;
    const AuthToken = useSelector((state: any) => state.auth);
    // const [page, setPage] = useState(PAGE);
    const { token } = AuthToken;
    const dispatch = useDispatch();
    const { whiteListIPLists, deleteWhiteList } = bindActionCreators(Actions, dispatch);
    const whiteIPList = useSelector((state: any) => state.setting);
    const { whiteip } = whiteIPList
    const [isChecked, setIsChecked] = useState(true)
    const [edit, setEdit] = useState(false);
    const [disable, setDisable] = useState(false);
    const [ipError, setIpError] = useState(false);
    const [openView, setOpenView] = React.useState(false);
    const [location, setLocation] = useState("");

    // const handlefilter = (e: any) => {
    //     if (e.target.value == "") {
    //         setData(searchApiData);
    //     } else {
    //         const filterData = searchApiData.filter((items: any) =>
    //             (items.name.props.children[0] + items.name.props.children[2]).toString().includes(e.target.value.toString())
    //             || items.id.toString().includes(e.target.value.toString())
    //             || items.hour.toString().includes(e.target.value.toString())
    //             || items.description.toLowerCase().includes(e.target.value.toLowerCase())
    //             || items.course.toString().includes(e.target.value.toString()));
    //         setData(filterData);

    //     }
    //     setFilterVal(e.target.value);
    // };

    useEffect(() => {
        (async () => {
            const whiteList = await whiteListIPLists(token);
            renderDataTable(whiteList);
        })();
    }, [open, openView, disable]);

    const renderDataTable = (whiteList: any) => {
        const rowsDataTable = whiteList && whiteList.data.data.map((row: any, index: string) => {
            return {
                id: index + 1,
                ip_address: row.ip_allowed,
                location: row?.location || "---",
                date_added: moment(row.created_at).format("YYYY-MM-DD"),
                enable:
                    <div>
                        <Switch color={row.status ? "success" : "primary"} onChange={(e) => handleStatus(row, e.target.checked)} checked={row.status} />
                    </div>,
                edit:
                    <div>
                        <span className={classes.iconSpace}>
                            <FaRegEdit style={{ cursor: 'pointer' }} size={20} color="#000000" onClick={() => handleEditWhitelist(row)} />
                        </span>
                        <span onClick={() => handleOpenView(row.id)}>
                            <BsFillTrashFill style={{ cursor: 'pointer' }} size={20} color="black" />
                        </span>
                    </div>
                ,
            };
        });
        setData(rowsDataTable);
        setSearchApiData(rowsDataTable);
    };

    const columns = [
        {
            label: 'Sr No',
            name: "id",
        },
        {
            label: 'IP Address',
            name: "ip_address",
        },
        {
            label: 'Location',
            name: "location",
        },
        {
            label: "Date Added",
            name: "date_added",
        },
        {
            label: 'Enabled / Disable',
            name: "enable",
        },
        {
            label: 'Action',
            name: "edit",
            options: {
                sort: false,
            }
        },
    ];

    const options = {
        search: false,
        print: false,
        viewColumns: false,
        filter: false,
        download: false,
        responsive: "vertical",
        expandableRows: false,
        expandableRowsHeader: false,
        expandableRowsOnClick: true,
        selectableRows: "none",
        rowsPerPageOptions: [],
        textLabels: {
            body: {
                noMatch: !whiteip || (whiteip?.data?.length > 0 && data?.length == 0) ?
                    <Box style={{ textAlign: 'center', margin: '0 auto', top: '50%', left: '50%', justifyContent: 'center' }}>
                        <CircularProgress sx={{ color: '#707070' }} />
                    </Box>
                    : 'Sorry, there is no matching data to display',
            },
        },
    };

    const handleClose = () => {
        setIpError(false);
        setOpen(false);
        setEdit(false);
        handleCloseView();
    };

    const handleCloseView = () => {
        setOpenView(false);
        setwhitelistId("");
        setwhitelistIp("");
        setLocation("");
        setIsValidValues(true);
    };

    const handleOpenView = (id: any) => {
        setwhitelistId(id);
        setOpenView(true);
    };

    const handleClear = () => {
        setwhitelistIp("");
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const SubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsSubmit(true);
        if (
            isRequiredField(whitelistIp) &&
            isValidIPAddress(whitelistIp) &&
            isValidRegisterID(location)
        ) {
            setIsSubmit(false);
            let userObj = {
                ip_allowed: whitelistIp,
                status: isChecked,
                location: location,
            }
            let resData: any = edit ? await updateWhiteListIP(whitelistId, userObj, token) : await addWhiteListIP(userObj, token);
            if (!resData.error) {
                toast.success(edit ? WHITE_IP_SUCCESSFULLY_UPDATED : WHITE_IP_SUCCESSFULLY_ADDED);
                setTimeout(() => {
                    whiteListIPLists(token);
                    handleClose();
                    setwhitelistId("");
                    setwhitelistIp("");
                    setIsValidValues(true);
                }, 2000);
                return;
            }
            toast.error(resData.data.errorMsg);
        }
        setIsValidValues(false);
    }

    const handleEditWhitelist = async (row: any) => {
        setEdit(true);
        setOpen(true);
        setwhitelistId(row?.id);
        setwhitelistIp(row?.ip_allowed);
        setIsChecked(row?.status);
        setLocation(row?.location)
    }

    const header = {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,GET,OPTIONS,PUT,DELETE,PATCH",
            "Access-Control-Allow-Headers": "Content-Type, X-Auth-Token, Origin, Authorization"
        },
    };

    const handleDeleteWhitelist = async (id: any) => {
        header.headers.Authorization = `Bearer ${token}`;
        // let resData: any = await deleteWhiteList(id, token);
        // if (!resData.error) {
        //     toast.success(WHITE_IP_SUCCESSFULLY_DELETED);
        //     whiteListIPLists(token)
        // }
        await axios
            .delete(
                `${DELETE_WHITE_LIST_API}/${id}`,
                header
            )
            .then((res) => {
                toast.success(WHITE_IP_SUCCESSFULLY_DELETED);
                setTimeout(() => {
                    handleCloseView()
                    return res;
                }, 2000)
            })
            .catch((error) => {
                toast.error(error.response.data.errorMsg);
                return error.response;
            });

    }

    const handleStatus = async (val: any, check: any) => {
        let userObj = {
            ip_allowed: val.ip_allowed,
            status: check
        }
        let resData: any = await updateWhiteListIP(val.id, userObj, token);
        if (!resData.error) {
            toast.success(WHITE_IP_SUCCESSFULLY_UPDATED);
            setDisable(!disable)
        }
    }

    const BootstrapDialogTitle = (props: DialogTitleProps) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} style={{ color: "#ffffff", backgroundColor: "#646464" }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={() => onClose()}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[100],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };

    const IP_PATTERN = new RegExp(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/i);

    const onChangeIp = (value: any) => {
        if (!IP_PATTERN.test(value)) {
            setIpError(true);
        } else {
            setIpError(false);
        }
    }

    const myIp = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        window.open("https://www.whatismyip.com", "_blank")
    }

    return (
        <>
            <ToastMessage />
            {/* <Paper className={classes.boxBorder}>
                <div className={classes.divStyling}>
                    <Row>
                        <Col lg="8" sm="12">
                            <Typography variant="h6" component="div">
                                Whitelist ip's
                            </Typography>
                        </Col>
                        <Col lg="4" sm="12" className={classes.switchRight}>
                            <Button
                                type="button"
                                variant="contained"
                                className={classes.saveEmailButton}
                                onClick={handleClickOpen}
                            >

                                Add whitelist ip's
                            </Button>
                        </Col>
                    </Row>
                </div>
                <hr className="hrline" />
                <Table className="communication-table" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell component="th" align="left">
                                #Id
                            </StyledTableCell>
                            <StyledTableCell component="th" align="left">
                                IP Address
                            </StyledTableCell>
                            <StyledTableCell component="th" align="left">
                                Date Added
                            </StyledTableCell>
                            <StyledTableCell component="th" align="left">
                                Enabled / Disable
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!whiteip ? (
                            <TableRow>
                                <StyledTableCell align="center" colSpan={6}>
                                    Loading...
                                </StyledTableCell>
                            </TableRow>
                        ) : whiteip.data.length > 0 ? (
                            whiteip.data.map((row: any) =>
                                <StyledTableRow>
                                    <TableCell align="left">{row.id}</TableCell>
                                    <TableCell align="left">{row.ip_allowed}</TableCell>
                                    <TableCell align="left">{moment(row.created_at).format("DD MMMM, YYYY")}</TableCell>
                                    <TableCell align="left">
                                        <div className="Switch-main">
                                            <Switch className="Switch" onChange={(e) => handleStatus(row, e.target.checked)} checked={row.status} />
                                        </div>
                                    </TableCell>
                                </StyledTableRow>
                            )
                        ) : (
                            <StyledTableRow>
                                <StyledTableCell align="center" colSpan={8}>
                                    No Data Found
                                </StyledTableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                </Table>
            </Paper> */}
            <Button
                type="button"
                variant="contained"
                className={classes.saveEmailButton}
                onClick={handleClickOpen}
            >
                Add whitelist ip's
            </Button>

            <ThemeProvider theme={theme} >
                <MUIDataTable
                    title={''}
                    data={data}
                    columns={columns}
                    options={options}
                />
            </ThemeProvider >

            <BootstrapDialog
                onClose={handleClose}
                fullWidth
                maxWidth="xs"
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <form onSubmit={SubmitHandler}>
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleClose}
                    >
                        {edit ? "Edit whitelist ip's" : "Add whitelist ip's"}
                    </BootstrapDialogTitle>

                    <DialogContent dividers>
                        <Row className='mt-2'>
                            <Col lg="12" sm="12">
                                <Row>
                                    <Col lg="6" sm="12">
                                        <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
                                            Whitelist IP
                                        </Typography>
                                    </Col>
                                    <Col lg="6" sm="12">
                                        <a style={{ float: 'right', textDecoration: 'underline' }} onClick={(e) => myIp(e)}>Find my IP</a>
                                    </Col>
                                </Row>
                                <MaskedInput
                                    mask="111.111.111.111"
                                    className={classes.ipInputField}
                                    name="ipList"
                                    size={48}
                                    placeholder={"Enter Whitelist IP"}
                                    value={whitelistIp}
                                    onChange={(e) => { setwhitelistIp(e.target.value); onChangeIp(e.target.value) }}
                                />
                                {
                                    !isValidValues && ipError && <Typography variant="caption" color="error" component="div">
                                        Please enter valid IP Address.
                                    </Typography>
                                }

                                <InputField
                                    type="text"
                                    lable="Location"
                                    name={location}
                                    rows={1}
                                    multiline={false}
                                    placeHolder="Enter Location"
                                    className="form-textfield-styles"
                                    value={location}
                                    handleChange={(e) => setLocation(e.target.value)}
                                    isCheckValidRID={true}
                                    isValidValue={isValidValues}
                                    isSubmit={isSubmit}
                                    requiredMessage="Location field is required"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12" sm="12">
                                <Typography variant="subtitle1" component="div">
                                    Enabled / Disable
                                </Typography>
                                <Switch color={isChecked ? "success" : "primary"} checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />
                            </Col>
                        </Row>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="button"
                            variant="contained"
                            onClick={handleClear}
                            className={classes.saveEmailButton1}
                        >
                            Clear
                        </Button>
                        {""}
                        <Button
                            type="button"
                            variant="contained"
                            onClick={handleClose}
                            className={classes.saveEmailButton1}
                        >
                            Cancel
                        </Button>
                        {" "}
                        <SaveButtonComponent ButtonType="submit" ButtonLabel={edit ? "Update" : "Save"} />
                    </DialogActions>
                </form>
            </BootstrapDialog >

            <BootstrapDialog
                onClose={handleCloseView}
                fullWidth
                maxWidth="sm"
                aria-labelledby="customized-dialog-title"
                open={openView}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                >
                    Delete Whitelistip
                </BootstrapDialogTitle>

                <DialogContent dividers className={classes.dialogContent} >
                    <RiErrorWarningFill color="#FDB924" size={30} style={{ marginRight: 10 }} />
                    <Typography variant="h6" component="div">
                        Are you sure you want to  delete this ip?
                    </Typography>

                </DialogContent>

                <DialogActions className={classes.dialogAction} >
                    <Button
                        type="button"
                        variant="contained"
                        className={classes.noButton}
                        onClick={handleCloseView}
                    >
                        No
                    </Button>
                    <Button
                        type="button"
                        variant="contained"
                        className={classes.yesButton}
                        onClick={() => handleDeleteWhitelist(whitelistId)}
                    >
                        Yes
                    </Button>
                </DialogActions>

            </BootstrapDialog>
        </>
    );
};

export default WhitelistIps;