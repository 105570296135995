import { ActionProps } from "../../ts";
import {
  LIST_OF_NOTIFICATION,
  LIST_OF_NOTIFICATION_ERROR,
} from "../ActionTypes";

const initialState = { notifications: null };

const notificationStore = (state: any = initialState, action: ActionProps) => {
  const { type, payload } = action;
  switch (type) {
    case LIST_OF_NOTIFICATION: {
      return {
        ...state,
        notifications: payload,
      };
    }
    case LIST_OF_NOTIFICATION_ERROR: {
      return {
        ...state,
        notifications: null,
      };
    }
    default:
      return state;
  }
};

export default notificationStore;
