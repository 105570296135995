import { Typography } from "@mui/material";
import React from "react";

type TypoProps = {
  label: string;
};

const TypographyComponent: React.FC<TypoProps> = ({ label }) => {
  return (
    <Typography
      id="modal-modal-title"
      variant="h5"
      component="h2"
      className="mt-4 mb-4"
    >
      {label}
    </Typography>
  );
};

export default TypographyComponent;
