import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { Clear } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CommonHeadPart from "../../../common-components/CommonHeadPart";
import CommunicationIcon from "../../../svg_icons/communication.svg";
import { ROUTES } from "../../../constants/routes";
import { DiscardButtonComponet, SendButtonComponet, } from "../../../common-components/ButtonComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import * as Action from "../../../reducer-store/actions/communicationAction";
import { InputField, InputSelectField, } from "../../../common-components/FormComponent/FormFieldsComponent";
import { isRequiredField } from "../../../common-components/FormComponent/FormValidator";
import { Button } from "@mui/material";
import ToastMessage from "../../../common-components/ToastMessage";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { MESSAGE_SUCCESSFULLY_SAVE, MESSAGE_SUCCESSFULLY_SENT } from "../../../reducer-store/ToastMessage";
import FieldError from "../../../common-components/FormComponent/ErrorMessage";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { BsFillCheckCircleFill } from "react-icons/bs";
import ImageComponent from "../../../common-components/ImageComponent";
import FolderIcon from "../../../svg_icons/File.png";
import { manageIconType } from "../../../reducer-store/constants";

const useStyles = makeStyles(() => ({
  saveEmailButton: {
    borderRadius: "40px !important",
    backgroundColor: "#ffffff !important",
    border: "1px solid #393939 !important",
    color: "#393939 !important",
    padding: "0 35px !important",
    height: "40px !important",
    textTransform: 'capitalize',
  },
  CardTitle: {
    backgroundColor: "#646464",
    color: "#ffffff",
  },
  icon: {
    marginRight: "20px",
  },
  button_save: {
    textAlign: "right",
  },
  dialogContent: {
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  // dialogAction: {
  //   justifyContent: "center !important",
  // },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2 }}
      style={{ color: "#ffffff", backgroundColor: "#646464" }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[100],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const ComposeMessgeForm: React.FC = (props: any) => {
  const classes = useStyles();
  const params: any = useParams();
  const [composeTo, setComposeTo] = useState("");
  const [subject, setSubject] = useState("");
  const [category, setCategory] = useState("");
  const [message, setMessage] = useState("");
  const [attachmentFile, setAttachmentFile] = useState<any>([]);
  const [attachmentFileName, setAttachmentFileName] = useState<any>([]);
  const [stylingClass, setStylingClass] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isValidValues, setIsValidValues] = useState(true);
  const AuthToken = useSelector((state: any) => state.auth);
  const { token } = AuthToken;
  const history = useHistory();
  const { saveMessage, sendMessage, GetReplyMessaage, sendDraftMessage } = Action;
  const { UpdateDraftMessaage } = Action;
  const [imgError, setImgError] = useState(false);
  const [imgErrorMsg, setImgErrorMsg] = useState("");
  const [wrongImage, setWrongImg] = useState(false);
  const [wrongImageError, setWrongImgError] = useState("")
  const [imageData, setImageData] = useState<any>([]);
  const typeArray = [
    "image/jpeg",
    "image/jpg",
    "image/png"
  ]

  const BreadCrumbComposeMessage = [
    {
      labelText: "Home",
      linkUrl: ROUTES.dashboard,
    },
    {
      labelText: "Communication",
      linkUrl: ROUTES.communication,
    },
    {
      labelText: "Compose new message",
      linkUrl: "",
    },
  ];

  useEffect(() => {
    if (params?.id?.length > 0) {
      (async () => {
        const lastReplyMessage: any = await GetReplyMessaage(params?.id, token);
        const { data } = lastReplyMessage;
        setImageData(data?.threads[0]?.MessageAttachments);
        setMessage(data?.threads[data?.threads?.length - 1]?.body)
        setSubject(data?.threads[data?.threads?.length - 1]?.subject)
        setCategory(data?.threads[data?.threads?.length - 1]?.category)
      })();
    }
  }, []);

  const handleClose = () => {
    setOpenSuccess(false);
  };

  const imageValidationCheck = (fileInfo: any) => {
    let typeImage = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/pdf",
      "application/msword",
      "text/plain",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",];

    if (fileInfo.every((x: any) => typeImage.some((y: any) => x.type === y))) {
      setImgError(false)
      return true;
    } else {
      setImgError(true)
      setImgErrorMsg("Please upload valid Document (pdf,doc,ppt,jpg,jpeg,excel,png,xlsx,xls,docx,txt,pptx,docx).");
      return false;
    }
  };

  const onChangeFile = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files;

    var imageContainer = [];
    var imageName = [];

    for (var i = 0; i < file.length; i++) {
      var fileUpload: any = file[i];
      if (fileUpload) {
        imageContainer.push(fileUpload);
        imageName.push(fileUpload?.name);
      }
    }

    if (imageValidationCheck(imageContainer)) {
      setAttachmentFile(imageContainer);
      setAttachmentFileName(imageName);
      return;
    }
    setAttachmentFile(imageContainer);
    setAttachmentFileName(imageName);
    return;
  };

  const removeSelectedAttachment = async (id: number) => {
    const newArr = await attachmentFileName.filter((files: string, index: number) => {
      return id !== index;
    });
    const newFileArr = await attachmentFile.filter((files: any, index: number) => {
      return id !== index;
    });
    setAttachmentFileName(newArr);
    setAttachmentFile(newFileArr);
    setImgError(false);
    setWrongImg(false);
  };

  const onChangeFile1 = () => {
    setWrongImg(true);
    setWrongImgError("Please Remove Unsupport File First");
  }

  const resetData = () => {
    setComposeTo("");
    setSubject("");
    setCategory("");
    setMessage("");
  };

  const manageCategory = (e: any) => {
    const str = e.target.value;
    const str2 = str.charAt(0).toUpperCase() + str.slice(1);
    setCategory(str);
    setStylingClass(str2);
  };

  const SubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    if (
      // isRequiredField(composeTo) &&
      // isValidEmail(composeTo) &&
      isRequiredField(category) &&
      isRequiredField(subject) &&
      isRequiredField(message)
    ) {
      setIsSubmit(false);
      const formData = new FormData();
      formData.append("category", category);
      formData.append("subject", subject);
      formData.append("body", message);
      formData.append("send_to", "sunilrmaheshwari@gmail.com");
      formData.append("toAdmin", "1");
      {
        attachmentFile.map((filesData: any) => {
          formData.append("files", filesData);
        })
      }
      let resData: any = params?.id ? await sendDraftMessage(formData, token, params?.id) : await sendMessage(formData, token);
      if (!resData?.error) {
        toast.success(MESSAGE_SUCCESSFULLY_SENT);
        setOpenSuccess(true);
        setTimeout(() => {
          resetData();
          setOpenSuccess(false);
          history.push(ROUTES.communication);
        }, 1500);
        return;
      }
      toast.error(params?.id ? resData?.errorMsg : resData?.errMsg);
      return;
    }
    setIsValidValues(false);
  };

  const SaveHandler = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsSubmit(true);

    if (
      // isRequiredField(composeTo) &&
      isRequiredField(category) &&
      isRequiredField(subject) &&
      isRequiredField(message)
    ) {
      setIsSubmit(false);
      const formData = new FormData();
      formData.append("category", category);
      formData.append("subject", subject);
      formData.append("body", message);
      formData.append("send_to", "sunilrmaheshwari@gmail.com");
      formData.append("toAdmin", "1");
      if (params?.id) {
        formData.append("status", "6")
      }
      {
        attachmentFile.map((filesData: any) => {
          formData.append("files", filesData);
        })
      }
      let resData: any = params?.id ? await UpdateDraftMessaage(params?.id, token, formData) : await saveMessage(formData, token);
      if (!resData?.error) {
        toast.success(MESSAGE_SUCCESSFULLY_SAVE);
        setTimeout(() => {
          resetData();
          history.push(ROUTES.communication);
        }, 1000);
        return;
      }
      toast.error(resData?.data?.errorMsg);
      return;
    }
    setIsValidValues(false);
  };

  return (
    <>
      <ToastMessage />
      <CommonHeadPart
        componentText="Communication"
        Icon={CommunicationIcon}
        buttonText=""
        urlLink=""
        BreadCrumbs={BreadCrumbComposeMessage}
      />
      <Card>
        <div className={classes.CardTitle}>
          <CardContent>
            <Typography variant="h6" component="div">
              <FontAwesomeIcon icon={faEdit as IconProp} style={{ marginRight: "5px" }} />
              Compose Message
            </Typography>
          </CardContent>
        </div>
        <CardContent>
          <form onSubmit={SubmitHandler}>
            <Row>
              <Col lg="8" sm="12">
                <InputField
                  type="text"
                  lable="To"
                  name={composeTo}
                  disabled
                  rows={1}
                  multiline={false}
                  placeHolder="Driving School Cloud Support"
                  className="form-textfield-styles"
                  value={composeTo}
                  handleChange={(e) => setComposeTo(e.target.value)}
                  isValidValue={false}
                  isSubmit={false}
                  requiredMessage="To field is required"
                // isCheckValidEmailValue={true}
                />
              </Col>
              <Col lg="4" sm="12">
                <InputSelectField
                  select={true}
                  lable="Categories"
                  name={category}
                  value={category}
                  className={`form-textfield-styles ${stylingClass}`}
                  options={[
                    { id: "", name: "Select category" },
                    { id: "sales", name: "Sales" },
                    { id: "support", name: "Support" },
                    { id: "account", name: "Account" },
                    { id: "technical", name: "Technical" },
                    { id: "other", name: "Other" },
                  ]}
                  handleChange={(e) => manageCategory(e)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Category field is required"
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg="12" sm="12">
                <InputField
                  type="text"
                  lable="Subject"
                  name={subject}
                  rows={1}
                  multiline={false}
                  placeHolder="Enter subject"
                  className="form-textfield-styles"
                  value={subject}
                  handleChange={(e) => setSubject(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Subject field is required"
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col lg="12" sm="12">
                <InputField
                  type="text"
                  lable="Message"
                  name={message}
                  rows={10}
                  multiline={true}
                  placeHolder="Enter message"
                  className="form-textfield-styles"
                  value={message}
                  handleChange={(e) => setMessage(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Message field is required"
                />
              </Col>
            </Row>
            <br />
            <Row className="mt-2">
              <Col lg="4" sm="4">
                <div className="mb-2" >
                  <label htmlFor="file" style={{ cursor: 'pointer' }}>
                    <b>Attach File</b> <AttachFileIcon className={classes.icon} />
                  </label>
                  <input
                    multiple
                    onChange={imgError === false ? (e) => onChangeFile(e) : () => onChangeFile1()}
                    type="file"
                    id="file"
                    style={{ display: "none" }}
                  />
                </div>
                {
                  attachmentFileName.map((fileName: string, index: number) => {
                    return <div>
                      {fileName}
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => removeSelectedAttachment(index)}
                      >
                        <Clear className={classes.icon} />
                      </span>
                    </div>
                  })
                }
                {
                  imgError ? <FieldError message={imgErrorMsg} /> : null
                }
                {<br />}
                {
                  wrongImage ? <FieldError message={wrongImageError} /> : null
                }
              </Col>
              <Col lg="8" sm="8" className={classes.button_save}>
                <Link to={ROUTES.communication}>
                  <DiscardButtonComponet />
                </Link>
                {" "}
                <Button
                  type="button"
                  variant="contained"
                  className={classes.saveEmailButton}
                  onClick={(e) => SaveHandler(e)}
                >
                  {params?.id ? "Save" : "Draft"}
                </Button>{" "}
                <SendButtonComponet />
              </Col>
            </Row>
          </form>
        </CardContent>
      </Card>

      {attachmentFile?.length > 0 ?
        <Card className="p-2">
          {attachmentFile?.map((data: any) => {
            return (
              <img
                className="m-1 rounded"
                src={typeArray.includes(data?.type) ? URL.createObjectURL(data) : manageIconType(data?.type)}
                width={typeArray.includes(data?.type) ? 100 : 60}
              />
            )
          })}
        </Card>
        : null}

      {params?.id && imageData?.length > 0 ?
        <Card className="p-2">
          {imageData.map((data: any) => {
            return (
              <ImageComponent signedUrl={data?.attachment_key} type={data?.attachment_type} fileName={data?.attachment_name} />
            )
          })}
        </Card>
        : null}


      <BootstrapDialog
        // onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"
        open={openSuccess}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Message Sent
        </BootstrapDialogTitle>

        <DialogContent dividers className={classes.dialogContent}>
          <BsFillCheckCircleFill color="#00A651" size={40} />
          <Typography variant="h6" component="div">
            Thank you for your message, we aim to respond all queries within 24 working hours
          </Typography>
        </DialogContent>

        {/* <DialogActions className={classes.dialogAction}>
          <Button
            type="button"
            variant="contained"
            className={classes.noButton}
          // onClick={loggedOutUser}
          >
            No
          </Button>
          <Button
            type="button"
            variant="contained"
            className={classes.yesButton}
          // onClick={handleClose}
          >
            Yes
          </Button>
        </DialogActions> */}
      </BootstrapDialog>
    </>
  );
};
export default ComposeMessgeForm;
