import { ActionProps } from "../../ts";
import { LIST_OF_PAYMENTS, LIST_OF_PAYMENTS_ERROR } from "../ActionTypes";

const initialState = { payments: null };

const dashboardStore = (state: any = initialState, action: ActionProps) => {
  const { type, payload } = action;
  switch (type) {
    case LIST_OF_PAYMENTS: {
      return {
        ...state,
        payments: payload,
      };
    }
    case LIST_OF_PAYMENTS_ERROR: {
      return {
        ...state,
        payments: null,
      };
    }
    default:
      return state;
  }
};

export default dashboardStore;