import React, { useEffect, useState } from 'react'
import { Paper } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import { Col, Row } from 'react-bootstrap'
import FormFieldComponent from "../FormFieldComponent"
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from "../../reducer-store/actions/programsAction";
import { useParams } from 'react-router-dom';
import { PAGE } from '../../reducer-store/constants';
// import Switch from "@mui/material/Switch";

const useStyles = makeStyles((theme) => ({
  divInsideMain: {
    boxShadow: "0 0 5px #8e8e8e",
    borderRadius: "3px",
    marginTop: "30px",
  },
  tabPanelStyle: {
    padding: "0 !important",
  },
  boxBorder: {
    boxShadow: "none",
    backgroundColor: "#e6e6e6",
    borderRadius: "5px",

  },
  divStyling: {
    padding: "15px 20px",
  },
  switchRight: {
    textAlign: "end",
  },
  rowStyling: {
    marginLeft: 20,
    marginTop: 10,
    marginBottom: 10,
  },
  colStyling: {
    marginTop: 10,
    marginBottom: 10,
  }
}));

const ProgramViewDetail: React.FC<any> = () => {
  const classes = useStyles();
  const params: any = useParams();
  const [viewProgramInfos, setViewProgramInfos] = useState<any>(null);
  const dispatch = useDispatch();
  const { ProgramList } = bindActionCreators(Actions, dispatch);
  const AuthToken = useSelector((state: any) => state.auth);
  const { token } = AuthToken;

  useEffect(() => {
    (async () => {
      if (params && params.id) {
        const editProgramData: any = await ProgramList("all", PAGE, 200, token, "desc", "");
        const allProgramList = editProgramData?.data;
        const filteredProgram = allProgramList?.filter((program: any, index: number) => {
          if (program.id === parseInt(params.id)) {
            return program;
          }
        })
        setViewProgramInfos(filteredProgram[0]);
      }
    })();
  }, [params]);


  return (
    <div >
      <Paper className={classes.boxBorder}>
        <Row className={classes.rowStyling} >
          <Col lg='4' sm="12" className={classes.colStyling} >
            <FormFieldComponent FormFieldLable="Program Name" FormFieldValue={viewProgramInfos?.name} />
          </Col>
          <Col lg='4' sm="12" className={classes.colStyling} >
            <FormFieldComponent FormFieldLable="Program Code" FormFieldValue={viewProgramInfos?.program_code ? viewProgramInfos?.program_code : "---"} />
          </Col>
          <Col lg='4' sm="12" className={classes.colStyling} >
            <FormFieldComponent FormFieldLable="Total hours" FormFieldValue={viewProgramInfos?.total_duration_minutes / 60} />
          </Col>
        </Row>

        <Row className={classes.rowStyling} >
          <Col lg='4' sm="12" className={classes.colStyling} >
            <FormFieldComponent FormFieldLable="Total In class Training Hours" FormFieldValue={viewProgramInfos?.total_inclass_training_minutes / 60} />
          </Col>
          <Col lg='4' sm="12" className={classes.colStyling} >
            <FormFieldComponent FormFieldLable="Total In yard Training Hours" FormFieldValue={viewProgramInfos?.total_inyard_training_minutes / 60} />
          </Col>
          <Col lg='4' sm="12" className={classes.colStyling} >
            <FormFieldComponent FormFieldLable="Total In Cab Training Hours" FormFieldValue={viewProgramInfos?.total_incab_training_minutes / 60} />
          </Col>
        </Row>

        <Row className={classes.rowStyling} >
          <Col lg='8' sm="12" className={classes.colStyling} >
            <FormFieldComponent FormFieldLable="Description" FormFieldValue={viewProgramInfos?.description} />
          </Col>
          {/* <Col lg='4' sm="12" className={classes.colStyling} >
            <div className="Switch-main">
            <FormFieldComponent 
            FormFieldLable="Enable/Disable"
            FormFieldValue=  {<Switch checked={viewProgramInfos.status ? true : false}
            className="Switch" />}/>
             
            </div>
          </Col> */}
          <Col lg='4' sm="12" className={classes.colStyling} >
            <FormFieldComponent FormFieldLable="Vehicle Type" FormFieldValue={viewProgramInfos?.vehicle_type} />
          </Col>
        </Row>

      </Paper>
    </div>
  );
};

export default ProgramViewDetail;