import { ActionProps } from "../../ts";
import {
    LIST_OF_STAFF_MANAGEMENT,
  LIST_OF_STAFF_MANAGEMENT_ERROR,
  SINGLE_STAFF_INFO,
  SINGLE_STAFF_ERROR
} from "../ActionTypes";

const initialState = { staffmangement: null };

const staffmanagementStore = (state: any = initialState, action: ActionProps) => {
  const { type, payload } = action;
  switch (type) {
    case LIST_OF_STAFF_MANAGEMENT: {
      return {
        ...state,
        staffmangement: payload,
        staff_data: null,
      };
    }
    case LIST_OF_STAFF_MANAGEMENT_ERROR: {
      return {
        ...state,
        staffmangement: null,
        staff_data: null,
      };
    }
    case SINGLE_STAFF_INFO: {
      return {
        ...state,
        staff_data: payload,
      };
    }
    case SINGLE_STAFF_ERROR: {
      return {
        ...state,
        staff_data: null,
      };
    }
    default:
      return state;
  }
};

export default staffmanagementStore;