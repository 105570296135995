import React, { useEffect } from "react";
import "../Common.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { makeStyles } from "@material-ui/core/styles";
import CommonHeadPart from '../../common-components/CommonHeadPart';
import DashboardIcon from "../../svg_icons/dashboard.svg";
// import TotalStudenticon from "../../svg_icons/TotalStudent.svg"
import { ROUTES } from '../../constants/routes';
import { Row, Col } from "react-bootstrap";
import Card from "@material-ui/core/Card";
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Divider from '@mui/material/Divider';
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../../reducer-store/actions/dashboardAction";
import ToastMessage from "../../common-components/ToastMessage";
import { Box } from "@material-ui/core";
import { CircularProgress } from "@mui/material";
import * as ComunicationAction from "../../reducer-store/actions/communicationAction";

const useStyles = makeStyles(() => ({
  chardCard: {
    marginTop: "20px",
  },
  dashCard: {
    position: "relative",
    minHeight: "200px",
    marginBottom: "20px"
  },
  divline: {
    position: "absolute",
    height: "3px !important",
    borderStyle: "none !important",
    opacity: "1 !important",
    backgroundColor: "#feba26",
    width: "100%",
    bottom: "70px"
  },
  cardCount: {
    textAlign: "center",
    fontWeight: 700,
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  boldTitle: {
    font: "normal normal bold 18px/21px Arial"
  },
  iconTitle: {
    alignItems: "center !important",
  },
  cardTitle: {
    // font: "normal normal bold 18px/21px Arial",
    fontSize: "21px !important",
    fontWeight: 600
  },
  countCenter: {
    position: "absolute",
    bottom: "0px",
    // left: "40%"
  },
}));

const BreadCrumbDashboard = [{
  labelText: "Home",
  linkUrl: ROUTES.home
}, {
  labelText: "Dashboard",
  linkUrl: ""
}]

interface DashboardProps {
  info: {
    totalStudents: number;
    totalPayment: number;
    totalCourse: number;
    studentRegisterThisMonth: number;
    paymentThisMonth: number;
    activeAssets: number;
  }
}

const Dashboard: React.FC = () => {
  const classes = useStyles();
  const AuthToken = useSelector((state: any) => state.auth);
  const { token } = AuthToken;
  const dashboardInfo: DashboardProps = useSelector((state: any) => state.dashboard);
  const { info } = dashboardInfo;
  const dispatch = useDispatch();
  const { DashboardData } = bindActionCreators(Actions, dispatch);

  useEffect(() => {
    (async () => {
      await DashboardData(token);
    })();
  }, []);

  return (
    <>
      <ToastMessage />
      <CommonHeadPart
        componentText="Dashboard"
        Icon={DashboardIcon}
        buttonText=""
        urlLink="/"
        BreadCrumbs={BreadCrumbDashboard}
      />
      <>
        <div>
          {!info ? (
            <Box style={{ position: 'absolute', margin: '0 auto', top: '50%', left: '50%', justifyContent: 'center' }}>
              <CircularProgress sx={{ color: '#707070' }} />
            </Box>
          ) : info ?
            (
              <Row>
                <Col lg="4" sm="6">
                  <Card className={classes.dashCard}>
                    <CardHeader
                      className={classes.iconTitle}
                      avatar={
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 30">
                          <g transform="translate(-377.495 -351.289)">
                            <path className="a" fill="#fb9b00" d="M415.472,365.9c.565,1.706,1.556,2.875,3.169,2.875s2.6-1.169,3.171-2.875c.492.039.837-.53,1.064-1.28.343-1.141-.024-1.549-.516-1.691V362.9l0,0a7.353,7.353,0,0,0-.1-2.53.835.835,0,0,0-.68-.643,3.537,3.537,0,0,0-2.433-1.4c-3.3-.78-3.339,1.326-3.339,1.326a.734.734,0,0,0-.893.743,17.63,17.63,0,0,0,.005,2.507v0a.269.269,0,0,1,0,.028c-.491.142-.861.55-.515,1.691C414.633,365.372,414.978,365.941,415.472,365.9Z" />
                            <path className="a" fill="#fb9b00" d="M383.178,365.9c.566,1.706,1.557,2.875,3.17,2.875s2.605-1.169,3.17-2.875c.494.039.839-.53,1.065-1.28.345-1.141-.025-1.549-.515-1.691a.269.269,0,0,1,0-.028l0,0a17.587,17.587,0,0,0,0-2.507.733.733,0,0,0-.892-.743s-.04-2.106-3.339-1.326a3.531,3.531,0,0,0-2.433,1.4.834.834,0,0,0-.681.643,7.344,7.344,0,0,0-.1,2.53l0,0v.028c-.491.142-.859.55-.516,1.691C382.34,365.372,382.684,365.941,383.178,365.9Z" />
                            <path className="a" fill="#fb9b00" d="M406.8,368.074l-2.438,8.478-1.16-6.045,1.079-2.433H400.7l1.08,2.433-1.161,6.045-2.437-8.478-5.99,2.2c-.425,1.057-1.753,9.675-1.753,9.675a64.737,64.737,0,0,0,12.053,1.341c7.945,0,12.051-1.341,12.051-1.341s-1.326-8.618-1.753-9.675Z" />
                            <path className="a" fill="#fb9b00" d="M389.609,380.04l.034-.221c.183-1.193.982-6.32,1.507-8.73l-1.635-.6-1.792,6.227-.853-4.44.793-1.787h-2.628l.79,1.787-.852,4.44-1.789-6.227-4.4,1.614c-.313.776-1.287,7.106-1.287,7.106a31.308,31.308,0,0,0,8.853.988C387.452,380.2,388.563,380.135,389.609,380.04Z" />
                            <path className="a" fill="#fb9b00" d="M426.209,372.1l-4.4-1.614-1.79,6.227-.853-4.44.791-1.787h-2.629l.794,1.787-.855,4.44-1.789-6.227-1.634.6c.524,2.412,1.321,7.537,1.507,8.73l.034.221c1.044.1,2.154.158,3.258.158a31.312,31.312,0,0,0,8.854-.988S426.52,372.88,426.209,372.1Z" />
                            <path className="a" fill="#fb9b00" d="M398.181,361.832c.769,2.32,2.118,3.912,4.314,3.912s3.544-1.592,4.316-3.912c.671.052,1.142-.723,1.449-1.745.468-1.553-.032-2.107-.7-2.3,0-.012,0-.025,0-.038l0,0a9.947,9.947,0,0,0-.14-3.442,1.137,1.137,0,0,0-.928-.874c-.687-1.3-2.4-1.695-3.307-1.909-4.493-1.061-4.547,1.8-4.547,1.8l0,0h0v0s-1.07-.247-1.215,1.015a24.359,24.359,0,0,0,0,3.407l0,0c0,.013,0,.026,0,.038-.671.194-1.171.748-.7,2.3C397.039,361.109,397.508,361.884,398.181,361.832Z" />
                          </g>
                        </svg>
                      }
                      title={
                        <Typography className={classes.cardTitle}>
                          Total Students
                        </Typography>
                      }
                    />
                    <Divider color="#feba26" className={classes.divline} />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CardActions className={classes.countCenter}>
                        <Typography
                          variant="h4"
                          component="div"
                          className={classes.cardCount}
                        >
                          {" "}
                          {info?.totalStudents}
                        </Typography>
                      </CardActions>
                    </div>
                  </Card>
                </Col>

                <Col lg="4" sm="6">
                  <Card className={classes.dashCard}>
                    <CardHeader
                      className={classes.iconTitle}
                      avatar={
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 42.122 41.495">
                          <g transform="translate(-92.573 -51.422)">
                            <rect className="a" fill="#fb9b00" width="42.122" height="41.495" rx="1.172" transform="translate(92.573 51.422)" />
                            <path className="b" fill="#ffffff" d="M100.936,64.489l-2.285-.712a16.206,16.206,0,0,1,10.085-8.214,16.46,16.46,0,0,1,14.1,2.484l1.565-1.366-.2,4.62-4.646-.124,1.565-1.462a14.076,14.076,0,0,0-11.6-1.991A14.6,14.6,0,0,0,100.936,64.489Z" />
                            <path className="b" fill="#ffffff" d="M125.643,64.456l1.813-1.564a17.722,17.722,0,0,1,1.64,12.9,16.462,16.462,0,0,1-9.565,10.656l.35,1.777-3.85-2.509,2.41-3.726.58,2.121a14.078,14.078,0,0,0,7.824-8.793A14.6,14.6,0,0,0,125.643,64.456Z" />
                            <path className="b" fill="#ffffff" d="M113.274,85.537l.53,2.219s-7.718,1.093-13.613-6.392a16.541,16.541,0,0,1-3.279-12.039l-2.066-.746,4.04-2.105,2.131,3.968-1.868-.513s-.382,5.631,2.574,9.63A13.967,13.967,0,0,0,113.274,85.537Z" />
                            <path className="b" fill="#ffffff" d="M108.4,63.512l2.782-1.143-.232-1.871a10.071,10.071,0,0,1,4.571,0l-.265,1.788,2.765,1.226,1.193-1.457a9.237,9.237,0,0,1,3.2,3.27l-1.366.994,1.093,2.832,1.838-.174a10.962,10.962,0,0,1,0,4.546l-1.789-.373-1.217,2.906,1.416,1.087a7.606,7.606,0,0,1-3.155,3.26l-1.093-1.366-2.807,1.118.174,1.739a7.887,7.887,0,0,1-4.546,0l.249-1.689L108.4,78.913l-1.168,1.466s-2.459-1.528-3.2-3.236l1.416-.987L104.325,73.3l-1.857.224a13.821,13.821,0,0,1,0-4.571l1.857.174,1.2-2.758-1.54-1.092a12.362,12.362,0,0,1,3.279-3.255Z" />
                            <path className="a" fill="#fb9b00" d="M113.194,64.489a6.814,6.814,0,1,0,6.815,6.814A6.814,6.814,0,0,0,113.194,64.489Z" />
                            <path className="c" fill="#e64934" d="M115.224,74.274a2.576,2.576,0,0,1-1.629.813v1h-.775v-.973a2.487,2.487,0,0,1-1.5-.69,2.839,2.839,0,0,1-.737-1.631l1.395-.15a1.792,1.792,0,0,0,.321.738,1.459,1.459,0,0,0,.524.449V71.59a2.955,2.955,0,0,1-1.537-.906,2.276,2.276,0,0,1-.489-1.474,2.081,2.081,0,0,1,.553-1.472,2.23,2.23,0,0,1,1.473-.688v-.529h.775v.529a2.29,2.29,0,0,1,1.353.581,2.237,2.237,0,0,1,.642,1.28l-1.353.176a1.086,1.086,0,0,0-.642-.855v2.09a3.461,3.461,0,0,1,1.749.9,2.134,2.134,0,0,1,.466,1.42A2.383,2.383,0,0,1,115.224,74.274Z" />
                            <path className="a" fill="#e64934" d="M112.13,69.146a.9.9,0,0,0,.172.527,1.171,1.171,0,0,0,.518.392V68.216a1,1,0,0,0-.5.363A.941.941,0,0,0,112.13,69.146Z" />
                            <path className="a" fill="#e64934" d="M113.6,71.82V73.9a1.09,1.09,0,0,0,.653-.372,1.045,1.045,0,0,0,.251-.7.953.953,0,0,0-.211-.618A1.346,1.346,0,0,0,113.6,71.82Z" />
                          </g>
                        </svg>
                      }
                      title={
                        <Typography className={classes.cardTitle}>
                          Active Assets
                        </Typography>
                      }
                    />
                    <Divider color="#feba26" className={classes.divline} />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CardActions className={classes.countCenter}>
                        <Typography
                          variant="h4"
                          component="div"
                          className={classes.cardCount}
                        >
                          {" "}
                          {info?.activeAssets}
                        </Typography>
                      </CardActions>
                    </div>
                  </Card>
                </Col>

                <Col lg="4" sm="6">
                  <Card className={classes.dashCard}>
                    <CardHeader
                      className={classes.iconTitle}
                      avatar={
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 41.311 41.31">
                          <g transform="translate(-148.9 -51.791)">
                            <path fill="#f8ad19" className="a" d="M154.039,60.4H148.9s.065-6.606,6.044-8.287a9.2,9.2,0,0,1,2.483-.327h21.652a5.841,5.841,0,0,1,5.837,5.109,8.376,8.376,0,0,1,.093,1.295V81.017h-3.4V58.044a2.779,2.779,0,0,0-1.92-2.665,3.639,3.639,0,0,0-1.2-.192l-22.621-.026a4.652,4.652,0,0,1,1.65,3.411l.046,28.655s-.052,2.348,2.166,2.061c0,0,2.217-.157,3.261-4.8l27.223.013s0,.192-.031.52a8.934,8.934,0,0,1-8.909,8.081H159.324a5.335,5.335,0,0,1-2.876-.817,5.2,5.2,0,0,1-1.711-1.8,5.59,5.59,0,0,1-.724-2.8Z" />
                            <rect fill="#f8ad19" className="a" width="13.815" height="1.722" transform="translate(162.662 70.724)" />
                            <rect fill="#f8ad19" className="a" width="10.273" height="1.722" transform="translate(164.433 74.241)" />
                            <rect fill="#f8ad19" className="a" width="13.815" height="1.722" transform="translate(162.662 77.593)" />
                            <path fill="#e63b24" className="b" d="M169.9,59.023l.079.026a3.957,3.957,0,0,1,1.907,1.368l.151.2a3.948,3.948,0,0,1,.8,2.272v.027a3.953,3.953,0,0,1-.79,2.486l-.012.016a3.951,3.951,0,0,1-2.151,1.45l-.225.06a3.966,3.966,0,0,1-2.016,0l-.3-.078a3.956,3.956,0,0,1-2.1-1.374l-.007-.01a3.953,3.953,0,0,1-.842-2.734h0a3.959,3.959,0,0,1,.726-2.015l.131-.183a3.955,3.955,0,0,1,1.946-1.447l.179-.061A3.957,3.957,0,0,1,169.9,59.023Z" transform="translate(0.911 0.888)" />
                          </g>
                        </svg>
                      }
                      title={
                        <Typography className={classes.cardTitle}>
                          Total Courses
                        </Typography>
                      }
                    />
                    <Divider color="#feba26" className={classes.divline} />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CardActions className={classes.countCenter}>
                        <Typography
                          variant="h4"
                          component="div"
                          className={classes.cardCount}
                        >
                          {" "}
                          {info?.totalCourse}
                        </Typography>
                      </CardActions>
                    </div>
                  </Card>
                </Col>

                <Col lg="4" sm="6">
                  <Card className={classes.dashCard}>
                    <CardHeader
                      className={classes.iconTitle}
                      avatar={
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 39.508 42.025">
                          <g transform="translate(-311.048 -51.151)">
                            <path fill="#f8ad19" className="a" d="M334.874,52.089h-21.8s-2.022.352-2.022,1.876V91.506a1.756,1.756,0,0,0,1.8,1.67h26.772a1.842,1.842,0,0,0,1.582-1.758v-20.4s-7.033.264-9.935-7.913C331.269,63.108,329.467,55.972,334.874,52.089Z" />
                            <circle fill="#f8ad19" className="a" cx="9.144" cy="9.144" r="9.144" transform="translate(332.269 51.151)" />
                            <rect fill="#ffffff" className="b" width="5.158" height="5.568" rx="2.579" transform="translate(318.492 74.479)" />
                            <rect fill="#ffffff" className="b" width="5.158" height="5.568" rx="2.579" transform="translate(318.492 83.388)" />
                            <rect fill="#ffffff" className="b" width="11.965" height="1.443" rx="0.722" transform="translate(325.37 83.776)" />
                            <rect fill="#ffffff" className="b" width="11.965" height="1.443" rx="0.722" transform="translate(325.37 87.073)" />
                            <rect fill="#ffffff" className="b" width="11.965" height="1.443" rx="0.722" transform="translate(325.37 75.009)" />
                            <rect fill="#ffffff" className="b" width="11.965" height="1.443" rx="0.722" transform="translate(325.37 78.306)" />
                            <path fill="#ffffff" className="b" d="M316.059,59.064a2.867,2.867,0,0,1,0-4.264,3.39,3.39,0,0,1,4.22,0,2.878,2.878,0,0,1,0,4.264,4.831,4.831,0,0,1,1.891,2.594v2.637h-8.133V61.833A3.707,3.707,0,0,1,316.059,59.064Z" />
                            <circle fill="#e63b24" className="c" cx="1.462" cy="1.462" r="1.462" transform="translate(316.642 55.448)" />
                            <path fill="#e63b24" className="c" d="M315.356,62.866H320.7a2.484,2.484,0,0,0-2.671-2.835A2.568,2.568,0,0,0,315.356,62.866Z" />
                            <circle fill="#ffffff" className="b" cx="5.845" cy="5.845" r="5.845" transform="translate(335.568 54.449)" />
                            <path fill="#e63b24" className="c" d="M340.433,61.852l1.772,1.681a.8.8,0,0,0,1.118-.011l3.9-3.851a.8.8,0,0,0,0-1.141L347.2,58.5a.8.8,0,0,0-1.151.015l-2.662,2.809a.8.8,0,0,1-1.137.03l-.7-.67a.8.8,0,0,0-1.1-.008h0A.8.8,0,0,0,340.433,61.852Z" transform="translate(-2.847 -0.704)" />
                          </g>
                        </svg>
                      }
                      title={
                        <Typography className={classes.cardTitle}>
                          New Student Registrations this Month
                        </Typography>
                      }
                    />
                    <Divider color="#feba26" className={classes.divline} />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CardActions className={classes.countCenter}>
                        <Typography
                          variant="h4"
                          component="div"
                          className={classes.cardCount}
                        >
                          {" "}
                          {info?.studentRegisterThisMonth}
                        </Typography>
                      </CardActions>
                    </div>
                  </Card>
                </Col>

                <Col lg="4" sm="6">
                  <Card className={classes.dashCard}>
                    <CardHeader
                      className={classes.iconTitle}
                      avatar={
                        <svg xmlns="http://www.w3.org/2000/svg" width="62.971" height="50" viewBox="0 0 62.971 41.027">
                          <g transform="translate(-240.466 -51.89)">
                            <path fill="#e53b24" className="a" d="M256.967,51.89a2.582,2.582,0,0,1,2.582,2.582v4.939a2.582,2.582,0,0,1-2.582,2.582h0a2.582,2.582,0,0,1-2.582-2.582V54.472a2.582,2.582,0,0,1,2.582-2.582" />
                            <path fill="#e53b24" className="a" d="M278.781,51.89a2.582,2.582,0,0,1,2.581,2.582v4.939a2.582,2.582,0,0,1-2.581,2.582h0a2.582,2.582,0,0,1-2.582-2.582V54.472a2.582,2.582,0,0,1,2.582-2.582" />
                            <path fill="#f8ad19" className="b" d="M247.426,64.462V59.748a2.572,2.572,0,0,1,2.245-2.32h3.1v2.32a4.277,4.277,0,0,0,4.191,3.816,4.333,4.333,0,0,0,4.125-3.872V57.465l13.461-.037v2.189a4.176,4.176,0,0,0,8.325.15V57.428H286.1a2.541,2.541,0,0,1,2.1,2.282v4.752Z" />
                            <circle fill="#f8ad19" className="b" cx="6.959" cy="6.959" r="6.959" transform="translate(240.466 78.007)" />
                            <path fill="#f8ad19" className="b" d="M250.307,76.884a11.4,11.4,0,0,0-2.881-.486V66.034h40.765v8.924H276.2a3.167,3.167,0,0,0-2.254,1.122l-.983-.028s-.523.168-.533.5L272.4,80.86s.131.524.515.533l.018,2.17s-.495.225-.505.384l-.018,4.406s.084.5.486.514l-.019,4.05H253.113V91.5a7.3,7.3,0,0,0,1.946-2.545.759.759,0,0,0,.842-.617l-.019-1.946a7.819,7.819,0,0,0-.711-4.92s.692-.094.692-.58l.038-4.153s-.075-.618-.524-.618l-4.565-.018S250.307,76.155,250.307,76.884Z" />
                            <rect fill="#ffffff" className="c" width="5.575" height="5.276" rx="1" transform="translate(250.307 68.615)" />
                            <rect fill="#ffffff" className="c" width="5.575" height="5.276" rx="1" transform="translate(257.622 68.615)" />
                            <rect fill="#ffffff" className="c" width="5.575" height="5.276" rx="1" transform="translate(265.021 68.615)" />
                            <rect fill="#ffffff" className="c" width="5.575" height="5.276" rx="1" transform="translate(272.42 68.615)" />
                            <rect fill="#ffffff" className="c" width="5.575" height="5.276" rx="1" transform="translate(279.828 68.615)" />
                            <rect fill="#ffffff" className="c" width="5.575" height="5.276" rx="1" transform="translate(257.622 76.024)" />
                            <rect fill="#ffffff" className="c" width="5.575" height="5.276" rx="1" transform="translate(265.021 76.024)" />
                            <rect fill="#ffffff" className="c" width="5.575" height="5.276" rx="1" transform="translate(257.622 83.432)" />
                            <rect fill="#ffffff" className="c" width="5.575" height="5.276" rx="1" transform="translate(265.021 83.432)" />
                            <path fill="#f8ad19" className="b" d="M274.44,80.4h23.722V78.231a2,2,0,0,0-1.8-1.8H276.124s-1.646.487-1.684,1.8Z" />
                            <rect fill="#f8ad19" className="b" width="23.722" height="8.381" transform="translate(274.44 83.133)" />
                            <rect fill="#e63b24" className="a" width="3.18" height="1.609" rx="0.804" transform="translate(276.947 86.762)" />
                            <rect fill="#e63b24" className="a" width="4.06" height="1.609" rx="0.804" transform="translate(280.998 86.762)" />
                            <rect fill="#e63b24" className="a" width="3.18" height="1.609" rx="0.804" transform="translate(285.789 86.762)" />
                            <path fill="#e63b24" className="a" d="M291.989,86.089a2.331,2.331,0,0,0,0,3.087,1.686,1.686,0,0,1-1.486-.662,1.649,1.649,0,0,1-.109-1.762A1.4,1.4,0,0,1,291.989,86.089Z" />
                            <circle fill="#e63b24" className="a" cx="1.557" cy="1.557" r="1.557" transform="translate(292.152 86.009)" />
                            <rect fill="#f8ad19" className="b" width="62.579" height="1.403" rx="0.702" transform="translate(240.859 91.514)" />
                            <path fill="#ffffff" className="c" d="M247.489,81.09a3.828,3.828,0,1,0,3.828,3.828A3.828,3.828,0,0,0,247.489,81.09Z" />
                            <path fill="#e63b24" className="a" d="M248.629,86.587a1.445,1.445,0,0,1-.915.456v.562h-.435v-.547a1.394,1.394,0,0,1-.845-.387,1.6,1.6,0,0,1-.414-.916l.784-.084a1,1,0,0,0,.18.414.832.832,0,0,0,.3.252V85.079a1.659,1.659,0,0,1-.864-.509,1.277,1.277,0,0,1-.274-.828,1.17,1.17,0,0,1,.31-.827,1.249,1.249,0,0,1,.828-.386v-.3h.435v.3a1.282,1.282,0,0,1,.76.326,1.258,1.258,0,0,1,.361.719l-.76.1a.609.609,0,0,0-.361-.48v1.174a1.936,1.936,0,0,1,.982.506,1.193,1.193,0,0,1,.262.8A1.338,1.338,0,0,1,248.629,86.587Z" />
                            <path fill="#f8ad19" className="c" d="M246.891,83.706a.5.5,0,0,0,.1.3.661.661,0,0,0,.292.22V83.184a.566.566,0,0,0-.283.2A.527.527,0,0,0,246.891,83.706Z" />
                            <path fill="#f8ad19" className="c" d="M247.714,85.208v1.171a.616.616,0,0,0,.367-.208.588.588,0,0,0,.141-.392.533.533,0,0,0-.119-.347A.749.749,0,0,0,247.714,85.208Z" />
                          </g>
                        </svg>
                      }
                      title={
                        <Typography className={classes.cardTitle}>
                          Payments this Month
                        </Typography>
                      }
                    />
                    <Divider color="#feba26" className={classes.divline} />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CardActions className={classes.countCenter}>
                        <Typography
                          variant="h4"
                          component="div"
                          className={classes.cardCount}
                        >
                          {" "}
                           {`CAD ${info?.paymentThisMonth}`}
                        </Typography>
                      </CardActions>
                    </div>
                  </Card>
                </Col>

                <Col lg="4" sm="6">
                  <Card className={classes.dashCard}>
                    <CardHeader
                      className={classes.iconTitle}
                      avatar={
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 40.261 40.262">
                          <g transform="translate(-192.707 -53.503)">
                            <path fill="#f8ad19" className="a" d="M192.707,87.077h15.057a8.529,8.529,0,0,0,7.986,6.688H198.8S193.623,93.573,192.707,87.077Z" />
                            <path fill="#f8ad19" className="a" d="M226.18,53.5v8.363h5.96a.519.519,0,0,0,.431-.133,1.014,1.014,0,0,0,.4-.854C232.911,59.34,232.289,53.777,226.18,53.5Z" />
                            <path fill="#f8ad19" className="a" d="M224.531,53.5H206.045a4.833,4.833,0,0,0-4.551,3.054,5.932,5.932,0,0,0-.445,2.291v26.52h7.43a1.228,1.228,0,0,1,.544.12.636.636,0,0,1,.4.546,6.3,6.3,0,0,0,5.323,5.918,2.3,2.3,0,0,0,.43.041h5.692a.843.843,0,0,0,.432-.118,6.987,6.987,0,0,0,3.216-4.715.859.859,0,0,0,.015-.167Z" />
                            <path fill="#ffffff" className="b" d="M212.863,58.923a5.819,5.819,0,1,0,5.818,5.819A5.819,5.819,0,0,0,212.863,58.923Z" />
                            <path fill="#e53b24" className="c" d="M214.6,67.278a2.2,2.2,0,0,1-1.391.694v.854h-.662V68a2.12,2.12,0,0,1-1.283-.589,2.427,2.427,0,0,1-.63-1.392l1.192-.128a1.527,1.527,0,0,0,.274.63,1.254,1.254,0,0,0,.447.383V64.987a2.522,2.522,0,0,1-1.312-.774,1.94,1.94,0,0,1-.418-1.258,1.781,1.781,0,0,1,.472-1.258,1.9,1.9,0,0,1,1.258-.587v-.452h.662v.452a1.956,1.956,0,0,1,1.155.5,1.92,1.92,0,0,1,.549,1.093l-1.156.15a.93.93,0,0,0-.548-.73V63.9a2.948,2.948,0,0,1,1.493.77,1.818,1.818,0,0,1,.4,1.212A2.032,2.032,0,0,1,214.6,67.278Z" />
                            <path fill="#ffffff" className="b" d="M211.954,62.9a.764.764,0,0,0,.146.449,1.006,1.006,0,0,0,.443.336V62.106a.851.851,0,0,0-.429.31A.8.8,0,0,0,211.954,62.9Z" />
                            <path fill="#ffffff" className="b" d="M213.205,65.183v1.78a.927.927,0,0,0,.557-.317.9.9,0,0,0,.215-.6.813.813,0,0,0-.18-.527A1.155,1.155,0,0,0,213.205,65.183Z" />
                            <rect fill="#ffffff" className="b" width="18.833" height="1.026" rx="0.513" transform="translate(203.446 75.28)" />
                            <rect fill="#ffffff" className="b" width="18.833" height="1.026" rx="0.513" transform="translate(203.446 77.191)" />
                            <rect fill="#ffffff" className="b" width="18.833" height="1.026" rx="0.513" transform="translate(203.446 79.102)" />
                            <rect fill="#ffffff" className="b" width="18.833" height="1.026" rx="0.513" transform="translate(203.446 81.013)" />
                          </g>
                        </svg>
                      }
                      title={
                        <Typography className={classes.cardTitle}>
                          Total Payment
                        </Typography>
                      }
                    />
                    <Divider color="#feba26" className={classes.divline} />
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <CardActions className={classes.countCenter}>
                        <Typography
                          variant="h4"
                          component="div"
                          className={classes.cardCount}
                        >
                          {" "}
                           {`CAD ${info?.totalPayment}`}
                        </Typography>
                      </CardActions>
                    </div>
                  </Card>
                </Col>

              </Row>
            ) : (
              <Box style={{ position: 'absolute', margin: '0 auto', top: '50%', left: '50%', justifyContent: 'center' }}>
                <p>{"No Data Found"} </p>
              </Box>
            )}
        </div>
      </>
    </>
  );
};

export default Dashboard;
