import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import { fontSize } from "@mui/system";
import NotificationMessage from "../common-components/NotificationMessage";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  closeicon: {
    textAlign: "right",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px 0",
    backgroundColor: "#00A651",
    fontSize: "18px",
    letterSpacing: "0.07px",
    color: "#fff",
    height: "50px",
  },
  allNotifications: {
    font: 'normal normal bold 18px / 21px Arial',
    letterSpacing: '0.07px',
  },
  box: {
    width: "250px",
    margin: "0px auto",
    textAlign: "center",
  },
  img: {
    width: "100px",
    padding: "30px 0",
  },
  button: {
    marginBottom: "10px",
    background: "#4a90e2",
    width: "219px",
    borderRadius: 2,
    "&:hover": {
      backgroundColor: "#4a90e2",
    },
  },
  typography: {
    color: "#091e42",
    fontSize: 14,
    fontWeight: 400,
  },
  notificationHead: {
    justifyContent: "space-between",
    display: "flex"
  },
  notificationTitle: {
    color: "#000",
    fontSize: "16px"
  },
  notificationTime: {
    color: "#888888",
    fontSize: "12px"
  },
  notificationMsg: {
    color: "#888888",
    fontSize: "12px"
  }
}));

interface SidebarPanelProps {
  handleDrawerClose: () => void;
  notification: {
    data: NotificationProps[]
  }
};

interface NotificationProps {
  messages: string;
  id: string;
  created_at: string
}

const SidebarRegister: React.FC<SidebarPanelProps> = ({
  // handleDrawerClose,
  notification
}) => {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.closeicon}>
        <Typography variant="h6" className={classes.allNotifications}>All Notifications</Typography>
        {/* <IconButton style={{ color: "#fff" }}>
          <CloseIcon onClick={handleDrawerClose} />
        </IconButton> */}
      </div>
      <ul style={{ listStyleType: "none", padding: "0", marginBottom: "0" }}>
        {
          notification && notification.data.length > 0 ? notification.data.map((notification, index) => {
            return <NotificationMessage
              key={`notification_${index}`}
              title="Notification"
              time={moment(new Date(notification.created_at)).startOf('minute').fromNow()}
              content={notification.messages} />
          }) : <li style={{ padding: "10px 15px", borderBottom: "3px solid #CBCBCB", textAlign: 'left' }}>
            <span >No Notifcations Found</span>
          </li>
        }
      </ul>
    </div>
  );
};

export { SidebarRegister };
