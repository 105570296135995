import {
  LOGIN_SUCCESS,
  OTP_SENT,
  OTP_VERIFY,
  LOGIN_FAIL,
  USER_LOADING_ERROR,
  USER_LOADING_SUCCESS,
  USER_LOGOUT,
  SELECT_OTP_PLATFORM,
  GET_SCHOOL_UPDATED_DATA,
  GET_SCHOOL_UPDATED_DATA_ERROR,
  TAX_DETAILS,
  TAX_DETAILS_ERROR,
  PLANLIST,
  PLANLIST_FAIL,
} from "../ActionTypes";
const userData = sessionStorage.getItem("user");
const isAuthenticatedData = sessionStorage.getItem("isAuthenticated");
const isVerifiedData = sessionStorage.getItem("isVerified");

const initialState = {
  token: sessionStorage.getItem("token") || "",
  isAuthenticated: isAuthenticatedData
    ? JSON.parse(isAuthenticatedData)
    : false,
  loading: true,
  user: userData ? JSON.parse(userData) : null,
  isVerified: isVerifiedData ? JSON.parse(isVerifiedData) : false,
  setectedValue: sessionStorage.getItem("setectedValue") || "email",
  taxDetails: null,
  planData: null,
};

const authStore = (state = initialState, action: any = {}) => {
  const { type, payload, otpType } = action;
  switch (type) {
    case LOGIN_SUCCESS: {
      sessionStorage.setItem("isAuthenticated", "false");
      sessionStorage.setItem("isVerified", "false");
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        isVerified: false,
        setectedValue: null,
      };
    }
    case SELECT_OTP_PLATFORM: {
      sessionStorage.setItem("setectedValue", payload);
      return {
        ...state,
        selectedValue: payload,
      };
    }
    case OTP_SENT: {
      sessionStorage.setItem("isAuthenticated", "true");
      sessionStorage.setItem("setectedValue", otpType);
      return {
        ...state,
        isAuthenticated: true,
        selectedValue: otpType,
      };
    }
    case OTP_VERIFY: {
      sessionStorage.setItem("isVerified", "true");
      sessionStorage.setItem("token", payload.access_token);
      sessionStorage.setItem("user", JSON.stringify(payload.data));
      return {
        ...state,
        user: payload.data,
        token: payload.access_token,
        isVerified: true,
      };
    }
    case USER_LOADING_SUCCESS: {
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        user: payload,
      };
    }
    case USER_LOADING_ERROR: {
      sessionStorage.clear();
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: {},
        setectedValue: null,
      };
    }
    case LOGIN_FAIL: {
      sessionStorage.clear();
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: {},
        setectedValue: null,
      };
    }
    case USER_LOGOUT: {
      sessionStorage.clear();
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
        setectedValue: null,
      };
    }
    case GET_SCHOOL_UPDATED_DATA: {
      return {
        ...state,
        userUpdated: payload,
      }
    }
    case GET_SCHOOL_UPDATED_DATA_ERROR: {
      return {
        ...state,
        userUpdated: null,
      }
    }
    case TAX_DETAILS: {
      return {
        ...state,
        taxDetails: payload,
      }
    }
    case TAX_DETAILS_ERROR: {
      return {
        ...state,
        taxDetails: null,
      }
    }
    case PLANLIST: {
      // localStorage.setItem("school-plan", JSON.stringify(payload.data));
      return {
        ...state,
        planData: payload.data,
      };
    }
    case PLANLIST_FAIL: {
      // localStorage.setItem("school-plan", JSON.stringify(payload.data));
      return {
        ...state,
        planData: null,
      };
    }
    default:
      return state;
  }
};

export default authStore;
