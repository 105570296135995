import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CommunicationIcon from "../../svg_icons/communication.svg";
import CommonHeadPart from "../../common-components/CommonHeadPart";
import FilterTabs from "../../common-components/FilterTabs";
import { InnerPageRoutes, ROUTES } from "../../constants/routes";
import { CommunicationFilterTabs } from "../../constants/tabs";
import { useDispatch, useSelector } from "react-redux";
import {
  PAGE,
  SALES,
  SUPPORT,
  ACCOUNT,
  TECHNICAL,
  OTHER,
  IMPORTANT,
  IMPORTANT_MESSAGE,
  SENT,
  DELETE_MESSAGE,
  DELETED,
  MESSAGE_LENGTH,
  MESSAGES_REFRESH_INTERVAL,
} from "../../reducer-store/constants";
import { bindActionCreators } from "redux";
import * as Actions from "../../reducer-store/actions/communicationAction";
import ToastMessage from "../../common-components/ToastMessage";
import moment from "moment";
import { toast } from "react-toastify";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import { red } from "@mui/material/colors";
// import "@fortawesome/fontawesome-free/css/all.min.css";
// import "mdbreact/dist/css/mdb.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { TextField, IconButton, Box } from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BsArchiveFill } from "react-icons/bs";
import { CircularProgress } from "@mui/material";
import { FaTrashRestoreAlt } from "react-icons/fa";

const useStyles = makeStyles(() => ({
  textBold: {
    fontWeight: 600,
  },
  divInsideMain: {
    boxShadow: "0 0 5px #8e8e8e",
    borderRadius: "3px",
  },
  searchBar: {
    borderColor: "black",
    "& hover": {
      borderColor: "black",
    },
    "& focused": {
      borderColor: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
    },
  },
  linkstyle: {
    textDecoration: "none",
    color: "black",
  },
  linkstyleBold: {
    textDecoration: "none",
    color: "black",
    fontWeight: 'bold'
  },
  saveEmailButton: {
    borderRadius: "40px !important",
    backgroundColor: "#ffffff !important",
    border: "1px solid #393939 !important",
    color: "#393939 !important",
    padding: "0 35px !important",
    height: "40px !important",
    fontSize: "0.875rem",
    fontFamily: 'Arial !important',
    textTransform: 'capitalize',
  },
  dialogContent: {
    textAlign: "center",
    display: 'flex',
    flexDirection: "row",
    justifyContent: 'center',
    alignItems: "center",
  },
  dialogAction: {
    justifyContent: "center !important",
  },
}));

const manageCategory = (value: string) => {
  switch (value) {
    case "sales":
      return SALES;
    case "support":
      return SUPPORT;
    case "account":
      return ACCOUNT;
    case "technical":
      return TECHNICAL;
    case "other":
      return OTHER;
    default:
      return SALES;
  }
};

const theme = createTheme({
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          "& .css-1ygcj2i-MuiTableCell-root": {
            fontweight: "bold",
            backgroundColor: "#FFFFFF",
          },
          "& .tss-1h9t3sl-MUIDataTableHeadCell-sortAction": {
            display: "-webkit-box",
          },
          "& .tss-1fbujeu-MUIDataTableHeadCell-toolButton": {
            backgroundColor: "#FFFFFF",
          },
          ".tss-1akey0g-MUIDataTableHeadCell-data": {
            fontSize: "15px",
          },
          "& .tss-1832qqn-MUIDataTableHeadRow-root": {
            borderBottomColor: "#000",
            borderBottomWidth: "1.2px",
          },
          "& .tss-10syd3x-MUIDataTableHeadCell-root": {
            fontSize: "15px",
          },
          "& .tss-gm6zfk-MUIDataTableHeadCell-fixedHeader": {
            borderBottomColor: "#000",
            borderBottomWidth: "thin",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Arial",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: "Arial",
        },
        head: {
          backgroundColor: "#FFFFFF",
        },
        footer: {
          backgroundColor: "#FFFFFF",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        colorInherit: {
          backgroundColor: "#E53D12",
          borderRadius: "4px",
          color: "white",
          marginRight: "25px",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          lineHeight: "1.30 !important",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:nth-child(odd)": {
            backgroundColor: "#DFDFDF",
          },
          "&:nth-child(even)": {
            backgroundColor: "#F9F9F9",
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          "& .MuiTablePagination-displayedRows": {
            marginTop: "9%",
          },
        },
      },
    },
  },
});

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const Communication: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const AuthToken = useSelector((state: any) => state.auth);
  const { user, token } = AuthToken;
  // const { school_id } = user;
  const communicationList = useSelector((state: any) => state.communication);
  const { messages } = communicationList;
  const [rowsPerPage, setRowsPerPage] = useState(1000);
  const [page, setPage] = useState(PAGE);
  const [filter, setFilters] = useState<any>("");
  const [order, setOrder] = useState("desc");
  const { CommunicationList, CommunicationListTagsAdded } = bindActionCreators(Actions, dispatch);
  const { updateMessage, deletePermenant, readMessage } = Actions;
  const [search, setSearch] = useState("");
  const [data, setData] = useState<any>([]);
  const [searchApiData, setSearchApiData] = useState([]);
  const [filterVal, setFilterVal] = useState("");
  const [reload, setReload] = useState(false);
  const [searchData, setSearchData] = useState(false);
  const [actionLoader, setActionLoader] = useState(false);
  const referenceValue = useRef<any>("")
  const referenceCount = useRef<any>(0)

  const handleSearchField = (e: any) => {
    if (e.target.value == "") {
      setData(searchApiData);
    } else {
      const filterData = searchApiData.filter(
        (items: any) =>
          items?.category?.props?.children?.props?.children?.toString()?.toLowerCase().includes(e.target.value.toString().toLowerCase())
          || items?.id?.props?.children?.toString().includes(e.target.value.toString())
          || items?.status?.props?.children?.toString()?.toLowerCase().includes(e.target.value.toString().toLowerCase())
          || items?.date?.props?.children?.toString()?.toLowerCase().includes(e.target.value.toString().toLowerCase())
          || items?.body?.props?.children?.toString()?.toLowerCase()?.includes(e.target.value.toString().toLowerCase())
      );
      if (filterData.length > 0) {
        setData(filterData);
        setSearchData(false);
      } else {
        setData([]);
        setSearchData(true);
      }
    }
    setFilterVal(e.target.value);
  };

  const BreadCrumbCommunication = [
    {
      labelText: "Home",
      linkUrl: ROUTES.home,
    },
    {
      labelText: "Communication",
      linkUrl: ROUTES.communication,
    },
    {
      labelText: `${filter === "1"
        ? "Received"
        : filter === "2"
          ? "Sent"
          : filter === "3"
            ? "Failed"
            : filter === "4"
              ? "Important"
              : filter === "5"
                ? "Archived"
                : filter === "6"
                  ? "Draft"
                  : "All messages"
        }`,
      linkUrl: "",
    },
  ];

  const handleRead = async (row: any, token: string) => {
    row?.threads?.map((data: any, index: number) => {
      if (!data?.read && data?.sent_by !== user?.id) {
        (async () => {
          let resData: any = await readMessage(data?.id, token);
          if (!resData?.error) {
            setReload(!reload);
          }
        })();
      }
    })
  }

  const columns = [
    {
      label: " ",
      name: "star",
      options: {
        sort: false,
      },
    },
    {
      label: " ",
      name: "read",
      options: {
        display: false,
      }
    },
    {
      label: "Sr No",
      name: "id",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Link to={{ pathname: tableMeta.rowData[5].props.children == "Draft" && tableMeta.rowData[8] == user?.id ? `${InnerPageRoutes.sendDraftMessage}/${tableMeta.rowData[2]?.props?.children}` : `${InnerPageRoutes.replyToMessage}/${tableMeta.rowData[2]?.props?.children}` }} className={tableMeta.rowData[1] == false && tableMeta.rowData[8] !== user?.id ? classes.linkstyleBold : classes.linkstyle}>
            {tableMeta.rowIndex + 1}
          </Link>
        ),
      },
    },
    {
      label: "Subject",
      name: "body",
      options: {
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Link to={{ pathname: tableMeta.rowData[5].props.children == "Draft" && tableMeta.rowData[8] == user?.id ? `${InnerPageRoutes.sendDraftMessage}/${tableMeta.rowData[2]?.props?.children}` : `${InnerPageRoutes.replyToMessage}/${tableMeta.rowData[2]?.props?.children}` }} className={tableMeta.rowData[1] == false && tableMeta.rowData[8] !== user?.id ? classes.linkstyleBold : classes.linkstyle}>
            {value}
          </Link>
        ),
      },
    },
    {
      label: "Category",
      name: "category",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Link to={{ pathname: tableMeta.rowData[5].props.children == "Draft" && tableMeta.rowData[8] == user?.id ? `${InnerPageRoutes.sendDraftMessage}/${tableMeta.rowData[2]?.props?.children}` : `${InnerPageRoutes.replyToMessage}/${tableMeta.rowData[2]?.props?.children}` }} className={tableMeta.rowData[1] == false && tableMeta.rowData[8] !== user?.id ? classes.linkstyleBold : classes.linkstyle}>
            {value}
          </Link>
        ),
      },
    },
    {
      label: "Status",
      name: "status",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Link to={{ pathname: tableMeta.rowData[5].props.children == "Draft" && tableMeta.rowData[8] == user?.id ? `${InnerPageRoutes.sendDraftMessage}/${tableMeta.rowData[2]?.props?.children}` : `${InnerPageRoutes.replyToMessage}/${tableMeta.rowData[2]?.props?.children}` }} className={tableMeta.rowData[1] == false && tableMeta.rowData[8] !== user?.id ? classes.linkstyleBold : classes.linkstyle}>
            {value}
          </Link>
        ),
      },
    },
    {
      label: "Date",
      name: "date",
      options: {
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => (
          <Link to={{ pathname: tableMeta.rowData[5].props.children == "Draft" && tableMeta.rowData[8] == user?.id ? `${InnerPageRoutes.sendDraftMessage}/${tableMeta.rowData[2]?.props?.children}` : `${InnerPageRoutes.replyToMessage}/${tableMeta.rowData[2]?.props?.children}` }} className={tableMeta.rowData[1] == false && tableMeta.rowData[8] !== user?.id ? classes.linkstyleBold : classes.linkstyle}>
            {value}
          </Link>
        ),
      },
    },
    {
      label: "Action",
      name: "action",
      options: {
        sort: false,
      },
    },
    {
      label: "sent_by",
      name: "sent_by",
      options: {
        display: false,
      },
    },
  ];

  const options = {
    search: false,
    print: false,
    viewColumns: false,
    filter: false,
    download: false,
    responsive: "vertical",
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    selectableRows: "none",
    rowsPerPageOptions: [],
    textLabels: {
      body: {
        noMatch: actionLoader ?
          <Box style={{ alignItems: 'center', margin: "0 auto", justifyContent: "center" }}>
            <CircularProgress sx={{ color: "#707070" }} />
          </Box> :
          searchData || data?.length == 0 && messages?.data?.length == 0 ?
            'Sorry, there is no matching data to display' :
            <Box style={{ alignItems: 'center', margin: "0 auto", justifyContent: "center" }}>
              <CircularProgress sx={{ color: "#707070" }} />
            </Box>,
      },
    },
    customToolbar: () => {
      return (
        <>
          <TextField
            variant="standard"
            placeholder="Search"
            onInput={(e: any) => handleSearchField(e)}
            value={filterVal}
            className={classes.searchBar}
            InputProps={{
              style: { color: "black" },
              endAdornment: (
                <IconButton>
                  <SearchOutlined />
                </IconButton>
              ),
            }}
          />
        </>
      );
    },
  };

  const options1 = {
    search: false,
    print: false,
    viewColumns: false,
    filter: false,
    download: false,
    responsive: "vertical",
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    selectableRows: "none",
    rowsPerPageOptions: [],
    textLabels: {
      body: {
        noMatch:
          <Box style={{ alignItems: 'center', margin: "0 auto", justifyContent: "center" }}>
            <CircularProgress sx={{ color: "#707070" }} />
          </Box>,
      },
    },
    customToolbar: () => {
      return (
        <>
          <TextField
            variant="standard"
            placeholder="Search"
            onInput={(e: any) => handleSearchField(e)}
            value={filterVal}
            className={classes.searchBar}
            InputProps={{
              style: { color: "black" },
              endAdornment: (
                <IconButton>
                  <SearchOutlined />
                </IconButton>
              ),
            }}
          />
        </>
      );
    },
  };

  useEffect(() => {
    // let tempArray: any = [];
    referenceValue.current = filter;
    (async () => {
      const newCommunicationArr: any = filter?.length > 0 ? await CommunicationListTagsAdded(page, rowsPerPage, token, order, filter, user?.id, true) :
        await CommunicationList(page, rowsPerPage, token, order, filter);
      renderDataTable(newCommunicationArr);
    })();

    return () => { }
  }, [filter, reload]);

  async function greet() {
    var count: any = 0;

    const newCommunicationArr: any = referenceValue?.current?.length > 0 ? await CommunicationListTagsAdded(page, rowsPerPage, token, order, referenceValue.current, user?.id, true) :
      await CommunicationList(page, rowsPerPage, token, order, referenceValue.current);

    if (newCommunicationArr?.data?.meta?.totalRecord > count) {
      // const newCommunicationData: any = referenceValue?.current?.length > 0 ? await CommunicationListTagsAdded(page, rowsPerPage, token, order, referenceValue.current, user?.id, true) :
      //   await CommunicationList(page, rowsPerPage, token, order, referenceValue.current);
      renderDataTable(newCommunicationArr);
      count = newCommunicationArr?.data?.meta?.totalRecord
    }
  }

  useEffect(() => {
    setInterval(greet, MESSAGES_REFRESH_INTERVAL)
  }, []);

  const manageStatus = (value: any) => {
    switch (value) {
      case 1:
        return "Received";
      case 2:
        return "Sent";
      case 4:
        return "Important";
      case 5:
        return "Archived";
      case 6:
        return "Draft";
      default:
        return "Received";
    }
  };

  const handleImportantArchive = (threads: any, token: string) => {
    let obj = {
      status: SENT,
    }

    let archieveObj = {
      archive: true
    }

    Promise.allSettled(threads?.map(async (data: any) => {
      await updateMessage(`${IMPORTANT_MESSAGE}/${data.id}`, obj, token);
    })).then(threads?.map(async (data: any) => {
      return await updateMessage(`${DELETE_MESSAGE}/${data.id}`, archieveObj, token);
    })).then(async (fullFilled: any) => {
      const newCommunicationArr: any = referenceValue?.current?.length > 0 ? await CommunicationListTagsAdded(page, rowsPerPage, token, order, referenceValue.current, user?.id, true) :
        await CommunicationList(page, rowsPerPage, token, order, referenceValue.current);
      renderDataTable(newCommunicationArr);

      setActionLoader(false);
      toast.success("Message marked as archived")
    }).catch(reject => {
      toast.error("Something went wrong");
      return;
    })
  }

  const updateInfo = async (url: string, statusId: number, threads: any, isRestored: boolean, token: string) => {
    let obj = {
      status: statusId,
    }

    let archieveObj = {
      archive: isRestored
    }

    setActionLoader(true);

    if (threads[0]?.status == 4 && statusId == 5) {
      handleImportantArchive(threads, token);
    } else {
      Promise.all(threads?.map(async (data: any) => {
        return await updateMessage(`${url}/${data.id}`, url.split("/").pop() === "delete" ? archieveObj : obj, token);
      })).then(async (onfulfilled: any) => {
        const newCommunicationArr: any = filter?.length > 0 ? await CommunicationListTagsAdded(page, rowsPerPage, token, order, filter, user?.id, true) :
          await CommunicationList(page, rowsPerPage, token, order, filter);
        renderDataTable(newCommunicationArr);

        setActionLoader(false);
        toast.success(onfulfilled[0]?.msg)
      }).catch(reject => {
        toast.error("Something went wrong");
        return;
      })
    }
  };

  const renderDataTable = (communicationList: any) => {
    const rowsDataTable = communicationList && communicationList?.data?.data?.map((row: any) => {
      return {
        star: (<>
          {(row?.archived_by?.includes(JSON.stringify(user?.id)) && row?.archive) || row?.status === 6 ? null :
            row?.tags_added_by?.includes(JSON.stringify(user.id)) && row?.status == 4 ? (
              <span onClick={() => updateInfo(`${IMPORTANT_MESSAGE}`, SENT, row?.threads, false, token)}>
                <StarOutlinedIcon sx={{ color: red[500], fontSize: 20, cursor: 'pointer' }} />
              </span>
            ) : (
              <span onClick={() => updateInfo(`${IMPORTANT_MESSAGE}`, IMPORTANT, row?.threads, false, token)}>
                <StarOutlineOutlinedIcon sx={{ color: red[500], fontSize: 20, cursor: 'pointer' }} />
              </span>
            )} </>),
        read: row.threads.at(-1)?.read,
        id: <div onClick={() => handleRead(row, token)}>{row?.id}</div>,
        body: <div onClick={() => handleRead(row, token)}>
          {row?.subject.length > 25 ? row?.subject.substring(0, MESSAGE_LENGTH) + "..." : row?.subject}
        </div>,
        category: <div onClick={() => handleRead(row, token)}>{manageCategory(row?.category)}</div>,
        status: <div onClick={() => handleRead(row, token)}>{manageStatus(
          row?.status === 6 ? 6 :
            row?.tags_added_by?.includes(JSON.stringify(user?.id)) && row?.status == 4 ? 4 :
              row?.archived_by?.includes(JSON.stringify(user?.id)) ? 5 :
                row?.threads?.at(-1)?.sent_by === user?.id ? 2 : 1
        )}</div>,
        date: <div onClick={() => handleRead(row, token)}>{moment(row?.sent_timestamp).format("YYYY-MM-DD | h:mm A")}</div>,
        action: (<>
          {row?.status === 6 || (row?.archived_by?.includes(JSON.stringify(user?.id)) && referenceValue.current !== "5") ? null :
            referenceValue.current !== "5" ?
              (<span onClick={() => updateInfo(`${DELETE_MESSAGE}`, DELETED, row?.threads, true, token)}>
                <BsArchiveFill style={{ cursor: 'pointer' }} size={20} color="black" title="Archive Message" />
              </span>
              ) : (<span onClick={() => updateInfo(`${DELETE_MESSAGE}`, SENT, row?.threads, false, token)}>
                <FaTrashRestoreAlt size={20} color="black" style={{ cursor: 'pointer' }} title="Restore Message" />
              </span>)}</>),
        sent_by: row?.threads?.at(-1)?.sent_by,
      };
    });
    setData(rowsDataTable);
    setSearchApiData(rowsDataTable);
  };

  const handleFilter = (newValue: string) => {
    setFilters(newValue);
    setPage(1);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  // const handleClose = () => {
  //   setWarningDialog(false);
  // };

  return (
    <>
      <ToastMessage />
      <CommonHeadPart
        componentText="Communication"
        Icon={CommunicationIcon}
        buttonText="Compose Message"
        urlLink={InnerPageRoutes.composeMessage}
        BreadCrumbs={BreadCrumbCommunication}
      />
      <div className={classes.divInsideMain}>
        <ThemeProvider theme={theme}>
          {actionLoader ?
            <MUIDataTable
              title={<FilterTabs
                SelectedFilter={filter}
                Filters={CommunicationFilterTabs}
                HandleChange={handleFilter}
                HandleSearch={handleSearch}
                isSearch={false}
              />}
              data={[]}
              columns={columns}
              options={options1}
            />
            :
            <MUIDataTable
              title={<FilterTabs
                SelectedFilter={filter}
                Filters={CommunicationFilterTabs}
                HandleChange={handleFilter}
                HandleSearch={handleSearch}
                isSearch={false}
              />}
              data={data}
              columns={columns}
              options={options}
            />
          }
        </ThemeProvider>
      </div>

      {/* <BootstrapDialog
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"
        open={warningDialog}
      >
        <form>
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            Warning
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <RiErrorWarningFill color="#FDB924" size={30} style={{ marginRight: 10 }} />
            <b>Are you sure you want to restore the message?</b>
          </DialogContent>
          <DialogActions>
            <Button
              type="button"
              variant="contained"
              onClick={handleClose}
              className={classes.saveEmailButton}
            >
              Cancel
            </Button>
            {" "}
            <SaveButtonComponent ButtonType="submit" ButtonLabel="Restore" />
          </DialogActions>
        </form>
      </BootstrapDialog> */}
    </>
  );
};

export default Communication;
