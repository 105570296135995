import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Logo1 from "../../svg_icons/logo/dsc_logo.svg";

interface LogoProps {
  className?: string
  size?: number
}

const Logo = (props: LogoProps) => {
  const classes = useStyles(props)
  return (
    <img src={Logo1} />
  )
}

const useStyles = makeStyles(theme => ({

}))

export default Logo