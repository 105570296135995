import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ROUTES } from "../../../constants/routes";
import { Card, CardContent, TextField, Typography } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import { InputDatePicker, InputField, InputSelectField } from "../../../common-components/FormComponent/FormFieldsComponent";
import { STUDENT_CONTRACT_SUCCESSFULLY_CREATED, UPDATE_STUDENT_CONTRACT_SUCCESSFULLY_CREATED } from "../../../reducer-store/ToastMessage";
import { CancelButtonComponent, SaveButtonComponent } from "../../../common-components/ButtonComponent";
import { Box, CircularProgress, Grid } from "@mui/material";
import CommonHeadPart from "../../../common-components/CommonHeadPart";
import DashboardIcon from "../../../svg_icons/dashboard.svg";
import { isRequiredDate, isRequiredField, isValidRegisterID } from "../../../common-components/FormComponent/FormValidator";
import * as Actions from "../../../reducer-store/actions/StudentContractAction";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import ToastMessage from "../../../common-components/ToastMessage";
import { useParams } from "react-router-dom";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { contracts } from "../../../reducer-store/constants";

const useStyles = makeStyles(() => ({
  CardTitle: {
    backgroundColor: "#646464",
    color: "#ffffff",
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  }
}));

const NewStaffInstructor: React.FC = () => {
  const classes = useStyles();
  const [isSubmit, setIsSubmit] = useState(false);
  const [isValidValues, setIsValidValues] = useState(true);
  const [contractType, setContractType] = useState("");
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [documentName, setDocumentName] = useState("");
  const { addStudentContaract, SingleStudentContaract, updateStudentContaract } = Actions;
  const AuthToken = useSelector((state: any) => state.auth);
  const { token } = AuthToken;
  const history = useHistory();
  const params: any = useParams();
  const { id } = params;
  const [saveUpdate, setSaveUpdate] = useState(true);

  useEffect(() => {
    (async () => {
      if (params && params.id) {
        const response: any = await SingleStudentContaract(id, token);
        const data = response.data;
        setDocumentName(data && data.contract_name);
        setStartDate(data && moment(data.start_date).format("YYYY-MM-DD"));
        setEndDate(data && moment(data.end_date).format("YYYY-MM-DD"));
        setContractType(data && data.contract_description);
        setSaveUpdate(false);
      }
    })();
  }, [id]);

  if (params && params.id && saveUpdate) {
    return <>
      <Box style={{ position: 'absolute', margin: '0 auto', top: '50%', left: '50%', justifyContent: 'center' }}>
        <CircularProgress sx={{ color: '#707070' }} />
      </Box>
    </>
  }

  const BreadCrumbStudentContractManagement = [
    {
      labelText: "Home",
      linkUrl: ROUTES.dashboard,
    },
    {
      labelText: "Terms and Contracts",
      linkUrl: ROUTES.studentContractManagement,
    },
    {
      labelText: id ? "Edit Terms and Contracts" : "New Terms and Contracts",
      linkUrl: "",
    },
  ];

  const SubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmit(true);
    if (
      isRequiredField(documentName) &&
      isValidRegisterID(documentName) &&
      isRequiredField(contractType)
    ) {
      setIsSubmit(false);
      let userObj = {
        contract_name: documentName,
        contract_description: contractType
      }

      let resData: any = saveUpdate ? await addStudentContaract(userObj, token) : await updateStudentContaract(id, userObj, token)
      if (!resData.error) {
        toast.success(saveUpdate ? STUDENT_CONTRACT_SUCCESSFULLY_CREATED : UPDATE_STUDENT_CONTRACT_SUCCESSFULLY_CREATED);
        setTimeout(() => {
          return history.push(ROUTES.studentContractManagement);
        }, 2000);
        return;
      }
      toast.error(resData.data.errorMsg);
    }
    setIsValidValues(false);
  }

  const handleStartDateChange = (date: any) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: any) => {
    setEndDate(date);
  };

  return (
    <>
      <ToastMessage />
      <Row>
        <Col lg="8" sm="12">
          <CommonHeadPart
            componentText="Terms and Contracts"
            Icon={DashboardIcon}
            buttonText=""
            urlLink="/"
            BreadCrumbs={BreadCrumbStudentContractManagement}
          />
        </Col>
      </Row>

      <Card>
        <div className={classes.CardTitle}>
          <CardContent>
            <Typography variant="h6" align="justify">
              {/*  <AddCircle /> */}
              {saveUpdate ? "Add New Terms and Contracts" : "Update Terms and Contracts"}
            </Typography>
          </CardContent>
        </div>
        <CardContent>
          <form onSubmit={SubmitHandler}>
            <Row>
              <Col lg="4" sm="12">
                <InputSelectField
                  select={true}
                  lable="Contract Type"
                  name="type"
                  value={contractType}
                  className="form-textfield-styles"
                  options={contracts}
                  handleChange={(e) => setContractType(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Contract Type field is required"
                  disabled={false}
                />
              </Col>
              <Col lg="4" sm="12">
                {/* <InputDatePicker
                  className="form-textfield-styles errorBottom"
                  value={startDate}
                  lable="Start Date"
                  handleChange={(e) => handleStartDateChange(e)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Start Date is required"
                  min={saveUpdate ? moment(new Date()).format("YYYY-MM-DD") : startDate}
                /> */}
                <InputField
                  type="text"
                  lable="Document Name"
                  name={documentName}
                  rows={1}
                  multiline={true}
                  placeHolder="Enter Document Name"
                  className="form-textfield-styles"
                  value={documentName}
                  handleChange={(e) => setDocumentName(e.target.value)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="Document Name is required"
                />
              </Col>
              <Col lg="4" sm="12">
                {/* <InputDatePicker
                  className="form-textfield-styles errorBottom"
                  value={endDate}
                  lable="End Date"
                  handleChange={(e) => handleEndDateChange(e)}
                  isValidValue={isValidValues}
                  isSubmit={isSubmit}
                  requiredMessage="End Date is required"
                  min={saveUpdate ? moment(new Date()).format("YYYY-MM-DD") : endDate}
                /> */}
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  type="file"
                  label="Upload Document (PDF Only)"
                  className="form-textfield-styles errorBottom"
                  onChange={(e) => { }}
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col lg="4" sm="12">
                <InputDatePicker
                  disabled={true}
                  className="form-textfield-styles errorBottom"
                  value={moment(new Date()).format("YYYY-MM-DD")}
                  lable="Date Created"
                  handleChange={(e) => { }}
                  isValidValue={false}
                  isSubmit={false}
                  requiredMessage="Date Created is required"
                />
              </Col>
              <Col lg="4" sm="12">
                <FormControlLabel
                  control={
                    <Checkbox
                      // disabled={editProgramInfo === undefined ? true : false}
                      checked={false}
                      onChange={() => { }}
                      color="success"
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    />
                  }
                  label="Is Mandatory"
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg="12" sm="12">
                <div style={{ textAlign: "end" }}>
                  <Link to={ROUTES.studentContractManagement} className={classes.link}>
                    <CancelButtonComponent ButtonLabel="Cancel" /></Link>{" "}
                  <SaveButtonComponent ButtonType="submit" ButtonLabel={saveUpdate ? "Save" : "Update"} />
                </div>
              </Col>
            </Row>
          </form>
        </CardContent>
      </Card>
    </>
  );
};

export default NewStaffInstructor;
