import React, { useEffect, useState } from "react";
import "../Common.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { makeStyles } from "@material-ui/core/styles";
import CommonHeadPart from '../../common-components/CommonHeadPart';
import SchoolPaymentIcon from "../../svg_icons/school_payment.svg";
import { ROUTES } from '../../constants/routes';
import FilterTabs from "../../common-components/FilterTabs";
import { PaymentTab } from "../../constants/tabs";
import { Box, Card, CardContent } from "@material-ui/core";
import Transaction from './Pages/Transaction'
import Faild from './Pages/Faild'
import Refunded from "./Pages/Refunded";
import AllPayments from "./Pages/AllPayments";
const useStyles = makeStyles(() => ({
    divInsideMain: {
        boxShadow: "0 0 5px #8e8e8e",
        borderRadius: "3px",
    },
    boxBorder: {
        backgroundColor: "#ffffff",
        boxShadow: "0px 0px 2px #8e8e8e",
        borderRadius: "5px",
    },
}));

const Payments: React.FC = () => {
    const [filter, setFilters] = useState("All Payments");
    const [search, setSearch] = useState('');
    const classes = useStyles();


    const handleFilter = (newValue: string) => {
        setFilters(newValue);
    };

    const handleSearch = (value: string) => {
        setSearch(value);
    };

    const BreadCrumbStudentContractManagement = [
        {
            labelText: "Home",
            linkUrl: ROUTES.home
        },
        {
            labelText: "Payments",
            linkUrl: ROUTES.payments
        },
        {
            labelText: filter,
            linkUrl: ""
        }
    ];

    return (
        <>
            <CommonHeadPart
                componentText="Payments"
                Icon={SchoolPaymentIcon}
                urlLink="/"
                BreadCrumbs={BreadCrumbStudentContractManagement}
            />

            <Card>
                <CardContent>
                    <FilterTabs
                        SelectedFilter={filter}
                        Filters={PaymentTab}
                        HandleChange={handleFilter}
                        HandleSearch={handleSearch}
                    />
                </CardContent>
                {/* <CardContent> */}
                {filter === 'All Payments' ? <AllPayments /> : filter === 'Transaction' ? <Transaction /> : filter === 'Failed' ? <Faild /> : <Refunded />}
                {/* </CardContent> */}
            </Card>

            {/* <div className={classes.divInsideMain}>
                <FilterTabs
                    SelectedFilter={filter}
                    Filters={PaymentTab}
                    HandleChange={handleFilter}
                    HandleSearch={handleSearch}
                />
                {filter === 'Transaction' ? <Transaction /> : filter === 'Failed' ? <Faild /> : <Refunded />} */}
            {/* 'Cards List' ? <Card /> : filter === */}
            {/* </div> */}

        </>);
};
export default Payments;