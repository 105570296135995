export const STUDENT_CONTRACT_SUCCESSFULLY_CREATED = "Student Contract Created Successfully";
export const UPDATE_STUDENT_CONTRACT_SUCCESSFULLY_CREATED = "Student Contract Updated Successfully";
export const SCHOOL_SETTING_SUCCESSFULLY_UPDATED = "School Settings Updated Successfully";
export const SCHOOL_SETTING_SUCCESSFULLY_ADDED = "School Settings Added Successfully";
export const TAX_SUCCESSFULLY_UPDATED = "School Tax Updated Successfully";
export const WHITE_IP_SUCCESSFULLY_ADDED = "Whitelist IP Created Successfully";
export const WHITE_IP_SUCCESSFULLY_UPDATED = "Whitelist IP Updated Successfully";
export const WHITE_IP_SUCCESSFULLY_DELETED = "Whitelist IP Deleted Successfully";
export const STAFF_SUCCESSFULLY_ADDED = "Staff Created Successfully";
export const STAFF_SUCCESSFULLY_UPDATED = "Staff Updated Successfully";
export const TOPIC_SUCCESSFULLY_CREATED = "Topic Created Successfully";
export const TOPIC_SUCCESSFULLY_UPDATED = "Topic Updated Successfully";

export const MODUAL_SUCCESSFULLY_CREATED = "Module Created Successfully";
export const MODUAL_SUCCESSFULLY_UPDATED = "Module Updated Successfully";

export const PROGRAM_SUCCESSFULLY_CREATED = "Program Created Successfully";
export const PROGRAM_SUCCESSFULLY_UPDATED = "Program Updated Successfully";
export const PROGRAM_SUCCESSFULLY_ENABLED = "Program Enabled Successfully";
export const PROGRAM_SUCCESSFULLY_DISABLED = "Program Disabled Successfully";

export const COURSE_SUCCESSFULLY_CREATED = "Course Created Successfully";
export const COURSE_SUCCESSFULLY_UPDATED = "Course Updated Successfully";

export const ASSET_ADDED_SUCCESSFULLY = "Asset Added Successfully";
export const ASSET_UPDATED_SUCCESSFULLY = "Asset Updated Successfully";

export const MESSAGE_SUCCESSFULLY_SENT = "Message Sent Successfully";
export const MESSAGE_SUCCESSFULLY_SAVE = "Message Saved Successfully";

export const KEYS_SUCCESSFULLY_ADDED = "Payment Keys Added Successfully";
export const KEYS_SUCCESSFULLY_UPDATED = "Payment Keys Updated Successfully";

export const PROGRAM_FEES_ADD_SUCCESSFULLY = "Program Fees Added Successfully";
export const PROGRAM_FEES_UPDATE_SUCCESSFULLY = "Program Fees Updated Successfully";

export const UPDATE_SCHOOL_SUCCESSFULLY = "School Updated Successfully";
