import React, { useEffect, useState } from 'react';
import { fileSignedURL } from '../reducer-store/actions/authActions';
import { useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import { manageIconType } from '../reducer-store/constants';

type ImageProps = {
    signedUrl: string,
    type: string,
    fileName: string,
}

const ImageComponent: React.FC<ImageProps> = ({ signedUrl, type, fileName }): JSX.Element => {
    const AuthToken = useSelector((state: any) => state.auth);
    const { token } = AuthToken;
    const [image, setImage] = useState('');
    const typeArray = [
        "image/jpeg",
        "image/jpg",
        "image/png"
    ]

    useEffect(() => {
        (async () => {
            const newData: { url: string, msg: string, error: boolean } = await fileSignedURL(signedUrl, token);
            const { url } = newData;
            setImage(url);
        })();
    }, [])

    return (
        <>
            <Tooltip title={fileName}>
                <img
                    loading='eager'
                    className="m-1 rounded"
                    src={typeArray.includes(type) ? image : manageIconType(type)}
                    style={{ cursor: 'pointer' }}
                    width={typeArray.includes(type) ? 100 : 60}
                    onClick={() => window.open(image, "_blank")}
                />
            </Tooltip>
        </>
    );
};

export default ImageComponent
