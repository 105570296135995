import React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Row, Col } from "react-bootstrap";

const useStyles = makeStyles(() => ({
  paginationRowPadding: {
    padding: "20px 5px 15px",
  },
  rowDataLeftAlign: {
    float: "left",
  },
  paginationDataRightAlign: {
    float: "right",
  },
}));

type PaginationComponentProps = {
  page?: number;
  setPage?: any;
  paginationData?: paginationDataProps;
};

type paginationDataProps = {
  totalRecord?: number;
  totalPage?: number;
  start?: number;
  end?: number;
};

const PaginationComponent: React.FC<PaginationComponentProps> = ({
  page = 1,
  setPage,
  paginationData = { start: 0, end: 0, totalRecord: 0 },
}) => {
  const classes = useStyles();
  return (
    <Container className="main-pagination-tag" fluid>
      <Row className={classes.paginationRowPadding}>
        <Col sm={6} xs={12}>
          <span className={classes.rowDataLeftAlign}>
            Showing {paginationData?.start} to {paginationData?.end} from{" "}
            {paginationData?.totalRecord} entries
          </span>
        </Col>
        <Col sm={6} xs={12}>
          <Stack spacing={2} className={classes.paginationDataRightAlign}>
            <div className="Pagination">
              <Pagination
                count={paginationData?.totalPage}
                page={page}
                variant="outlined"
                shape="rounded"
                size="large"
                onChange={(e, value) => setPage(value)}
              />
            </div>
          </Stack>
        </Col>
      </Row>
    </Container>
  );
};

export default PaginationComponent;
